import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { syncActions } from '.';
import { analyticsThunkActions } from '../analytics';
import { caregiverThunkActions } from '../caregiver';
import { documentThunkActions } from '../document';
import { notesThunkActions } from '../notes';
import { RootState, ThunkApiType } from '../types';
import { userThunkActions } from '../user';

import {
  SyncOfflineDataProps,
  SyncOfflineDataReturnType,
  SyncOfflineVisitDataProps,
} from './sync.types';
import { visitActions, visitThunkActions } from '../visit';
import { CmsVisitDataInputModelInput } from '@ecdlink/graphql';

type SyncStep = {
  title: string;
  action?: AsyncThunk<boolean[], any, any>;
};
export const syncOfflineData = createAsyncThunk<
  SyncOfflineDataProps,
  SyncOfflineDataReturnType,
  ThunkApiType<RootState>
>('sync/offlineData', async (any, { getState, rejectWithValue, dispatch }) => {
  const syncSteps: SyncStep[] = [
    {
      title: 'Practitioner',
      action: userThunkActions.updateUser,
    },
    {
      title: 'Infant Visit(s)',
    },
    {
      title: 'Mother Visit(s)',
    },
    {
      title: 'Care givers',
      action: caregiverThunkActions.upsertCareGivers,
    },
    {
      title: 'Notes',
      action: notesThunkActions.upsertNotes,
    },
    {
      title: 'Documents',
      action: documentThunkActions.createDocument,
    },
    {
      title: 'User Consent',
      action: userThunkActions.upsertUserConsents,
    },
    {
      title: 'Analytics',
      action: analyticsThunkActions.pushAnalytics,
    },
  ];

  let error: Error | null = null;

  for (let i = 0; i < syncSteps.length; i++) {
    const step = syncSteps[i];

    dispatch(
      syncActions.setCurrentActionState({
        title: step.title,
        step: i + 1,
        stepTotal: syncSteps.length,
      })
    );

    try {
      if (!step.action) {
        const {
          visits: { visitFormData, visitFormDataForMother },
        } = getState();
        // Sync visit form data for infants
        if (!!visitFormData?.length) {
          for (const formData of visitFormData) {
            await dispatch(
              visitThunkActions.addVisitFormData(formData)
            ).unwrap();
          }
          await dispatch(visitActions.clearVisitFormData());
        }
        // Sync visit form data for mothers
        if (!!visitFormDataForMother?.length) {
          for (const formData of visitFormDataForMother) {
            await dispatch(
              visitThunkActions.addVisitForMomFormData(formData)
            ).unwrap();
          }
          await dispatch(visitActions.clearVisitFormDataForMother());
        }
      } else {
        await dispatch(step.action({})).unwrap();
      }
    } catch (err) {
      console.error(err);
      dispatch(syncActions.setError((err as Error).message));
      error = err as Error;
      break;
    }
  }

  if (error) {
    return rejectWithValue(error.message);
  }
});

/**
 * This function syncs offline visit data when the user is back online
 * and opens a profile that has offline visit data. It will sync the data
 * and remove the specific visit data from the offline store.
 */
export const syncOfflineVisitData = createAsyncThunk<
  SyncOfflineDataProps,
  SyncOfflineVisitDataProps,
  ThunkApiType<RootState>
>('sync/offlineData', async (data, { getState, rejectWithValue, dispatch }) => {
  let error: Error | null = null;

  const {
    visits: { visitFormData, visitFormDataForMother },
  } = getState();

  // Combine visit form data for easier processing
  const combinedData = [
    ...(visitFormData ? visitFormData : []),
    ...(visitFormDataForMother ? visitFormDataForMother : []),
  ];

  // Get the visit data by visitId
  const visitData: CmsVisitDataInputModelInput[] | undefined =
    combinedData.filter((formData) => {
      if (data.motherId) {
        return formData.motherId === data.motherId;
      } else if (data.infantId) {
        return formData.infantId === data.infantId;
      }
    });

  for (let i = 0; i < visitData.length; i++) {
    dispatch(
      syncActions.setCurrentActionState({
        title: 'Syncing Visit Data',
        step: i + 1,
        stepTotal: visitData.length,
      })
    );

    try {
      if (visitData) {
        const isInfantData = visitData[i]?.infantId !== undefined;
        if (isInfantData && data.infantId) {
          // Sync visit form data for infants
          await dispatch(
            visitThunkActions.addVisitFormData(visitData[i])
          ).unwrap();
          await dispatch(
            visitActions.clearVisitFormDataById({
              infantId: data.infantId,
              visitId: visitData[i].visitId,
            })
          );
        } else if (!isInfantData && data.motherId) {
          // Sync visit form data for mothers
          await dispatch(
            visitThunkActions.addVisitForMomFormData(visitData[i])
          ).unwrap();
          await dispatch(
            visitActions.clearVisitFormDataForMotherById({
              motherId: data.motherId,
              visitId: visitData[i].visitId,
            })
          );
        }
      }
    } catch (err) {
      console.error(err);
      dispatch(syncActions.setError((err as Error).message));
      error = err as Error;
    }
  }

  if (error) {
    return rejectWithValue(error.message);
  }
});
