export const weightForLengthBoys = {
  data: [
    {
      Section: 'weight-for-length-boys',
      Height: 45.0,
      Median: 2.441,
      SD3neg: 1.877,
      SD2neg: 2.043,
      SD2: 2.951,
      SD3: 3.261,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 45.1,
      Median: 2.4577,
      SD3neg: 1.89,
      SD2neg: 2.057,
      SD2: 2.971,
      SD3: 3.283,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 45.2,
      Median: 2.4744,
      SD3neg: 1.903,
      SD2neg: 2.072,
      SD2: 2.991,
      SD3: 3.305,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 45.3,
      Median: 2.4911,
      SD3neg: 1.916,
      SD2neg: 2.086,
      SD2: 3.011,
      SD3: 3.326,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 45.4,
      Median: 2.5078,
      SD3neg: 1.929,
      SD2neg: 2.1,
      SD2: 3.031,
      SD3: 3.348,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 45.5,
      Median: 2.5244,
      SD3neg: 1.942,
      SD2neg: 2.114,
      SD2: 3.05,
      SD3: 3.37,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 45.6,
      Median: 2.5411,
      SD3neg: 1.956,
      SD2neg: 2.128,
      SD2: 3.07,
      SD3: 3.391,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 45.7,
      Median: 2.5578,
      SD3neg: 1.969,
      SD2neg: 2.142,
      SD2: 3.09,
      SD3: 3.413,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 45.8,
      Median: 2.5744,
      SD3neg: 1.982,
      SD2neg: 2.157,
      SD2: 3.109,
      SD3: 3.434,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 45.9,
      Median: 2.5911,
      SD3neg: 1.995,
      SD2neg: 2.171,
      SD2: 3.129,
      SD3: 3.456,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.0,
      Median: 2.6077,
      SD3neg: 2.008,
      SD2neg: 2.185,
      SD2: 3.149,
      SD3: 3.477,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.1,
      Median: 2.6244,
      SD3neg: 2.021,
      SD2neg: 2.199,
      SD2: 3.169,
      SD3: 3.499,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.2,
      Median: 2.6411,
      SD3neg: 2.034,
      SD2neg: 2.213,
      SD2: 3.188,
      SD3: 3.521,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.3,
      Median: 2.6578,
      SD3neg: 2.048,
      SD2neg: 2.228,
      SD2: 3.208,
      SD3: 3.542,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.4,
      Median: 2.6745,
      SD3neg: 2.061,
      SD2neg: 2.242,
      SD2: 3.228,
      SD3: 3.564,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.5,
      Median: 2.6913,
      SD3neg: 2.074,
      SD2neg: 2.256,
      SD2: 3.248,
      SD3: 3.585,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.6,
      Median: 2.7081,
      SD3neg: 2.087,
      SD2neg: 2.271,
      SD2: 3.268,
      SD3: 3.607,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.7,
      Median: 2.7249,
      SD3neg: 2.101,
      SD2neg: 2.285,
      SD2: 3.288,
      SD3: 3.629,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.8,
      Median: 2.7417,
      SD3neg: 2.114,
      SD2neg: 2.299,
      SD2: 3.307,
      SD3: 3.65,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 46.9,
      Median: 2.7586,
      SD3neg: 2.127,
      SD2neg: 2.314,
      SD2: 3.327,
      SD3: 3.672,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.0,
      Median: 2.7755,
      SD3neg: 2.141,
      SD2neg: 2.328,
      SD2: 3.347,
      SD3: 3.694,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.1,
      Median: 2.7925,
      SD3neg: 2.154,
      SD2neg: 2.343,
      SD2: 3.367,
      SD3: 3.716,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.2,
      Median: 2.8095,
      SD3neg: 2.168,
      SD2neg: 2.357,
      SD2: 3.388,
      SD3: 3.738,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.3,
      Median: 2.8266,
      SD3neg: 2.181,
      SD2neg: 2.372,
      SD2: 3.408,
      SD3: 3.76,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.4,
      Median: 2.8437,
      SD3neg: 2.195,
      SD2neg: 2.386,
      SD2: 3.428,
      SD3: 3.782,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.5,
      Median: 2.8609,
      SD3neg: 2.208,
      SD2neg: 2.401,
      SD2: 3.448,
      SD3: 3.804,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.6,
      Median: 2.8782,
      SD3neg: 2.222,
      SD2neg: 2.416,
      SD2: 3.469,
      SD3: 3.826,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.7,
      Median: 2.8955,
      SD3neg: 2.236,
      SD2neg: 2.431,
      SD2: 3.489,
      SD3: 3.848,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.8,
      Median: 2.9129,
      SD3neg: 2.25,
      SD2neg: 2.446,
      SD2: 3.51,
      SD3: 3.871,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 47.9,
      Median: 2.9304,
      SD3neg: 2.263,
      SD2neg: 2.461,
      SD2: 3.53,
      SD3: 3.893,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.0,
      Median: 2.948,
      SD3neg: 2.277,
      SD2neg: 2.476,
      SD2: 3.551,
      SD3: 3.916,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.1,
      Median: 2.9657,
      SD3neg: 2.291,
      SD2neg: 2.491,
      SD2: 3.572,
      SD3: 3.939,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.2,
      Median: 2.9835,
      SD3neg: 2.306,
      SD2neg: 2.506,
      SD2: 3.593,
      SD3: 3.962,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.3,
      Median: 3.0014,
      SD3neg: 2.32,
      SD2neg: 2.521,
      SD2: 3.614,
      SD3: 3.985,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.4,
      Median: 3.0195,
      SD3neg: 2.334,
      SD2neg: 2.537,
      SD2: 3.635,
      SD3: 4.008,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.5,
      Median: 3.0377,
      SD3neg: 2.349,
      SD2neg: 2.552,
      SD2: 3.657,
      SD3: 4.031,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.6,
      Median: 3.056,
      SD3neg: 2.363,
      SD2neg: 2.568,
      SD2: 3.678,
      SD3: 4.055,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.7,
      Median: 3.0745,
      SD3neg: 2.378,
      SD2neg: 2.584,
      SD2: 3.7,
      SD3: 4.078,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.8,
      Median: 3.0931,
      SD3neg: 2.393,
      SD2neg: 2.6,
      SD2: 3.722,
      SD3: 4.102,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 48.9,
      Median: 3.1119,
      SD3neg: 2.407,
      SD2neg: 2.616,
      SD2: 3.744,
      SD3: 4.126,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.0,
      Median: 3.1308,
      SD3neg: 2.422,
      SD2neg: 2.632,
      SD2: 3.766,
      SD3: 4.151,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.1,
      Median: 3.1499,
      SD3neg: 2.438,
      SD2neg: 2.648,
      SD2: 3.789,
      SD3: 4.175,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.2,
      Median: 3.1691,
      SD3neg: 2.453,
      SD2neg: 2.665,
      SD2: 3.812,
      SD3: 4.2,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.3,
      Median: 3.1884,
      SD3neg: 2.468,
      SD2neg: 2.681,
      SD2: 3.834,
      SD3: 4.225,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.4,
      Median: 3.2079,
      SD3neg: 2.484,
      SD2neg: 2.698,
      SD2: 3.857,
      SD3: 4.25,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.5,
      Median: 3.2276,
      SD3neg: 2.499,
      SD2neg: 2.715,
      SD2: 3.881,
      SD3: 4.275,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.6,
      Median: 3.2473,
      SD3neg: 2.515,
      SD2neg: 2.732,
      SD2: 3.904,
      SD3: 4.3,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.7,
      Median: 3.2672,
      SD3neg: 2.531,
      SD2neg: 2.749,
      SD2: 3.927,
      SD3: 4.326,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.8,
      Median: 3.2873,
      SD3neg: 2.547,
      SD2neg: 2.766,
      SD2: 3.951,
      SD3: 4.352,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 49.9,
      Median: 3.3075,
      SD3neg: 2.563,
      SD2neg: 2.783,
      SD2: 3.975,
      SD3: 4.377,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.0,
      Median: 3.3278,
      SD3neg: 2.579,
      SD2neg: 2.801,
      SD2: 3.999,
      SD3: 4.403,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.1,
      Median: 3.3482,
      SD3neg: 2.595,
      SD2neg: 2.818,
      SD2: 4.023,
      SD3: 4.43,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.2,
      Median: 3.3688,
      SD3neg: 2.612,
      SD2neg: 2.836,
      SD2: 4.047,
      SD3: 4.456,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.3,
      Median: 3.3894,
      SD3neg: 2.628,
      SD2neg: 2.853,
      SD2: 4.071,
      SD3: 4.482,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.4,
      Median: 3.4102,
      SD3neg: 2.645,
      SD2neg: 2.871,
      SD2: 4.096,
      SD3: 4.509,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.5,
      Median: 3.4311,
      SD3neg: 2.661,
      SD2neg: 2.889,
      SD2: 4.12,
      SD3: 4.536,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.6,
      Median: 3.4522,
      SD3neg: 2.678,
      SD2neg: 2.907,
      SD2: 4.145,
      SD3: 4.563,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.7,
      Median: 3.4733,
      SD3neg: 2.695,
      SD2neg: 2.925,
      SD2: 4.17,
      SD3: 4.59,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.8,
      Median: 3.4946,
      SD3neg: 2.712,
      SD2neg: 2.944,
      SD2: 4.195,
      SD3: 4.617,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 50.9,
      Median: 3.5161,
      SD3neg: 2.729,
      SD2neg: 2.962,
      SD2: 4.22,
      SD3: 4.644,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.0,
      Median: 3.5376,
      SD3neg: 2.746,
      SD2neg: 2.981,
      SD2: 4.245,
      SD3: 4.672,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.1,
      Median: 3.5593,
      SD3neg: 2.763,
      SD2neg: 2.999,
      SD2: 4.271,
      SD3: 4.7,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.2,
      Median: 3.5812,
      SD3neg: 2.781,
      SD2neg: 3.018,
      SD2: 4.296,
      SD3: 4.728,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.3,
      Median: 3.6032,
      SD3neg: 2.798,
      SD2neg: 3.037,
      SD2: 4.322,
      SD3: 4.756,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.4,
      Median: 3.6254,
      SD3neg: 2.816,
      SD2neg: 3.056,
      SD2: 4.348,
      SD3: 4.784,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.5,
      Median: 3.6477,
      SD3neg: 2.834,
      SD2neg: 3.075,
      SD2: 4.375,
      SD3: 4.813,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.6,
      Median: 3.6702,
      SD3neg: 2.852,
      SD2neg: 3.094,
      SD2: 4.401,
      SD3: 4.841,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.7,
      Median: 3.6929,
      SD3neg: 2.87,
      SD2neg: 3.114,
      SD2: 4.428,
      SD3: 4.87,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.8,
      Median: 3.7157,
      SD3neg: 2.888,
      SD2neg: 3.133,
      SD2: 4.454,
      SD3: 4.899,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 51.9,
      Median: 3.7388,
      SD3neg: 2.907,
      SD2neg: 3.153,
      SD2: 4.482,
      SD3: 4.929,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.0,
      Median: 3.762,
      SD3neg: 2.925,
      SD2neg: 3.173,
      SD2: 4.509,
      SD3: 4.958,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.1,
      Median: 3.7855,
      SD3neg: 2.944,
      SD2neg: 3.193,
      SD2: 4.536,
      SD3: 4.988,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.2,
      Median: 3.8092,
      SD3neg: 2.963,
      SD2neg: 3.214,
      SD2: 4.564,
      SD3: 5.019,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.3,
      Median: 3.833,
      SD3neg: 2.982,
      SD2neg: 3.234,
      SD2: 4.592,
      SD3: 5.049,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.4,
      Median: 3.8571,
      SD3neg: 3.001,
      SD2neg: 3.255,
      SD2: 4.62,
      SD3: 5.08,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.5,
      Median: 3.8814,
      SD3neg: 3.02,
      SD2neg: 3.276,
      SD2: 4.649,
      SD3: 5.111,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.6,
      Median: 3.9059,
      SD3neg: 3.04,
      SD2neg: 3.297,
      SD2: 4.678,
      SD3: 5.142,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.7,
      Median: 3.9306,
      SD3neg: 3.06,
      SD2neg: 3.318,
      SD2: 4.707,
      SD3: 5.173,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.8,
      Median: 3.9555,
      SD3neg: 3.08,
      SD2neg: 3.339,
      SD2: 4.736,
      SD3: 5.205,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 52.9,
      Median: 3.9806,
      SD3neg: 3.1,
      SD2neg: 3.361,
      SD2: 4.765,
      SD3: 5.237,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.0,
      Median: 4.006,
      SD3neg: 3.12,
      SD2neg: 3.383,
      SD2: 4.795,
      SD3: 5.27,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.1,
      Median: 4.0315,
      SD3neg: 3.14,
      SD2neg: 3.405,
      SD2: 4.825,
      SD3: 5.302,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.2,
      Median: 4.0572,
      SD3neg: 3.161,
      SD2neg: 3.427,
      SD2: 4.855,
      SD3: 5.335,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.3,
      Median: 4.0831,
      SD3neg: 3.182,
      SD2neg: 3.449,
      SD2: 4.885,
      SD3: 5.368,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.4,
      Median: 4.1092,
      SD3neg: 3.202,
      SD2neg: 3.472,
      SD2: 4.916,
      SD3: 5.401,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.5,
      Median: 4.1354,
      SD3neg: 3.223,
      SD2neg: 3.494,
      SD2: 4.947,
      SD3: 5.434,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.6,
      Median: 4.1619,
      SD3neg: 3.245,
      SD2neg: 3.517,
      SD2: 4.978,
      SD3: 5.468,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.7,
      Median: 4.1885,
      SD3neg: 3.266,
      SD2neg: 3.54,
      SD2: 5.009,
      SD3: 5.502,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.8,
      Median: 4.2153,
      SD3neg: 3.287,
      SD2neg: 3.563,
      SD2: 5.04,
      SD3: 5.536,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 53.9,
      Median: 4.2422,
      SD3neg: 3.309,
      SD2neg: 3.586,
      SD2: 5.072,
      SD3: 5.57,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.0,
      Median: 4.2693,
      SD3neg: 3.33,
      SD2neg: 3.609,
      SD2: 5.104,
      SD3: 5.605,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.1,
      Median: 4.2965,
      SD3neg: 3.352,
      SD2neg: 3.633,
      SD2: 5.136,
      SD3: 5.639,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.2,
      Median: 4.3239,
      SD3neg: 3.374,
      SD2neg: 3.656,
      SD2: 5.168,
      SD3: 5.674,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.3,
      Median: 4.3513,
      SD3neg: 3.396,
      SD2neg: 3.68,
      SD2: 5.2,
      SD3: 5.709,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.4,
      Median: 4.3789,
      SD3neg: 3.418,
      SD2neg: 3.704,
      SD2: 5.232,
      SD3: 5.744,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.5,
      Median: 4.4066,
      SD3neg: 3.44,
      SD2neg: 3.727,
      SD2: 5.264,
      SD3: 5.779,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.6,
      Median: 4.4344,
      SD3neg: 3.463,
      SD2neg: 3.751,
      SD2: 5.297,
      SD3: 5.815,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.7,
      Median: 4.4623,
      SD3neg: 3.485,
      SD2neg: 3.775,
      SD2: 5.33,
      SD3: 5.85,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.8,
      Median: 4.4903,
      SD3neg: 3.507,
      SD2neg: 3.799,
      SD2: 5.363,
      SD3: 5.886,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 54.9,
      Median: 4.5185,
      SD3neg: 3.53,
      SD2neg: 3.824,
      SD2: 5.396,
      SD3: 5.922,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.0,
      Median: 4.5467,
      SD3neg: 3.553,
      SD2neg: 3.848,
      SD2: 5.428,
      SD3: 5.957,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.1,
      Median: 4.575,
      SD3neg: 3.575,
      SD2neg: 3.872,
      SD2: 5.462,
      SD3: 5.993,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.2,
      Median: 4.6034,
      SD3neg: 3.598,
      SD2neg: 3.897,
      SD2: 5.495,
      SD3: 6.029,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.3,
      Median: 4.6319,
      SD3neg: 3.621,
      SD2neg: 3.921,
      SD2: 5.528,
      SD3: 6.066,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.4,
      Median: 4.6605,
      SD3neg: 3.644,
      SD2neg: 3.946,
      SD2: 5.562,
      SD3: 6.102,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.5,
      Median: 4.6892,
      SD3neg: 3.667,
      SD2neg: 3.971,
      SD2: 5.595,
      SD3: 6.138,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.6,
      Median: 4.718,
      SD3neg: 3.69,
      SD2neg: 3.996,
      SD2: 5.629,
      SD3: 6.175,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.7,
      Median: 4.7469,
      SD3neg: 3.713,
      SD2neg: 4.02,
      SD2: 5.663,
      SD3: 6.211,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.8,
      Median: 4.7758,
      SD3neg: 3.736,
      SD2neg: 4.045,
      SD2: 5.696,
      SD3: 6.248,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 55.9,
      Median: 4.8048,
      SD3neg: 3.76,
      SD2neg: 4.07,
      SD2: 5.73,
      SD3: 6.285,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.0,
      Median: 4.8338,
      SD3neg: 3.783,
      SD2neg: 4.095,
      SD2: 5.764,
      SD3: 6.322,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.1,
      Median: 4.8629,
      SD3neg: 3.806,
      SD2neg: 4.121,
      SD2: 5.798,
      SD3: 6.358,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.2,
      Median: 4.892,
      SD3neg: 3.83,
      SD2neg: 4.146,
      SD2: 5.832,
      SD3: 6.395,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.3,
      Median: 4.9212,
      SD3neg: 3.853,
      SD2neg: 4.171,
      SD2: 5.866,
      SD3: 6.432,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.4,
      Median: 4.9504,
      SD3neg: 3.876,
      SD2neg: 4.196,
      SD2: 5.901,
      SD3: 6.469,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.5,
      Median: 4.9796,
      SD3neg: 3.9,
      SD2neg: 4.221,
      SD2: 5.935,
      SD3: 6.506,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.6,
      Median: 5.0088,
      SD3neg: 3.923,
      SD2neg: 4.246,
      SD2: 5.969,
      SD3: 6.543,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.7,
      Median: 5.0381,
      SD3neg: 3.947,
      SD2neg: 4.272,
      SD2: 6.003,
      SD3: 6.581,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.8,
      Median: 5.0673,
      SD3neg: 3.97,
      SD2neg: 4.297,
      SD2: 6.037,
      SD3: 6.617,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 56.9,
      Median: 5.0966,
      SD3neg: 3.994,
      SD2neg: 4.322,
      SD2: 6.071,
      SD3: 6.655,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.0,
      Median: 5.1259,
      SD3neg: 4.017,
      SD2neg: 4.347,
      SD2: 6.106,
      SD3: 6.692,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.1,
      Median: 5.1551,
      SD3neg: 4.041,
      SD2neg: 4.373,
      SD2: 6.14,
      SD3: 6.728,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.2,
      Median: 5.1844,
      SD3neg: 4.064,
      SD2neg: 4.398,
      SD2: 6.174,
      SD3: 6.766,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.3,
      Median: 5.2137,
      SD3neg: 4.088,
      SD2neg: 4.423,
      SD2: 6.208,
      SD3: 6.803,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.4,
      Median: 5.2429,
      SD3neg: 4.111,
      SD2neg: 4.448,
      SD2: 6.242,
      SD3: 6.84,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.5,
      Median: 5.2721,
      SD3neg: 4.135,
      SD2neg: 4.474,
      SD2: 6.276,
      SD3: 6.877,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.6,
      Median: 5.3014,
      SD3neg: 4.159,
      SD2neg: 4.499,
      SD2: 6.31,
      SD3: 6.913,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.7,
      Median: 5.3306,
      SD3neg: 4.182,
      SD2neg: 4.524,
      SD2: 6.344,
      SD3: 6.95,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.8,
      Median: 5.3598,
      SD3neg: 4.206,
      SD2neg: 4.549,
      SD2: 6.378,
      SD3: 6.987,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 57.9,
      Median: 5.3889,
      SD3neg: 4.229,
      SD2neg: 4.574,
      SD2: 6.412,
      SD3: 7.024,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.0,
      Median: 5.418,
      SD3neg: 4.252,
      SD2neg: 4.599,
      SD2: 6.447,
      SD3: 7.061,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.1,
      Median: 5.4471,
      SD3neg: 4.276,
      SD2neg: 4.625,
      SD2: 6.48,
      SD3: 7.098,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.2,
      Median: 5.4762,
      SD3neg: 4.299,
      SD2neg: 4.65,
      SD2: 6.514,
      SD3: 7.135,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.3,
      Median: 5.5053,
      SD3neg: 4.323,
      SD2neg: 4.675,
      SD2: 6.548,
      SD3: 7.171,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.4,
      Median: 5.5343,
      SD3neg: 4.346,
      SD2neg: 4.7,
      SD2: 6.582,
      SD3: 7.208,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.5,
      Median: 5.5632,
      SD3neg: 4.369,
      SD2neg: 4.725,
      SD2: 6.616,
      SD3: 7.245,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.6,
      Median: 5.5922,
      SD3neg: 4.393,
      SD2neg: 4.75,
      SD2: 6.65,
      SD3: 7.281,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.7,
      Median: 5.621,
      SD3neg: 4.416,
      SD2neg: 4.775,
      SD2: 6.683,
      SD3: 7.318,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.8,
      Median: 5.6499,
      SD3neg: 4.439,
      SD2neg: 4.8,
      SD2: 6.717,
      SD3: 7.354,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 58.9,
      Median: 5.6787,
      SD3neg: 4.462,
      SD2neg: 4.825,
      SD2: 6.751,
      SD3: 7.391,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.0,
      Median: 5.7074,
      SD3neg: 4.485,
      SD2neg: 4.849,
      SD2: 6.784,
      SD3: 7.427,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.1,
      Median: 5.7361,
      SD3neg: 4.508,
      SD2neg: 4.874,
      SD2: 6.818,
      SD3: 7.463,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.2,
      Median: 5.7647,
      SD3neg: 4.531,
      SD2neg: 4.899,
      SD2: 6.851,
      SD3: 7.499,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.3,
      Median: 5.7933,
      SD3neg: 4.554,
      SD2neg: 4.924,
      SD2: 6.884,
      SD3: 7.535,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.4,
      Median: 5.8217,
      SD3neg: 4.577,
      SD2neg: 4.948,
      SD2: 6.917,
      SD3: 7.571,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.5,
      Median: 5.8501,
      SD3neg: 4.6,
      SD2neg: 4.973,
      SD2: 6.951,
      SD3: 7.607,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.6,
      Median: 5.8784,
      SD3neg: 4.623,
      SD2neg: 4.997,
      SD2: 6.984,
      SD3: 7.643,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.7,
      Median: 5.9067,
      SD3neg: 4.646,
      SD2neg: 5.022,
      SD2: 7.017,
      SD3: 7.679,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.8,
      Median: 5.9348,
      SD3neg: 4.668,
      SD2neg: 5.046,
      SD2: 7.049,
      SD3: 7.714,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 59.9,
      Median: 5.9628,
      SD3neg: 4.691,
      SD2neg: 5.07,
      SD2: 7.082,
      SD3: 7.75,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.0,
      Median: 5.9907,
      SD3neg: 4.713,
      SD2neg: 5.094,
      SD2: 7.115,
      SD3: 7.785,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.1,
      Median: 6.0185,
      SD3neg: 4.735,
      SD2neg: 5.118,
      SD2: 7.147,
      SD3: 7.82,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.2,
      Median: 6.0461,
      SD3neg: 4.758,
      SD2neg: 5.142,
      SD2: 7.179,
      SD3: 7.855,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.3,
      Median: 6.0737,
      SD3neg: 4.78,
      SD2neg: 5.166,
      SD2: 7.211,
      SD3: 7.89,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.4,
      Median: 6.1011,
      SD3neg: 4.802,
      SD2neg: 5.189,
      SD2: 7.244,
      SD3: 7.925,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.5,
      Median: 6.1284,
      SD3neg: 4.824,
      SD2neg: 5.213,
      SD2: 7.275,
      SD3: 7.959,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.6,
      Median: 6.1556,
      SD3neg: 4.846,
      SD2neg: 5.236,
      SD2: 7.307,
      SD3: 7.994,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.7,
      Median: 6.1827,
      SD3neg: 4.868,
      SD2neg: 5.26,
      SD2: 7.339,
      SD3: 8.028,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.8,
      Median: 6.2096,
      SD3neg: 4.889,
      SD2neg: 5.283,
      SD2: 7.37,
      SD3: 8.062,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 60.9,
      Median: 6.2365,
      SD3neg: 4.911,
      SD2neg: 5.306,
      SD2: 7.402,
      SD3: 8.096,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.0,
      Median: 6.2632,
      SD3neg: 4.932,
      SD2neg: 5.329,
      SD2: 7.433,
      SD3: 8.13,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.1,
      Median: 6.2899,
      SD3neg: 4.954,
      SD2neg: 5.352,
      SD2: 7.464,
      SD3: 8.164,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.2,
      Median: 6.3164,
      SD3neg: 4.975,
      SD2neg: 5.375,
      SD2: 7.495,
      SD3: 8.197,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.3,
      Median: 6.3428,
      SD3neg: 4.996,
      SD2neg: 5.398,
      SD2: 7.526,
      SD3: 8.231,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.4,
      Median: 6.3692,
      SD3neg: 5.017,
      SD2neg: 5.421,
      SD2: 7.557,
      SD3: 8.264,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.5,
      Median: 6.3954,
      SD3neg: 5.039,
      SD2neg: 5.443,
      SD2: 7.587,
      SD3: 8.297,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.6,
      Median: 6.4215,
      SD3neg: 5.059,
      SD2neg: 5.466,
      SD2: 7.618,
      SD3: 8.33,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.7,
      Median: 6.4475,
      SD3neg: 5.08,
      SD2neg: 5.488,
      SD2: 7.648,
      SD3: 8.363,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.8,
      Median: 6.4735,
      SD3neg: 5.101,
      SD2neg: 5.511,
      SD2: 7.679,
      SD3: 8.396,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 61.9,
      Median: 6.4993,
      SD3neg: 5.122,
      SD2neg: 5.533,
      SD2: 7.709,
      SD3: 8.429,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.0,
      Median: 6.5251,
      SD3neg: 5.143,
      SD2neg: 5.555,
      SD2: 7.739,
      SD3: 8.462,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.1,
      Median: 6.5508,
      SD3neg: 5.163,
      SD2neg: 5.577,
      SD2: 7.769,
      SD3: 8.494,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.2,
      Median: 6.5764,
      SD3neg: 5.184,
      SD2neg: 5.599,
      SD2: 7.799,
      SD3: 8.527,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.3,
      Median: 6.6019,
      SD3neg: 5.204,
      SD2neg: 5.621,
      SD2: 7.829,
      SD3: 8.559,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.4,
      Median: 6.6273,
      SD3neg: 5.225,
      SD2neg: 5.643,
      SD2: 7.858,
      SD3: 8.591,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.5,
      Median: 6.6527,
      SD3neg: 5.245,
      SD2neg: 5.665,
      SD2: 7.888,
      SD3: 8.624,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.6,
      Median: 6.678,
      SD3neg: 5.265,
      SD2neg: 5.687,
      SD2: 7.918,
      SD3: 8.656,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.7,
      Median: 6.7033,
      SD3neg: 5.286,
      SD2neg: 5.709,
      SD2: 7.947,
      SD3: 8.688,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.8,
      Median: 6.7284,
      SD3neg: 5.306,
      SD2neg: 5.73,
      SD2: 7.977,
      SD3: 8.72,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 62.9,
      Median: 6.7535,
      SD3neg: 5.326,
      SD2neg: 5.752,
      SD2: 8.006,
      SD3: 8.752,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.0,
      Median: 6.7786,
      SD3neg: 5.346,
      SD2neg: 5.774,
      SD2: 8.035,
      SD3: 8.784,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.1,
      Median: 6.8035,
      SD3neg: 5.366,
      SD2neg: 5.795,
      SD2: 8.065,
      SD3: 8.815,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.2,
      Median: 6.8285,
      SD3neg: 5.386,
      SD2neg: 5.817,
      SD2: 8.094,
      SD3: 8.847,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.3,
      Median: 6.8533,
      SD3neg: 5.406,
      SD2neg: 5.838,
      SD2: 8.123,
      SD3: 8.879,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.4,
      Median: 6.8781,
      SD3neg: 5.426,
      SD2neg: 5.859,
      SD2: 8.152,
      SD3: 8.91,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.5,
      Median: 6.9028,
      SD3neg: 5.445,
      SD2neg: 5.88,
      SD2: 8.181,
      SD3: 8.942,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.6,
      Median: 6.9275,
      SD3neg: 5.465,
      SD2neg: 5.902,
      SD2: 8.21,
      SD3: 8.973,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.7,
      Median: 6.9521,
      SD3neg: 5.485,
      SD2neg: 5.923,
      SD2: 8.239,
      SD3: 9.004,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.8,
      Median: 6.9766,
      SD3neg: 5.504,
      SD2neg: 5.944,
      SD2: 8.268,
      SD3: 9.036,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 63.9,
      Median: 7.0011,
      SD3neg: 5.524,
      SD2neg: 5.965,
      SD2: 8.296,
      SD3: 9.067,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.0,
      Median: 7.0255,
      SD3neg: 5.544,
      SD2neg: 5.986,
      SD2: 8.325,
      SD3: 9.098,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.1,
      Median: 7.0499,
      SD3neg: 5.563,
      SD2neg: 6.007,
      SD2: 8.354,
      SD3: 9.129,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.2,
      Median: 7.0742,
      SD3neg: 5.582,
      SD2neg: 6.028,
      SD2: 8.382,
      SD3: 9.16,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.3,
      Median: 7.0984,
      SD3neg: 5.602,
      SD2neg: 6.049,
      SD2: 8.41,
      SD3: 9.191,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.4,
      Median: 7.1226,
      SD3neg: 5.621,
      SD2neg: 6.069,
      SD2: 8.439,
      SD3: 9.222,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.5,
      Median: 7.1467,
      SD3neg: 5.64,
      SD2neg: 6.09,
      SD2: 8.467,
      SD3: 9.253,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.6,
      Median: 7.1708,
      SD3neg: 5.659,
      SD2neg: 6.111,
      SD2: 8.496,
      SD3: 9.284,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.7,
      Median: 7.1948,
      SD3neg: 5.679,
      SD2neg: 6.131,
      SD2: 8.524,
      SD3: 9.314,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.8,
      Median: 7.2188,
      SD3neg: 5.698,
      SD2neg: 6.152,
      SD2: 8.552,
      SD3: 9.345,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 64.9,
      Median: 7.2427,
      SD3neg: 5.717,
      SD2neg: 6.172,
      SD2: 8.58,
      SD3: 9.375,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.0,
      Median: 7.2666,
      SD3neg: 5.736,
      SD2neg: 6.193,
      SD2: 8.608,
      SD3: 9.406,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.1,
      Median: 7.2905,
      SD3neg: 5.755,
      SD2neg: 6.213,
      SD2: 8.636,
      SD3: 9.437,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.2,
      Median: 7.3143,
      SD3neg: 5.774,
      SD2neg: 6.234,
      SD2: 8.664,
      SD3: 9.467,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.3,
      Median: 7.338,
      SD3neg: 5.793,
      SD2neg: 6.254,
      SD2: 8.692,
      SD3: 9.498,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.4,
      Median: 7.3617,
      SD3neg: 5.812,
      SD2neg: 6.274,
      SD2: 8.72,
      SD3: 9.528,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.5,
      Median: 7.3854,
      SD3neg: 5.83,
      SD2neg: 6.295,
      SD2: 8.748,
      SD3: 9.558,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.6,
      Median: 7.4091,
      SD3neg: 5.849,
      SD2neg: 6.315,
      SD2: 8.776,
      SD3: 9.589,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.7,
      Median: 7.4327,
      SD3neg: 5.868,
      SD2neg: 6.335,
      SD2: 8.804,
      SD3: 9.619,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.8,
      Median: 7.4563,
      SD3neg: 5.887,
      SD2neg: 6.355,
      SD2: 8.831,
      SD3: 9.649,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 65.9,
      Median: 7.4799,
      SD3neg: 5.905,
      SD2neg: 6.376,
      SD2: 8.859,
      SD3: 9.68,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.0,
      Median: 7.5034,
      SD3neg: 5.924,
      SD2neg: 6.396,
      SD2: 8.887,
      SD3: 9.71,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.1,
      Median: 7.5269,
      SD3neg: 5.943,
      SD2neg: 6.416,
      SD2: 8.915,
      SD3: 9.74,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.2,
      Median: 7.5504,
      SD3neg: 5.961,
      SD2neg: 6.436,
      SD2: 8.943,
      SD3: 9.771,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.3,
      Median: 7.5738,
      SD3neg: 5.98,
      SD2neg: 6.456,
      SD2: 8.97,
      SD3: 9.801,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.4,
      Median: 7.5973,
      SD3neg: 5.999,
      SD2neg: 6.476,
      SD2: 8.998,
      SD3: 9.831,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.5,
      Median: 7.6206,
      SD3neg: 6.017,
      SD2neg: 6.496,
      SD2: 9.026,
      SD3: 9.861,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.6,
      Median: 7.644,
      SD3neg: 6.035,
      SD2neg: 6.516,
      SD2: 9.053,
      SD3: 9.891,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.7,
      Median: 7.6673,
      SD3neg: 6.054,
      SD2neg: 6.536,
      SD2: 9.081,
      SD3: 9.921,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.8,
      Median: 7.6906,
      SD3neg: 6.072,
      SD2neg: 6.556,
      SD2: 9.108,
      SD3: 9.951,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 66.9,
      Median: 7.7138,
      SD3neg: 6.091,
      SD2neg: 6.575,
      SD2: 9.136,
      SD3: 9.981,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.0,
      Median: 7.737,
      SD3neg: 6.109,
      SD2neg: 6.595,
      SD2: 9.163,
      SD3: 10.011,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.1,
      Median: 7.7602,
      SD3neg: 6.127,
      SD2neg: 6.615,
      SD2: 9.191,
      SD3: 10.041,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.2,
      Median: 7.7834,
      SD3neg: 6.146,
      SD2neg: 6.635,
      SD2: 9.218,
      SD3: 10.071,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.3,
      Median: 7.8065,
      SD3neg: 6.164,
      SD2neg: 6.654,
      SD2: 9.246,
      SD3: 10.101,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.4,
      Median: 7.8296,
      SD3neg: 6.182,
      SD2neg: 6.674,
      SD2: 9.273,
      SD3: 10.131,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.5,
      Median: 7.8526,
      SD3neg: 6.2,
      SD2neg: 6.694,
      SD2: 9.3,
      SD3: 10.161,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.6,
      Median: 7.8757,
      SD3neg: 6.218,
      SD2neg: 6.713,
      SD2: 9.327,
      SD3: 10.191,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.7,
      Median: 7.8986,
      SD3neg: 6.236,
      SD2neg: 6.733,
      SD2: 9.355,
      SD3: 10.221,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.8,
      Median: 7.9216,
      SD3neg: 6.255,
      SD2neg: 6.752,
      SD2: 9.382,
      SD3: 10.251,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 67.9,
      Median: 7.9445,
      SD3neg: 6.273,
      SD2neg: 6.772,
      SD2: 9.409,
      SD3: 10.28,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.0,
      Median: 7.9674,
      SD3neg: 6.291,
      SD2neg: 6.791,
      SD2: 9.436,
      SD3: 10.31,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.1,
      Median: 7.9903,
      SD3neg: 6.309,
      SD2neg: 6.811,
      SD2: 9.464,
      SD3: 10.34,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.2,
      Median: 8.0132,
      SD3neg: 6.327,
      SD2neg: 6.83,
      SD2: 9.491,
      SD3: 10.369,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.3,
      Median: 8.036,
      SD3neg: 6.345,
      SD2neg: 6.85,
      SD2: 9.518,
      SD3: 10.399,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.4,
      Median: 8.0588,
      SD3neg: 6.363,
      SD2neg: 6.869,
      SD2: 9.545,
      SD3: 10.429,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.5,
      Median: 8.0816,
      SD3neg: 6.38,
      SD2neg: 6.888,
      SD2: 9.572,
      SD3: 10.459,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.6,
      Median: 8.1044,
      SD3neg: 6.398,
      SD2neg: 6.908,
      SD2: 9.599,
      SD3: 10.488,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.7,
      Median: 8.1272,
      SD3neg: 6.416,
      SD2neg: 6.927,
      SD2: 9.626,
      SD3: 10.518,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.8,
      Median: 8.15,
      SD3neg: 6.434,
      SD2neg: 6.946,
      SD2: 9.654,
      SD3: 10.548,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 68.9,
      Median: 8.1727,
      SD3neg: 6.452,
      SD2neg: 6.966,
      SD2: 9.681,
      SD3: 10.578,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.0,
      Median: 8.1955,
      SD3neg: 6.47,
      SD2neg: 6.985,
      SD2: 9.708,
      SD3: 10.607,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.1,
      Median: 8.2183,
      SD3neg: 6.488,
      SD2neg: 7.004,
      SD2: 9.735,
      SD3: 10.637,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.2,
      Median: 8.241,
      SD3neg: 6.505,
      SD2neg: 7.024,
      SD2: 9.762,
      SD3: 10.667,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.3,
      Median: 8.2638,
      SD3neg: 6.523,
      SD2neg: 7.043,
      SD2: 9.789,
      SD3: 10.697,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.4,
      Median: 8.2865,
      SD3neg: 6.541,
      SD2neg: 7.062,
      SD2: 9.816,
      SD3: 10.726,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.5,
      Median: 8.3092,
      SD3neg: 6.559,
      SD2neg: 7.081,
      SD2: 9.843,
      SD3: 10.756,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.6,
      Median: 8.332,
      SD3neg: 6.576,
      SD2neg: 7.101,
      SD2: 9.871,
      SD3: 10.786,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.7,
      Median: 8.3547,
      SD3neg: 6.594,
      SD2neg: 7.12,
      SD2: 9.898,
      SD3: 10.816,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.8,
      Median: 8.3774,
      SD3neg: 6.612,
      SD2neg: 7.139,
      SD2: 9.925,
      SD3: 10.845,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 69.9,
      Median: 8.4001,
      SD3neg: 6.63,
      SD2neg: 7.158,
      SD2: 9.952,
      SD3: 10.875,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.0,
      Median: 8.4227,
      SD3neg: 6.647,
      SD2neg: 7.177,
      SD2: 9.979,
      SD3: 10.905,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.1,
      Median: 8.4454,
      SD3neg: 6.665,
      SD2neg: 7.197,
      SD2: 10.006,
      SD3: 10.934,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.2,
      Median: 8.468,
      SD3neg: 6.683,
      SD2neg: 7.216,
      SD2: 10.033,
      SD3: 10.964,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.3,
      Median: 8.4906,
      SD3neg: 6.7,
      SD2neg: 7.235,
      SD2: 10.06,
      SD3: 10.994,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.4,
      Median: 8.5132,
      SD3neg: 6.718,
      SD2neg: 7.254,
      SD2: 10.087,
      SD3: 11.023,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.5,
      Median: 8.5358,
      SD3neg: 6.735,
      SD2neg: 7.273,
      SD2: 10.114,
      SD3: 11.053,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.6,
      Median: 8.5583,
      SD3neg: 6.753,
      SD2neg: 7.292,
      SD2: 10.141,
      SD3: 11.083,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.7,
      Median: 8.5808,
      SD3neg: 6.771,
      SD2neg: 7.311,
      SD2: 10.168,
      SD3: 11.112,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.8,
      Median: 8.6032,
      SD3neg: 6.788,
      SD2neg: 7.33,
      SD2: 10.195,
      SD3: 11.142,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 70.9,
      Median: 8.6257,
      SD3neg: 6.805,
      SD2neg: 7.349,
      SD2: 10.222,
      SD3: 11.172,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.0,
      Median: 8.648,
      SD3neg: 6.823,
      SD2neg: 7.368,
      SD2: 10.248,
      SD3: 11.201,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.1,
      Median: 8.6704,
      SD3neg: 6.84,
      SD2neg: 7.387,
      SD2: 10.275,
      SD3: 11.23,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.2,
      Median: 8.6927,
      SD3neg: 6.858,
      SD2neg: 7.406,
      SD2: 10.302,
      SD3: 11.259,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.3,
      Median: 8.715,
      SD3neg: 6.875,
      SD2neg: 7.424,
      SD2: 10.329,
      SD3: 11.289,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.4,
      Median: 8.7372,
      SD3neg: 6.892,
      SD2neg: 7.443,
      SD2: 10.355,
      SD3: 11.318,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.5,
      Median: 8.7594,
      SD3neg: 6.909,
      SD2neg: 7.462,
      SD2: 10.382,
      SD3: 11.348,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.6,
      Median: 8.7815,
      SD3neg: 6.927,
      SD2neg: 7.48,
      SD2: 10.408,
      SD3: 11.377,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.7,
      Median: 8.8036,
      SD3neg: 6.944,
      SD2neg: 7.499,
      SD2: 10.435,
      SD3: 11.406,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.8,
      Median: 8.8257,
      SD3neg: 6.961,
      SD2neg: 7.518,
      SD2: 10.461,
      SD3: 11.435,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 71.9,
      Median: 8.8477,
      SD3neg: 6.978,
      SD2neg: 7.536,
      SD2: 10.488,
      SD3: 11.464,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.0,
      Median: 8.8697,
      SD3neg: 6.995,
      SD2neg: 7.555,
      SD2: 10.514,
      SD3: 11.493,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.1,
      Median: 8.8916,
      SD3neg: 7.012,
      SD2neg: 7.573,
      SD2: 10.54,
      SD3: 11.522,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.2,
      Median: 8.9135,
      SD3neg: 7.029,
      SD2neg: 7.592,
      SD2: 10.567,
      SD3: 11.551,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.3,
      Median: 8.9353,
      SD3neg: 7.046,
      SD2neg: 7.61,
      SD2: 10.593,
      SD3: 11.58,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.4,
      Median: 8.9571,
      SD3neg: 7.063,
      SD2neg: 7.628,
      SD2: 10.619,
      SD3: 11.608,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.5,
      Median: 8.9788,
      SD3neg: 7.08,
      SD2neg: 7.647,
      SD2: 10.645,
      SD3: 11.637,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.6,
      Median: 9.0005,
      SD3neg: 7.097,
      SD2neg: 7.665,
      SD2: 10.671,
      SD3: 11.666,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.7,
      Median: 9.0221,
      SD3neg: 7.113,
      SD2neg: 7.683,
      SD2: 10.697,
      SD3: 11.694,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.8,
      Median: 9.0436,
      SD3neg: 7.13,
      SD2neg: 7.701,
      SD2: 10.723,
      SD3: 11.723,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 72.9,
      Median: 9.0651,
      SD3neg: 7.147,
      SD2neg: 7.719,
      SD2: 10.749,
      SD3: 11.751,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.0,
      Median: 9.0865,
      SD3neg: 7.163,
      SD2neg: 7.737,
      SD2: 10.774,
      SD3: 11.78,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.1,
      Median: 9.1079,
      SD3neg: 7.18,
      SD2neg: 7.755,
      SD2: 10.8,
      SD3: 11.808,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.2,
      Median: 9.1292,
      SD3neg: 7.196,
      SD2neg: 7.773,
      SD2: 10.826,
      SD3: 11.836,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.3,
      Median: 9.1504,
      SD3neg: 7.213,
      SD2neg: 7.791,
      SD2: 10.851,
      SD3: 11.864,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.4,
      Median: 9.1716,
      SD3neg: 7.229,
      SD2neg: 7.809,
      SD2: 10.877,
      SD3: 11.892,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.5,
      Median: 9.1927,
      SD3neg: 7.246,
      SD2neg: 7.827,
      SD2: 10.902,
      SD3: 11.92,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.6,
      Median: 9.2137,
      SD3neg: 7.262,
      SD2neg: 7.844,
      SD2: 10.927,
      SD3: 11.948,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.7,
      Median: 9.2347,
      SD3neg: 7.278,
      SD2neg: 7.862,
      SD2: 10.952,
      SD3: 11.975,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.8,
      Median: 9.2557,
      SD3neg: 7.294,
      SD2neg: 7.88,
      SD2: 10.978,
      SD3: 12.003,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 73.9,
      Median: 9.2766,
      SD3neg: 7.311,
      SD2neg: 7.897,
      SD2: 11.003,
      SD3: 12.031,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.0,
      Median: 9.2974,
      SD3neg: 7.327,
      SD2neg: 7.915,
      SD2: 11.028,
      SD3: 12.059,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.1,
      Median: 9.3182,
      SD3neg: 7.343,
      SD2neg: 7.932,
      SD2: 11.053,
      SD3: 12.086,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.2,
      Median: 9.339,
      SD3neg: 7.359,
      SD2neg: 7.95,
      SD2: 11.078,
      SD3: 12.113,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.3,
      Median: 9.3597,
      SD3neg: 7.375,
      SD2neg: 7.967,
      SD2: 11.103,
      SD3: 12.141,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.4,
      Median: 9.3803,
      SD3neg: 7.391,
      SD2neg: 7.985,
      SD2: 11.127,
      SD3: 12.168,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.5,
      Median: 9.401,
      SD3neg: 7.407,
      SD2neg: 8.002,
      SD2: 11.152,
      SD3: 12.195,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.6,
      Median: 9.4215,
      SD3neg: 7.423,
      SD2neg: 8.019,
      SD2: 11.177,
      SD3: 12.222,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.7,
      Median: 9.442,
      SD3neg: 7.439,
      SD2neg: 8.036,
      SD2: 11.201,
      SD3: 12.25,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.8,
      Median: 9.4625,
      SD3neg: 7.455,
      SD2neg: 8.054,
      SD2: 11.226,
      SD3: 12.277,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 74.9,
      Median: 9.4829,
      SD3neg: 7.471,
      SD2neg: 8.071,
      SD2: 11.25,
      SD3: 12.304,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.0,
      Median: 9.5032,
      SD3neg: 7.486,
      SD2neg: 8.088,
      SD2: 11.275,
      SD3: 12.33,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.1,
      Median: 9.5235,
      SD3neg: 7.502,
      SD2neg: 8.105,
      SD2: 11.299,
      SD3: 12.357,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.2,
      Median: 9.5438,
      SD3neg: 7.518,
      SD2neg: 8.122,
      SD2: 11.324,
      SD3: 12.384,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.3,
      Median: 9.5639,
      SD3neg: 7.533,
      SD2neg: 8.139,
      SD2: 11.348,
      SD3: 12.411,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.4,
      Median: 9.5841,
      SD3neg: 7.549,
      SD2neg: 8.156,
      SD2: 11.372,
      SD3: 12.437,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.5,
      Median: 9.6041,
      SD3neg: 7.565,
      SD2neg: 8.173,
      SD2: 11.396,
      SD3: 12.464,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.6,
      Median: 9.6241,
      SD3neg: 7.58,
      SD2neg: 8.19,
      SD2: 11.42,
      SD3: 12.49,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.7,
      Median: 9.644,
      SD3neg: 7.596,
      SD2neg: 8.207,
      SD2: 11.444,
      SD3: 12.516,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.8,
      Median: 9.6639,
      SD3neg: 7.611,
      SD2neg: 8.223,
      SD2: 11.468,
      SD3: 12.543,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 75.9,
      Median: 9.6836,
      SD3neg: 7.626,
      SD2neg: 8.24,
      SD2: 11.492,
      SD3: 12.569,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.0,
      Median: 9.7033,
      SD3neg: 7.641,
      SD2neg: 8.257,
      SD2: 11.515,
      SD3: 12.595,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.1,
      Median: 9.723,
      SD3neg: 7.657,
      SD2neg: 8.273,
      SD2: 11.539,
      SD3: 12.621,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.2,
      Median: 9.7425,
      SD3neg: 7.672,
      SD2neg: 8.29,
      SD2: 11.562,
      SD3: 12.646,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.3,
      Median: 9.762,
      SD3neg: 7.687,
      SD2neg: 8.306,
      SD2: 11.585,
      SD3: 12.672,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.4,
      Median: 9.7814,
      SD3neg: 7.702,
      SD2neg: 8.323,
      SD2: 11.609,
      SD3: 12.698,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.5,
      Median: 9.8007,
      SD3neg: 7.717,
      SD2neg: 8.339,
      SD2: 11.632,
      SD3: 12.723,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.6,
      Median: 9.82,
      SD3neg: 7.732,
      SD2neg: 8.355,
      SD2: 11.655,
      SD3: 12.749,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.7,
      Median: 9.8392,
      SD3neg: 7.747,
      SD2neg: 8.371,
      SD2: 11.678,
      SD3: 12.773,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.8,
      Median: 9.8583,
      SD3neg: 7.762,
      SD2neg: 8.387,
      SD2: 11.701,
      SD3: 12.799,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 76.9,
      Median: 9.8773,
      SD3neg: 7.777,
      SD2neg: 8.403,
      SD2: 11.723,
      SD3: 12.824,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.0,
      Median: 9.8963,
      SD3neg: 7.792,
      SD2neg: 8.42,
      SD2: 11.746,
      SD3: 12.848,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.1,
      Median: 9.9152,
      SD3neg: 7.807,
      SD2neg: 8.436,
      SD2: 11.769,
      SD3: 12.873,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.2,
      Median: 9.9341,
      SD3neg: 7.821,
      SD2neg: 8.452,
      SD2: 11.791,
      SD3: 12.898,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.3,
      Median: 9.9528,
      SD3neg: 7.836,
      SD2neg: 8.467,
      SD2: 11.813,
      SD3: 12.923,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.4,
      Median: 9.9716,
      SD3neg: 7.851,
      SD2neg: 8.483,
      SD2: 11.836,
      SD3: 12.947,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.5,
      Median: 9.9902,
      SD3neg: 7.865,
      SD2neg: 8.499,
      SD2: 11.858,
      SD3: 12.972,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.6,
      Median: 10.0088,
      SD3neg: 7.88,
      SD2neg: 8.515,
      SD2: 11.88,
      SD3: 12.996,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.7,
      Median: 10.0274,
      SD3neg: 7.895,
      SD2neg: 8.531,
      SD2: 11.902,
      SD3: 13.02,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.8,
      Median: 10.0459,
      SD3neg: 7.909,
      SD2neg: 8.546,
      SD2: 11.925,
      SD3: 13.044,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 77.9,
      Median: 10.0643,
      SD3neg: 7.923,
      SD2neg: 8.562,
      SD2: 11.946,
      SD3: 13.068,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.0,
      Median: 10.0827,
      SD3neg: 7.938,
      SD2neg: 8.578,
      SD2: 11.968,
      SD3: 13.092,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.1,
      Median: 10.1011,
      SD3neg: 7.952,
      SD2neg: 8.593,
      SD2: 11.99,
      SD3: 13.116,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.2,
      Median: 10.1194,
      SD3neg: 7.967,
      SD2neg: 8.609,
      SD2: 12.012,
      SD3: 13.14,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.3,
      Median: 10.1377,
      SD3neg: 7.981,
      SD2neg: 8.624,
      SD2: 12.033,
      SD3: 13.164,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.4,
      Median: 10.1559,
      SD3neg: 7.995,
      SD2neg: 8.64,
      SD2: 12.055,
      SD3: 13.187,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.5,
      Median: 10.1741,
      SD3neg: 8.01,
      SD2neg: 8.655,
      SD2: 12.077,
      SD3: 13.211,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.6,
      Median: 10.1923,
      SD3neg: 8.024,
      SD2neg: 8.671,
      SD2: 12.098,
      SD3: 13.234,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.7,
      Median: 10.2105,
      SD3neg: 8.039,
      SD2neg: 8.686,
      SD2: 12.12,
      SD3: 13.258,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.8,
      Median: 10.2286,
      SD3neg: 8.053,
      SD2neg: 8.702,
      SD2: 12.141,
      SD3: 13.281,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 78.9,
      Median: 10.2468,
      SD3neg: 8.067,
      SD2neg: 8.718,
      SD2: 12.162,
      SD3: 13.304,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.0,
      Median: 10.2649,
      SD3neg: 8.082,
      SD2neg: 8.733,
      SD2: 12.184,
      SD3: 13.328,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.1,
      Median: 10.2831,
      SD3neg: 8.096,
      SD2neg: 8.749,
      SD2: 12.205,
      SD3: 13.351,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.2,
      Median: 10.3012,
      SD3neg: 8.111,
      SD2neg: 8.764,
      SD2: 12.227,
      SD3: 13.375,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.3,
      Median: 10.3194,
      SD3neg: 8.125,
      SD2neg: 8.78,
      SD2: 12.248,
      SD3: 13.398,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.4,
      Median: 10.3376,
      SD3neg: 8.14,
      SD2neg: 8.795,
      SD2: 12.269,
      SD3: 13.421,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.5,
      Median: 10.3558,
      SD3neg: 8.154,
      SD2neg: 8.811,
      SD2: 12.291,
      SD3: 13.445,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.6,
      Median: 10.3741,
      SD3neg: 8.169,
      SD2neg: 8.826,
      SD2: 12.313,
      SD3: 13.468,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.7,
      Median: 10.3923,
      SD3neg: 8.183,
      SD2neg: 8.842,
      SD2: 12.334,
      SD3: 13.491,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.8,
      Median: 10.4107,
      SD3neg: 8.198,
      SD2neg: 8.858,
      SD2: 12.355,
      SD3: 13.514,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 79.9,
      Median: 10.4291,
      SD3neg: 8.213,
      SD2neg: 8.874,
      SD2: 12.377,
      SD3: 13.538,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.0,
      Median: 10.4475,
      SD3neg: 8.227,
      SD2neg: 8.89,
      SD2: 12.399,
      SD3: 13.561,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.1,
      Median: 10.466,
      SD3neg: 8.242,
      SD2neg: 8.906,
      SD2: 12.42,
      SD3: 13.585,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.2,
      Median: 10.4845,
      SD3neg: 8.257,
      SD2neg: 8.922,
      SD2: 12.442,
      SD3: 13.608,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.3,
      Median: 10.5031,
      SD3neg: 8.272,
      SD2neg: 8.938,
      SD2: 12.464,
      SD3: 13.632,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.4,
      Median: 10.5217,
      SD3neg: 8.287,
      SD2neg: 8.954,
      SD2: 12.485,
      SD3: 13.655,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.5,
      Median: 10.5405,
      SD3neg: 8.302,
      SD2neg: 8.97,
      SD2: 12.507,
      SD3: 13.679,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.6,
      Median: 10.5592,
      SD3neg: 8.317,
      SD2neg: 8.986,
      SD2: 12.529,
      SD3: 13.703,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.7,
      Median: 10.5781,
      SD3neg: 8.332,
      SD2neg: 9.002,
      SD2: 12.551,
      SD3: 13.726,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.8,
      Median: 10.597,
      SD3neg: 8.348,
      SD2neg: 9.019,
      SD2: 12.573,
      SD3: 13.75,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 80.9,
      Median: 10.6161,
      SD3neg: 8.363,
      SD2neg: 9.035,
      SD2: 12.595,
      SD3: 13.774,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.0,
      Median: 10.6352,
      SD3neg: 8.379,
      SD2neg: 9.052,
      SD2: 12.617,
      SD3: 13.798,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.1,
      Median: 10.6544,
      SD3neg: 8.394,
      SD2neg: 9.069,
      SD2: 12.64,
      SD3: 13.822,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.2,
      Median: 10.6737,
      SD3neg: 8.41,
      SD2neg: 9.085,
      SD2: 12.662,
      SD3: 13.847,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.3,
      Median: 10.6931,
      SD3neg: 8.425,
      SD2neg: 9.102,
      SD2: 12.685,
      SD3: 13.871,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.4,
      Median: 10.7126,
      SD3neg: 8.441,
      SD2neg: 9.119,
      SD2: 12.707,
      SD3: 13.895,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.5,
      Median: 10.7322,
      SD3neg: 8.457,
      SD2neg: 9.136,
      SD2: 12.73,
      SD3: 13.92,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.6,
      Median: 10.752,
      SD3neg: 8.473,
      SD2neg: 9.153,
      SD2: 12.753,
      SD3: 13.945,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.7,
      Median: 10.7718,
      SD3neg: 8.489,
      SD2neg: 9.171,
      SD2: 12.776,
      SD3: 13.969,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.8,
      Median: 10.7918,
      SD3neg: 8.506,
      SD2neg: 9.188,
      SD2: 12.799,
      SD3: 13.994,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 81.9,
      Median: 10.8119,
      SD3neg: 8.522,
      SD2neg: 9.205,
      SD2: 12.822,
      SD3: 14.019,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.0,
      Median: 10.8321,
      SD3neg: 8.538,
      SD2neg: 9.223,
      SD2: 12.845,
      SD3: 14.044,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.1,
      Median: 10.8524,
      SD3neg: 8.555,
      SD2neg: 9.241,
      SD2: 12.869,
      SD3: 14.069,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.2,
      Median: 10.8728,
      SD3neg: 8.572,
      SD2neg: 9.258,
      SD2: 12.892,
      SD3: 14.095,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.3,
      Median: 10.8934,
      SD3neg: 8.589,
      SD2neg: 9.277,
      SD2: 12.916,
      SD3: 14.12,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.4,
      Median: 10.9142,
      SD3neg: 8.606,
      SD2neg: 9.295,
      SD2: 12.94,
      SD3: 14.146,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.5,
      Median: 10.935,
      SD3neg: 8.623,
      SD2neg: 9.313,
      SD2: 12.964,
      SD3: 14.172,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.6,
      Median: 10.956,
      SD3neg: 8.64,
      SD2neg: 9.331,
      SD2: 12.988,
      SD3: 14.198,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.7,
      Median: 10.9772,
      SD3neg: 8.657,
      SD2neg: 9.35,
      SD2: 13.013,
      SD3: 14.224,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.8,
      Median: 10.9985,
      SD3neg: 8.675,
      SD2neg: 9.368,
      SD2: 13.037,
      SD3: 14.25,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 82.9,
      Median: 11.0199,
      SD3neg: 8.692,
      SD2neg: 9.387,
      SD2: 13.061,
      SD3: 14.277,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.0,
      Median: 11.0415,
      SD3neg: 8.71,
      SD2neg: 9.406,
      SD2: 13.086,
      SD3: 14.303,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.1,
      Median: 11.0632,
      SD3neg: 8.728,
      SD2neg: 9.425,
      SD2: 13.111,
      SD3: 14.33,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.2,
      Median: 11.0851,
      SD3neg: 8.746,
      SD2neg: 9.444,
      SD2: 13.137,
      SD3: 14.357,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.3,
      Median: 11.1071,
      SD3neg: 8.764,
      SD2neg: 9.463,
      SD2: 13.162,
      SD3: 14.384,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.4,
      Median: 11.1293,
      SD3neg: 8.782,
      SD2neg: 9.483,
      SD2: 13.187,
      SD3: 14.412,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.5,
      Median: 11.1516,
      SD3neg: 8.8,
      SD2neg: 9.503,
      SD2: 13.213,
      SD3: 14.439,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.6,
      Median: 11.174,
      SD3neg: 8.819,
      SD2neg: 9.522,
      SD2: 13.238,
      SD3: 14.466,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.7,
      Median: 11.1966,
      SD3neg: 8.837,
      SD2neg: 9.542,
      SD2: 13.264,
      SD3: 14.494,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.8,
      Median: 11.2193,
      SD3neg: 8.856,
      SD2neg: 9.562,
      SD2: 13.29,
      SD3: 14.522,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 83.9,
      Median: 11.2422,
      SD3neg: 8.875,
      SD2neg: 9.582,
      SD2: 13.316,
      SD3: 14.55,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.0,
      Median: 11.2651,
      SD3neg: 8.894,
      SD2neg: 9.602,
      SD2: 13.342,
      SD3: 14.578,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.1,
      Median: 11.2882,
      SD3neg: 8.913,
      SD2neg: 9.622,
      SD2: 13.369,
      SD3: 14.606,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.2,
      Median: 11.3114,
      SD3neg: 8.932,
      SD2neg: 9.643,
      SD2: 13.396,
      SD3: 14.635,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.3,
      Median: 11.3347,
      SD3neg: 8.951,
      SD2neg: 9.663,
      SD2: 13.422,
      SD3: 14.663,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.4,
      Median: 11.3581,
      SD3neg: 8.971,
      SD2neg: 9.684,
      SD2: 13.449,
      SD3: 14.692,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.5,
      Median: 11.3817,
      SD3neg: 8.99,
      SD2neg: 9.705,
      SD2: 13.476,
      SD3: 14.721,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.6,
      Median: 11.4053,
      SD3neg: 9.01,
      SD2neg: 9.725,
      SD2: 13.503,
      SD3: 14.75,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.7,
      Median: 11.429,
      SD3neg: 9.029,
      SD2neg: 9.746,
      SD2: 13.53,
      SD3: 14.778,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.8,
      Median: 11.4529,
      SD3neg: 9.049,
      SD2neg: 9.767,
      SD2: 13.557,
      SD3: 14.808,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 84.9,
      Median: 11.4768,
      SD3neg: 9.069,
      SD2neg: 9.788,
      SD2: 13.584,
      SD3: 14.837,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.0,
      Median: 11.5007,
      SD3neg: 9.088,
      SD2neg: 9.809,
      SD2: 13.612,
      SD3: 14.866,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.1,
      Median: 11.5248,
      SD3neg: 9.109,
      SD2neg: 9.831,
      SD2: 13.639,
      SD3: 14.895,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.2,
      Median: 11.549,
      SD3neg: 9.128,
      SD2neg: 9.852,
      SD2: 13.667,
      SD3: 14.925,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.3,
      Median: 11.5732,
      SD3neg: 9.149,
      SD2neg: 9.873,
      SD2: 13.694,
      SD3: 14.955,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.4,
      Median: 11.5975,
      SD3neg: 9.169,
      SD2neg: 9.895,
      SD2: 13.722,
      SD3: 14.984,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.5,
      Median: 11.6218,
      SD3neg: 9.189,
      SD2neg: 9.916,
      SD2: 13.75,
      SD3: 15.014,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.6,
      Median: 11.6462,
      SD3neg: 9.209,
      SD2neg: 9.938,
      SD2: 13.777,
      SD3: 15.043,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.7,
      Median: 11.6707,
      SD3neg: 9.229,
      SD2neg: 9.959,
      SD2: 13.806,
      SD3: 15.074,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.8,
      Median: 11.6952,
      SD3neg: 9.25,
      SD2neg: 9.981,
      SD2: 13.833,
      SD3: 15.103,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 85.9,
      Median: 11.7198,
      SD3neg: 9.27,
      SD2neg: 10.002,
      SD2: 13.861,
      SD3: 15.133,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.0,
      Median: 11.7444,
      SD3neg: 9.29,
      SD2neg: 10.024,
      SD2: 13.89,
      SD3: 15.163,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.1,
      Median: 11.769,
      SD3neg: 9.311,
      SD2neg: 10.046,
      SD2: 13.917,
      SD3: 15.193,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.2,
      Median: 11.7937,
      SD3neg: 9.331,
      SD2neg: 10.067,
      SD2: 13.946,
      SD3: 15.224,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.3,
      Median: 11.8184,
      SD3neg: 9.352,
      SD2neg: 10.089,
      SD2: 13.974,
      SD3: 15.253,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.4,
      Median: 11.8431,
      SD3neg: 9.372,
      SD2neg: 10.111,
      SD2: 14.002,
      SD3: 15.284,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.5,
      Median: 11.8678,
      SD3neg: 9.392,
      SD2neg: 10.133,
      SD2: 14.03,
      SD3: 15.314,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.6,
      Median: 11.8926,
      SD3neg: 9.413,
      SD2neg: 10.154,
      SD2: 14.059,
      SD3: 15.344,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.7,
      Median: 11.9173,
      SD3neg: 9.433,
      SD2neg: 10.176,
      SD2: 14.087,
      SD3: 15.374,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.8,
      Median: 11.9421,
      SD3neg: 9.454,
      SD2neg: 10.198,
      SD2: 14.115,
      SD3: 15.405,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 86.9,
      Median: 11.9668,
      SD3neg: 9.474,
      SD2neg: 10.22,
      SD2: 14.143,
      SD3: 15.435,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.0,
      Median: 11.9916,
      SD3neg: 9.495,
      SD2neg: 10.242,
      SD2: 14.172,
      SD3: 15.465,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.1,
      Median: 12.0163,
      SD3neg: 9.515,
      SD2neg: 10.263,
      SD2: 14.2,
      SD3: 15.496,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.2,
      Median: 12.0411,
      SD3neg: 9.536,
      SD2neg: 10.285,
      SD2: 14.228,
      SD3: 15.526,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.3,
      Median: 12.0658,
      SD3neg: 9.556,
      SD2neg: 10.307,
      SD2: 14.257,
      SD3: 15.556,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.4,
      Median: 12.0905,
      SD3neg: 9.576,
      SD2neg: 10.328,
      SD2: 14.285,
      SD3: 15.587,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.5,
      Median: 12.1152,
      SD3neg: 9.597,
      SD2neg: 10.35,
      SD2: 14.313,
      SD3: 15.617,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.6,
      Median: 12.1398,
      SD3neg: 9.617,
      SD2neg: 10.372,
      SD2: 14.341,
      SD3: 15.647,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.7,
      Median: 12.1645,
      SD3neg: 9.637,
      SD2neg: 10.393,
      SD2: 14.37,
      SD3: 15.677,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.8,
      Median: 12.1891,
      SD3neg: 9.658,
      SD2neg: 10.415,
      SD2: 14.398,
      SD3: 15.708,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 87.9,
      Median: 12.2136,
      SD3neg: 9.678,
      SD2neg: 10.436,
      SD2: 14.426,
      SD3: 15.738,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.0,
      Median: 12.2382,
      SD3neg: 9.698,
      SD2neg: 10.458,
      SD2: 14.454,
      SD3: 15.768,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.1,
      Median: 12.2627,
      SD3neg: 9.718,
      SD2neg: 10.479,
      SD2: 14.482,
      SD3: 15.798,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.2,
      Median: 12.2871,
      SD3neg: 9.738,
      SD2neg: 10.501,
      SD2: 14.51,
      SD3: 15.828,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.3,
      Median: 12.3116,
      SD3neg: 9.758,
      SD2neg: 10.522,
      SD2: 14.538,
      SD3: 15.859,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.4,
      Median: 12.336,
      SD3neg: 9.778,
      SD2neg: 10.544,
      SD2: 14.566,
      SD3: 15.889,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.5,
      Median: 12.3603,
      SD3neg: 9.798,
      SD2neg: 10.565,
      SD2: 14.594,
      SD3: 15.919,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.6,
      Median: 12.3846,
      SD3neg: 9.818,
      SD2neg: 10.586,
      SD2: 14.622,
      SD3: 15.949,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.7,
      Median: 12.4089,
      SD3neg: 9.838,
      SD2neg: 10.607,
      SD2: 14.65,
      SD3: 15.979,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.8,
      Median: 12.4332,
      SD3neg: 9.858,
      SD2neg: 10.629,
      SD2: 14.678,
      SD3: 16.009,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 88.9,
      Median: 12.4574,
      SD3neg: 9.877,
      SD2neg: 10.65,
      SD2: 14.706,
      SD3: 16.039,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.0,
      Median: 12.4815,
      SD3neg: 9.897,
      SD2neg: 10.671,
      SD2: 14.734,
      SD3: 16.069,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.1,
      Median: 12.5057,
      SD3neg: 9.917,
      SD2neg: 10.692,
      SD2: 14.762,
      SD3: 16.099,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.2,
      Median: 12.5298,
      SD3neg: 9.937,
      SD2neg: 10.713,
      SD2: 14.79,
      SD3: 16.129,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.3,
      Median: 12.5538,
      SD3neg: 9.956,
      SD2neg: 10.734,
      SD2: 14.818,
      SD3: 16.159,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.4,
      Median: 12.5778,
      SD3neg: 9.976,
      SD2neg: 10.755,
      SD2: 14.845,
      SD3: 16.189,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.5,
      Median: 12.6017,
      SD3neg: 9.995,
      SD2neg: 10.775,
      SD2: 14.873,
      SD3: 16.219,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.6,
      Median: 12.6257,
      SD3neg: 10.015,
      SD2neg: 10.796,
      SD2: 14.901,
      SD3: 16.249,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.7,
      Median: 12.6495,
      SD3neg: 10.034,
      SD2neg: 10.817,
      SD2: 14.928,
      SD3: 16.278,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.8,
      Median: 12.6734,
      SD3neg: 10.053,
      SD2neg: 10.838,
      SD2: 14.956,
      SD3: 16.309,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 89.9,
      Median: 12.6972,
      SD3neg: 10.073,
      SD2neg: 10.858,
      SD2: 14.984,
      SD3: 16.338,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.0,
      Median: 12.7209,
      SD3neg: 10.092,
      SD2neg: 10.879,
      SD2: 15.011,
      SD3: 16.368,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.1,
      Median: 12.7446,
      SD3neg: 10.111,
      SD2neg: 10.899,
      SD2: 15.039,
      SD3: 16.398,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.2,
      Median: 12.7683,
      SD3neg: 10.13,
      SD2neg: 10.92,
      SD2: 15.066,
      SD3: 16.428,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.3,
      Median: 12.792,
      SD3neg: 10.149,
      SD2neg: 10.94,
      SD2: 15.094,
      SD3: 16.458,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.4,
      Median: 12.8156,
      SD3neg: 10.169,
      SD2neg: 10.961,
      SD2: 15.121,
      SD3: 16.487,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.5,
      Median: 12.8392,
      SD3neg: 10.188,
      SD2neg: 10.981,
      SD2: 15.149,
      SD3: 16.517,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.6,
      Median: 12.8628,
      SD3neg: 10.207,
      SD2neg: 11.002,
      SD2: 15.176,
      SD3: 16.546,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.7,
      Median: 12.8864,
      SD3neg: 10.226,
      SD2neg: 11.022,
      SD2: 15.204,
      SD3: 16.576,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.8,
      Median: 12.9099,
      SD3neg: 10.244,
      SD2neg: 11.043,
      SD2: 15.231,
      SD3: 16.606,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 90.9,
      Median: 12.9334,
      SD3neg: 10.263,
      SD2neg: 11.063,
      SD2: 15.258,
      SD3: 16.636,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.0,
      Median: 12.9569,
      SD3neg: 10.282,
      SD2neg: 11.083,
      SD2: 15.286,
      SD3: 16.666,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.1,
      Median: 12.9804,
      SD3neg: 10.301,
      SD2neg: 11.103,
      SD2: 15.314,
      SD3: 16.696,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.2,
      Median: 13.0038,
      SD3neg: 10.32,
      SD2neg: 11.123,
      SD2: 15.341,
      SD3: 16.725,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.3,
      Median: 13.0273,
      SD3neg: 10.339,
      SD2neg: 11.144,
      SD2: 15.368,
      SD3: 16.755,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.4,
      Median: 13.0507,
      SD3neg: 10.357,
      SD2neg: 11.164,
      SD2: 15.396,
      SD3: 16.785,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.5,
      Median: 13.0742,
      SD3neg: 10.376,
      SD2neg: 11.184,
      SD2: 15.423,
      SD3: 16.815,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.6,
      Median: 13.0976,
      SD3neg: 10.395,
      SD2neg: 11.204,
      SD2: 15.451,
      SD3: 16.845,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.7,
      Median: 13.1209,
      SD3neg: 10.414,
      SD2neg: 11.224,
      SD2: 15.478,
      SD3: 16.874,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.8,
      Median: 13.1443,
      SD3neg: 10.432,
      SD2neg: 11.244,
      SD2: 15.506,
      SD3: 16.904,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 91.9,
      Median: 13.1677,
      SD3neg: 10.451,
      SD2neg: 11.264,
      SD2: 15.533,
      SD3: 16.934,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.0,
      Median: 13.191,
      SD3neg: 10.469,
      SD2neg: 11.284,
      SD2: 15.561,
      SD3: 16.964,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.1,
      Median: 13.2143,
      SD3neg: 10.488,
      SD2neg: 11.304,
      SD2: 15.588,
      SD3: 16.994,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.2,
      Median: 13.2376,
      SD3neg: 10.507,
      SD2neg: 11.324,
      SD2: 15.615,
      SD3: 17.024,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.3,
      Median: 13.2609,
      SD3neg: 10.525,
      SD2neg: 11.344,
      SD2: 15.643,
      SD3: 17.054,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.4,
      Median: 13.2842,
      SD3neg: 10.544,
      SD2neg: 11.364,
      SD2: 15.67,
      SD3: 17.083,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.5,
      Median: 13.3075,
      SD3neg: 10.562,
      SD2neg: 11.384,
      SD2: 15.698,
      SD3: 17.114,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.6,
      Median: 13.3308,
      SD3neg: 10.58,
      SD2neg: 11.404,
      SD2: 15.726,
      SD3: 17.144,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.7,
      Median: 13.3541,
      SD3neg: 10.599,
      SD2neg: 11.424,
      SD2: 15.753,
      SD3: 17.174,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.8,
      Median: 13.3773,
      SD3neg: 10.617,
      SD2neg: 11.444,
      SD2: 15.78,
      SD3: 17.204,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 92.9,
      Median: 13.4006,
      SD3neg: 10.635,
      SD2neg: 11.463,
      SD2: 15.808,
      SD3: 17.234,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.0,
      Median: 13.4239,
      SD3neg: 10.654,
      SD2neg: 11.483,
      SD2: 15.836,
      SD3: 17.264,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.1,
      Median: 13.4472,
      SD3neg: 10.672,
      SD2neg: 11.503,
      SD2: 15.864,
      SD3: 17.295,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.2,
      Median: 13.4705,
      SD3neg: 10.691,
      SD2neg: 11.523,
      SD2: 15.891,
      SD3: 17.325,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.3,
      Median: 13.4937,
      SD3neg: 10.709,
      SD2neg: 11.543,
      SD2: 15.919,
      SD3: 17.355,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.4,
      Median: 13.5171,
      SD3neg: 10.727,
      SD2neg: 11.563,
      SD2: 15.946,
      SD3: 17.385,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.5,
      Median: 13.5404,
      SD3neg: 10.745,
      SD2neg: 11.582,
      SD2: 15.974,
      SD3: 17.416,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.6,
      Median: 13.5637,
      SD3neg: 10.764,
      SD2neg: 11.602,
      SD2: 16.002,
      SD3: 17.446,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.7,
      Median: 13.587,
      SD3neg: 10.782,
      SD2neg: 11.622,
      SD2: 16.03,
      SD3: 17.477,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.8,
      Median: 13.6104,
      SD3neg: 10.8,
      SD2neg: 11.641,
      SD2: 16.058,
      SD3: 17.508,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 93.9,
      Median: 13.6338,
      SD3neg: 10.818,
      SD2neg: 11.661,
      SD2: 16.086,
      SD3: 17.538,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.0,
      Median: 13.6572,
      SD3neg: 10.837,
      SD2neg: 11.681,
      SD2: 16.114,
      SD3: 17.569,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.1,
      Median: 13.6806,
      SD3neg: 10.855,
      SD2neg: 11.701,
      SD2: 16.142,
      SD3: 17.6,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.2,
      Median: 13.7041,
      SD3neg: 10.873,
      SD2neg: 11.721,
      SD2: 16.17,
      SD3: 17.63,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.3,
      Median: 13.7275,
      SD3neg: 10.891,
      SD2neg: 11.741,
      SD2: 16.198,
      SD3: 17.661,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.4,
      Median: 13.751,
      SD3neg: 10.91,
      SD2neg: 11.76,
      SD2: 16.226,
      SD3: 17.692,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.5,
      Median: 13.7746,
      SD3neg: 10.928,
      SD2neg: 11.78,
      SD2: 16.254,
      SD3: 17.723,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.6,
      Median: 13.7981,
      SD3neg: 10.946,
      SD2neg: 11.8,
      SD2: 16.282,
      SD3: 17.754,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.7,
      Median: 13.8217,
      SD3neg: 10.964,
      SD2neg: 11.82,
      SD2: 16.311,
      SD3: 17.786,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.8,
      Median: 13.8454,
      SD3neg: 10.983,
      SD2neg: 11.84,
      SD2: 16.339,
      SD3: 17.817,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 94.9,
      Median: 13.8691,
      SD3neg: 11.001,
      SD2neg: 11.86,
      SD2: 16.368,
      SD3: 17.849,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.0,
      Median: 13.8928,
      SD3neg: 11.02,
      SD2neg: 11.88,
      SD2: 16.396,
      SD3: 17.88,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.1,
      Median: 13.9165,
      SD3neg: 11.038,
      SD2neg: 11.899,
      SD2: 16.425,
      SD3: 17.911,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.2,
      Median: 13.9403,
      SD3neg: 11.056,
      SD2neg: 11.919,
      SD2: 16.454,
      SD3: 17.943,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.3,
      Median: 13.9642,
      SD3neg: 11.075,
      SD2neg: 11.94,
      SD2: 16.482,
      SD3: 17.974,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.4,
      Median: 13.9881,
      SD3neg: 11.093,
      SD2neg: 11.96,
      SD2: 16.511,
      SD3: 18.006,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.5,
      Median: 14.012,
      SD3neg: 11.111,
      SD2neg: 11.98,
      SD2: 16.54,
      SD3: 18.038,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.6,
      Median: 14.036,
      SD3neg: 11.13,
      SD2neg: 12.0,
      SD2: 16.569,
      SD3: 18.07,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.7,
      Median: 14.06,
      SD3neg: 11.148,
      SD2neg: 12.02,
      SD2: 16.598,
      SD3: 18.103,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.8,
      Median: 14.0841,
      SD3neg: 11.167,
      SD2neg: 12.04,
      SD2: 16.627,
      SD3: 18.135,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 95.9,
      Median: 14.1083,
      SD3neg: 11.185,
      SD2neg: 12.06,
      SD2: 16.657,
      SD3: 18.167,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.0,
      Median: 14.1325,
      SD3neg: 11.204,
      SD2neg: 12.08,
      SD2: 16.686,
      SD3: 18.2,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.1,
      Median: 14.1567,
      SD3neg: 11.222,
      SD2neg: 12.1,
      SD2: 16.715,
      SD3: 18.232,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.2,
      Median: 14.1811,
      SD3neg: 11.241,
      SD2neg: 12.121,
      SD2: 16.745,
      SD3: 18.265,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.3,
      Median: 14.2055,
      SD3neg: 11.26,
      SD2neg: 12.141,
      SD2: 16.775,
      SD3: 18.298,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.4,
      Median: 14.2299,
      SD3neg: 11.278,
      SD2neg: 12.161,
      SD2: 16.804,
      SD3: 18.331,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.5,
      Median: 14.2544,
      SD3neg: 11.297,
      SD2neg: 12.182,
      SD2: 16.834,
      SD3: 18.364,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.6,
      Median: 14.279,
      SD3neg: 11.315,
      SD2neg: 12.202,
      SD2: 16.864,
      SD3: 18.397,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.7,
      Median: 14.3037,
      SD3neg: 11.334,
      SD2neg: 12.223,
      SD2: 16.894,
      SD3: 18.43,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.8,
      Median: 14.3284,
      SD3neg: 11.353,
      SD2neg: 12.243,
      SD2: 16.924,
      SD3: 18.464,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 96.9,
      Median: 14.3533,
      SD3neg: 11.372,
      SD2neg: 12.264,
      SD2: 16.955,
      SD3: 18.498,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.0,
      Median: 14.3782,
      SD3neg: 11.39,
      SD2neg: 12.284,
      SD2: 16.985,
      SD3: 18.532,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.1,
      Median: 14.4031,
      SD3neg: 11.41,
      SD2neg: 12.305,
      SD2: 17.015,
      SD3: 18.565,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.2,
      Median: 14.4282,
      SD3neg: 11.428,
      SD2neg: 12.326,
      SD2: 17.046,
      SD3: 18.599,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.3,
      Median: 14.4533,
      SD3neg: 11.447,
      SD2neg: 12.347,
      SD2: 17.077,
      SD3: 18.633,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.4,
      Median: 14.4785,
      SD3neg: 11.466,
      SD2neg: 12.367,
      SD2: 17.108,
      SD3: 18.668,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.5,
      Median: 14.5038,
      SD3neg: 11.485,
      SD2neg: 12.388,
      SD2: 17.139,
      SD3: 18.702,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.6,
      Median: 14.5292,
      SD3neg: 11.505,
      SD2neg: 12.409,
      SD2: 17.17,
      SD3: 18.737,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.7,
      Median: 14.5547,
      SD3neg: 11.524,
      SD2neg: 12.43,
      SD2: 17.201,
      SD3: 18.771,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.8,
      Median: 14.5802,
      SD3neg: 11.543,
      SD2neg: 12.451,
      SD2: 17.233,
      SD3: 18.807,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 97.9,
      Median: 14.6058,
      SD3neg: 11.562,
      SD2neg: 12.472,
      SD2: 17.264,
      SD3: 18.842,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.0,
      Median: 14.6316,
      SD3neg: 11.582,
      SD2neg: 12.494,
      SD2: 17.296,
      SD3: 18.877,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.1,
      Median: 14.6574,
      SD3neg: 11.601,
      SD2neg: 12.515,
      SD2: 17.327,
      SD3: 18.912,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.2,
      Median: 14.6832,
      SD3neg: 11.62,
      SD2neg: 12.536,
      SD2: 17.359,
      SD3: 18.948,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.3,
      Median: 14.7092,
      SD3neg: 11.64,
      SD2neg: 12.558,
      SD2: 17.391,
      SD3: 18.983,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.4,
      Median: 14.7353,
      SD3neg: 11.659,
      SD2neg: 12.579,
      SD2: 17.423,
      SD3: 19.019,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.5,
      Median: 14.7614,
      SD3neg: 11.679,
      SD2neg: 12.6,
      SD2: 17.455,
      SD3: 19.055,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.6,
      Median: 14.7877,
      SD3neg: 11.698,
      SD2neg: 12.622,
      SD2: 17.488,
      SD3: 19.091,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.7,
      Median: 14.814,
      SD3neg: 11.718,
      SD2neg: 12.644,
      SD2: 17.52,
      SD3: 19.127,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.8,
      Median: 14.8404,
      SD3neg: 11.738,
      SD2neg: 12.665,
      SD2: 17.553,
      SD3: 19.164,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 98.9,
      Median: 14.8669,
      SD3neg: 11.757,
      SD2neg: 12.687,
      SD2: 17.586,
      SD3: 19.201,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.0,
      Median: 14.8934,
      SD3neg: 11.777,
      SD2neg: 12.709,
      SD2: 17.618,
      SD3: 19.237,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.1,
      Median: 14.9201,
      SD3neg: 11.797,
      SD2neg: 12.731,
      SD2: 17.651,
      SD3: 19.274,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.2,
      Median: 14.9468,
      SD3neg: 11.817,
      SD2neg: 12.752,
      SD2: 17.684,
      SD3: 19.311,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.3,
      Median: 14.9736,
      SD3neg: 11.837,
      SD2neg: 12.774,
      SD2: 17.718,
      SD3: 19.348,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.4,
      Median: 15.0005,
      SD3neg: 11.857,
      SD2neg: 12.796,
      SD2: 17.751,
      SD3: 19.385,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.5,
      Median: 15.0275,
      SD3neg: 11.877,
      SD2neg: 12.818,
      SD2: 17.784,
      SD3: 19.423,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.6,
      Median: 15.0546,
      SD3neg: 11.897,
      SD2neg: 12.84,
      SD2: 17.818,
      SD3: 19.46,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.7,
      Median: 15.0818,
      SD3neg: 11.917,
      SD2neg: 12.863,
      SD2: 17.852,
      SD3: 19.498,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.8,
      Median: 15.109,
      SD3neg: 11.937,
      SD2neg: 12.885,
      SD2: 17.885,
      SD3: 19.536,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 99.9,
      Median: 15.1363,
      SD3neg: 11.957,
      SD2neg: 12.907,
      SD2: 17.92,
      SD3: 19.574,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.0,
      Median: 15.1637,
      SD3neg: 11.978,
      SD2neg: 12.929,
      SD2: 17.954,
      SD3: 19.612,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.1,
      Median: 15.1912,
      SD3neg: 11.998,
      SD2neg: 12.952,
      SD2: 17.988,
      SD3: 19.65,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.2,
      Median: 15.2187,
      SD3neg: 12.018,
      SD2neg: 12.974,
      SD2: 18.022,
      SD3: 19.688,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.3,
      Median: 15.2463,
      SD3neg: 12.038,
      SD2neg: 12.997,
      SD2: 18.056,
      SD3: 19.727,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.4,
      Median: 15.274,
      SD3neg: 12.059,
      SD2neg: 13.019,
      SD2: 18.091,
      SD3: 19.766,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.5,
      Median: 15.3018,
      SD3neg: 12.079,
      SD2neg: 13.042,
      SD2: 18.126,
      SD3: 19.805,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.6,
      Median: 15.3297,
      SD3neg: 12.1,
      SD2neg: 13.065,
      SD2: 18.16,
      SD3: 19.844,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.7,
      Median: 15.3576,
      SD3neg: 12.12,
      SD2neg: 13.087,
      SD2: 18.195,
      SD3: 19.883,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.8,
      Median: 15.3856,
      SD3neg: 12.141,
      SD2neg: 13.11,
      SD2: 18.23,
      SD3: 19.922,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 100.9,
      Median: 15.4137,
      SD3neg: 12.162,
      SD2neg: 13.133,
      SD2: 18.265,
      SD3: 19.962,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.0,
      Median: 15.4419,
      SD3neg: 12.182,
      SD2neg: 13.155,
      SD2: 18.3,
      SD3: 20.002,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.1,
      Median: 15.4701,
      SD3neg: 12.203,
      SD2neg: 13.178,
      SD2: 18.335,
      SD3: 20.041,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.2,
      Median: 15.4985,
      SD3neg: 12.224,
      SD2neg: 13.201,
      SD2: 18.371,
      SD3: 20.081,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.3,
      Median: 15.5268,
      SD3neg: 12.244,
      SD2neg: 13.224,
      SD2: 18.406,
      SD3: 20.121,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.4,
      Median: 15.5553,
      SD3neg: 12.265,
      SD2neg: 13.247,
      SD2: 18.442,
      SD3: 20.161,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.5,
      Median: 15.5838,
      SD3neg: 12.286,
      SD2neg: 13.27,
      SD2: 18.478,
      SD3: 20.201,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.6,
      Median: 15.6125,
      SD3neg: 12.307,
      SD2neg: 13.293,
      SD2: 18.514,
      SD3: 20.242,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.7,
      Median: 15.6412,
      SD3neg: 12.328,
      SD2neg: 13.317,
      SD2: 18.55,
      SD3: 20.282,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.8,
      Median: 15.6699,
      SD3neg: 12.349,
      SD2neg: 13.34,
      SD2: 18.586,
      SD3: 20.322,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 101.9,
      Median: 15.6987,
      SD3neg: 12.37,
      SD2neg: 13.363,
      SD2: 18.622,
      SD3: 20.364,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.0,
      Median: 15.7276,
      SD3neg: 12.391,
      SD2neg: 13.386,
      SD2: 18.658,
      SD3: 20.404,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.1,
      Median: 15.7566,
      SD3neg: 12.412,
      SD2neg: 13.41,
      SD2: 18.695,
      SD3: 20.445,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.2,
      Median: 15.7857,
      SD3neg: 12.433,
      SD2neg: 13.433,
      SD2: 18.731,
      SD3: 20.487,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.3,
      Median: 15.8148,
      SD3neg: 12.454,
      SD2neg: 13.457,
      SD2: 18.768,
      SD3: 20.528,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.4,
      Median: 15.844,
      SD3neg: 12.476,
      SD2neg: 13.48,
      SD2: 18.804,
      SD3: 20.569,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.5,
      Median: 15.8732,
      SD3neg: 12.497,
      SD2neg: 13.504,
      SD2: 18.841,
      SD3: 20.61,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.6,
      Median: 15.9026,
      SD3neg: 12.518,
      SD2neg: 13.528,
      SD2: 18.878,
      SD3: 20.652,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.7,
      Median: 15.932,
      SD3neg: 12.539,
      SD2neg: 13.551,
      SD2: 18.915,
      SD3: 20.694,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.8,
      Median: 15.9615,
      SD3neg: 12.561,
      SD2neg: 13.575,
      SD2: 18.952,
      SD3: 20.736,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 102.9,
      Median: 15.991,
      SD3neg: 12.582,
      SD2neg: 13.599,
      SD2: 18.989,
      SD3: 20.778,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.0,
      Median: 16.0206,
      SD3neg: 12.604,
      SD2neg: 13.623,
      SD2: 19.027,
      SD3: 20.819,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.1,
      Median: 16.0503,
      SD3neg: 12.625,
      SD2neg: 13.646,
      SD2: 19.064,
      SD3: 20.862,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.2,
      Median: 16.0801,
      SD3neg: 12.647,
      SD2neg: 13.67,
      SD2: 19.102,
      SD3: 20.904,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.3,
      Median: 16.1099,
      SD3neg: 12.669,
      SD2neg: 13.694,
      SD2: 19.139,
      SD3: 20.947,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.4,
      Median: 16.1398,
      SD3neg: 12.69,
      SD2neg: 13.718,
      SD2: 19.177,
      SD3: 20.99,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.5,
      Median: 16.1697,
      SD3neg: 12.712,
      SD2neg: 13.742,
      SD2: 19.215,
      SD3: 21.032,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.6,
      Median: 16.1997,
      SD3neg: 12.733,
      SD2neg: 13.766,
      SD2: 19.253,
      SD3: 21.075,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.7,
      Median: 16.2298,
      SD3neg: 12.755,
      SD2neg: 13.791,
      SD2: 19.291,
      SD3: 21.118,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.8,
      Median: 16.26,
      SD3neg: 12.777,
      SD2neg: 13.815,
      SD2: 19.329,
      SD3: 21.16,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 103.9,
      Median: 16.2902,
      SD3neg: 12.799,
      SD2neg: 13.839,
      SD2: 19.367,
      SD3: 21.204,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.0,
      Median: 16.3204,
      SD3neg: 12.821,
      SD2neg: 13.863,
      SD2: 19.405,
      SD3: 21.247,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.1,
      Median: 16.3508,
      SD3neg: 12.842,
      SD2neg: 13.888,
      SD2: 19.444,
      SD3: 21.291,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.2,
      Median: 16.3812,
      SD3neg: 12.865,
      SD2neg: 13.912,
      SD2: 19.482,
      SD3: 21.334,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.3,
      Median: 16.4117,
      SD3neg: 12.886,
      SD2neg: 13.937,
      SD2: 19.521,
      SD3: 21.378,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.4,
      Median: 16.4422,
      SD3neg: 12.909,
      SD2neg: 13.961,
      SD2: 19.559,
      SD3: 21.421,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.5,
      Median: 16.4728,
      SD3neg: 12.93,
      SD2neg: 13.985,
      SD2: 19.598,
      SD3: 21.465,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.6,
      Median: 16.5035,
      SD3neg: 12.952,
      SD2neg: 14.01,
      SD2: 19.637,
      SD3: 21.509,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.7,
      Median: 16.5342,
      SD3neg: 12.975,
      SD2neg: 14.035,
      SD2: 19.675,
      SD3: 21.553,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.8,
      Median: 16.565,
      SD3neg: 12.997,
      SD2neg: 14.059,
      SD2: 19.715,
      SD3: 21.597,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 104.9,
      Median: 16.5959,
      SD3neg: 13.019,
      SD2neg: 14.084,
      SD2: 19.753,
      SD3: 21.641,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.0,
      Median: 16.6268,
      SD3neg: 13.041,
      SD2neg: 14.109,
      SD2: 19.793,
      SD3: 21.686,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.1,
      Median: 16.6579,
      SD3neg: 13.064,
      SD2neg: 14.134,
      SD2: 19.832,
      SD3: 21.73,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.2,
      Median: 16.6889,
      SD3neg: 13.086,
      SD2neg: 14.159,
      SD2: 19.871,
      SD3: 21.775,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.3,
      Median: 16.7201,
      SD3neg: 13.108,
      SD2neg: 14.183,
      SD2: 19.911,
      SD3: 21.82,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.4,
      Median: 16.7513,
      SD3neg: 13.131,
      SD2neg: 14.209,
      SD2: 19.95,
      SD3: 21.864,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.5,
      Median: 16.7826,
      SD3neg: 13.153,
      SD2neg: 14.233,
      SD2: 19.99,
      SD3: 21.909,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.6,
      Median: 16.8139,
      SD3neg: 13.176,
      SD2neg: 14.258,
      SD2: 20.03,
      SD3: 21.954,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.7,
      Median: 16.8454,
      SD3neg: 13.198,
      SD2neg: 14.283,
      SD2: 20.07,
      SD3: 22.0,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.8,
      Median: 16.8769,
      SD3neg: 13.221,
      SD2neg: 14.309,
      SD2: 20.11,
      SD3: 22.044,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 105.9,
      Median: 16.9084,
      SD3neg: 13.243,
      SD2neg: 14.334,
      SD2: 20.15,
      SD3: 22.09,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.0,
      Median: 16.9401,
      SD3neg: 13.266,
      SD2neg: 14.359,
      SD2: 20.19,
      SD3: 22.136,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.1,
      Median: 16.9718,
      SD3neg: 13.289,
      SD2neg: 14.384,
      SD2: 20.231,
      SD3: 22.182,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.2,
      Median: 17.0036,
      SD3neg: 13.312,
      SD2neg: 14.41,
      SD2: 20.271,
      SD3: 22.227,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.3,
      Median: 17.0355,
      SD3neg: 13.335,
      SD2neg: 14.435,
      SD2: 20.311,
      SD3: 22.273,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.4,
      Median: 17.0674,
      SD3neg: 13.357,
      SD2neg: 14.461,
      SD2: 20.352,
      SD3: 22.319,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.5,
      Median: 17.0995,
      SD3neg: 13.38,
      SD2neg: 14.486,
      SD2: 20.393,
      SD3: 22.365,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.6,
      Median: 17.1316,
      SD3neg: 13.403,
      SD2neg: 14.512,
      SD2: 20.434,
      SD3: 22.412,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.7,
      Median: 17.1637,
      SD3neg: 13.426,
      SD2neg: 14.537,
      SD2: 20.475,
      SD3: 22.458,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.8,
      Median: 17.196,
      SD3neg: 13.449,
      SD2neg: 14.563,
      SD2: 20.516,
      SD3: 22.505,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 106.9,
      Median: 17.2283,
      SD3neg: 13.473,
      SD2neg: 14.589,
      SD2: 20.556,
      SD3: 22.551,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.0,
      Median: 17.2607,
      SD3neg: 13.496,
      SD2neg: 14.615,
      SD2: 20.598,
      SD3: 22.598,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.1,
      Median: 17.2931,
      SD3neg: 13.519,
      SD2neg: 14.641,
      SD2: 20.639,
      SD3: 22.645,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.2,
      Median: 17.3256,
      SD3neg: 13.542,
      SD2neg: 14.667,
      SD2: 20.68,
      SD3: 22.692,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.3,
      Median: 17.3582,
      SD3neg: 13.565,
      SD2neg: 14.692,
      SD2: 20.722,
      SD3: 22.739,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.4,
      Median: 17.3909,
      SD3neg: 13.588,
      SD2neg: 14.718,
      SD2: 20.764,
      SD3: 22.786,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.5,
      Median: 17.4237,
      SD3neg: 13.611,
      SD2neg: 14.744,
      SD2: 20.806,
      SD3: 22.835,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.6,
      Median: 17.4565,
      SD3neg: 13.635,
      SD2neg: 14.77,
      SD2: 20.848,
      SD3: 22.882,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.7,
      Median: 17.4894,
      SD3neg: 13.658,
      SD2neg: 14.797,
      SD2: 20.89,
      SD3: 22.93,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.8,
      Median: 17.5224,
      SD3neg: 13.682,
      SD2neg: 14.823,
      SD2: 20.932,
      SD3: 22.978,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 107.9,
      Median: 17.5554,
      SD3neg: 13.705,
      SD2neg: 14.849,
      SD2: 20.974,
      SD3: 23.025,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.0,
      Median: 17.5885,
      SD3neg: 13.729,
      SD2neg: 14.875,
      SD2: 21.016,
      SD3: 23.073,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.1,
      Median: 17.6217,
      SD3neg: 13.753,
      SD2neg: 14.902,
      SD2: 21.059,
      SD3: 23.122,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.2,
      Median: 17.655,
      SD3neg: 13.776,
      SD2neg: 14.928,
      SD2: 21.101,
      SD3: 23.17,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.3,
      Median: 17.6884,
      SD3neg: 13.8,
      SD2neg: 14.954,
      SD2: 21.144,
      SD3: 23.219,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.4,
      Median: 17.7218,
      SD3neg: 13.823,
      SD2neg: 14.981,
      SD2: 21.187,
      SD3: 23.268,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.5,
      Median: 17.7553,
      SD3neg: 13.847,
      SD2neg: 15.008,
      SD2: 21.229,
      SD3: 23.316,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.6,
      Median: 17.7889,
      SD3neg: 13.871,
      SD2neg: 15.034,
      SD2: 21.272,
      SD3: 23.365,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.7,
      Median: 17.8226,
      SD3neg: 13.895,
      SD2neg: 15.061,
      SD2: 21.316,
      SD3: 23.415,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.8,
      Median: 17.8564,
      SD3neg: 13.919,
      SD2neg: 15.088,
      SD2: 21.359,
      SD3: 23.464,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 108.9,
      Median: 17.8903,
      SD3neg: 13.943,
      SD2neg: 15.115,
      SD2: 21.402,
      SD3: 23.513,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.0,
      Median: 17.9242,
      SD3neg: 13.967,
      SD2neg: 15.142,
      SD2: 21.446,
      SD3: 23.562,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.1,
      Median: 17.9583,
      SD3neg: 13.991,
      SD2neg: 15.168,
      SD2: 21.49,
      SD3: 23.612,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.2,
      Median: 17.9924,
      SD3neg: 14.015,
      SD2neg: 15.195,
      SD2: 21.533,
      SD3: 23.662,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.3,
      Median: 18.0267,
      SD3neg: 14.04,
      SD2neg: 15.223,
      SD2: 21.577,
      SD3: 23.712,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.4,
      Median: 18.061,
      SD3neg: 14.064,
      SD2neg: 15.25,
      SD2: 21.621,
      SD3: 23.762,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.5,
      Median: 18.0954,
      SD3neg: 14.088,
      SD2neg: 15.277,
      SD2: 21.665,
      SD3: 23.812,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.6,
      Median: 18.1299,
      SD3neg: 14.112,
      SD2neg: 15.304,
      SD2: 21.71,
      SD3: 23.863,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.7,
      Median: 18.1645,
      SD3neg: 14.137,
      SD2neg: 15.332,
      SD2: 21.754,
      SD3: 23.913,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.8,
      Median: 18.1992,
      SD3neg: 14.162,
      SD2neg: 15.359,
      SD2: 21.798,
      SD3: 23.964,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 109.9,
      Median: 18.234,
      SD3neg: 14.186,
      SD2neg: 15.386,
      SD2: 21.843,
      SD3: 24.015,
    },
    {
      Section: 'weight-for-length-boys',
      Height: 110.0,
      Median: 18.2689,
      SD3neg: 14.211,
      SD2neg: 15.414,
      SD2: 21.888,
      SD3: 24.066,
    },
  ],
};
