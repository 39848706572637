export const weightForLengthGirls = {
  data: [
    {
      Section: 'weight-for-length-girls',
      Height: 45.0,
      Median: 2.4607,
      SD3neg: 1.902,
      SD2neg: 2.066,
      SD2: 2.967,
      SD3: 3.275,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 45.1,
      Median: 2.4777,
      SD3neg: 1.915,
      SD2neg: 2.081,
      SD2: 2.988,
      SD3: 3.298,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 45.2,
      Median: 2.4947,
      SD3neg: 1.928,
      SD2neg: 2.095,
      SD2: 3.008,
      SD3: 3.321,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 45.3,
      Median: 2.5117,
      SD3neg: 1.941,
      SD2neg: 2.109,
      SD2: 3.029,
      SD3: 3.343,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 45.4,
      Median: 2.5287,
      SD3neg: 1.954,
      SD2neg: 2.123,
      SD2: 3.049,
      SD3: 3.366,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 45.5,
      Median: 2.5457,
      SD3neg: 1.967,
      SD2neg: 2.138,
      SD2: 3.07,
      SD3: 3.389,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 45.6,
      Median: 2.5627,
      SD3neg: 1.98,
      SD2neg: 2.152,
      SD2: 3.09,
      SD3: 3.412,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 45.7,
      Median: 2.5797,
      SD3neg: 1.993,
      SD2neg: 2.166,
      SD2: 3.111,
      SD3: 3.434,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 45.8,
      Median: 2.5967,
      SD3neg: 2.006,
      SD2neg: 2.18,
      SD2: 3.131,
      SD3: 3.457,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 45.9,
      Median: 2.6137,
      SD3neg: 2.02,
      SD2neg: 2.195,
      SD2: 3.152,
      SD3: 3.48,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.0,
      Median: 2.6306,
      SD3neg: 2.033,
      SD2neg: 2.209,
      SD2: 3.172,
      SD3: 3.502,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.1,
      Median: 2.6476,
      SD3neg: 2.046,
      SD2neg: 2.223,
      SD2: 3.193,
      SD3: 3.525,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.2,
      Median: 2.6646,
      SD3neg: 2.059,
      SD2neg: 2.237,
      SD2: 3.214,
      SD3: 3.548,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.3,
      Median: 2.6816,
      SD3neg: 2.072,
      SD2neg: 2.252,
      SD2: 3.234,
      SD3: 3.571,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.4,
      Median: 2.6986,
      SD3neg: 2.085,
      SD2neg: 2.266,
      SD2: 3.255,
      SD3: 3.593,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.5,
      Median: 2.7155,
      SD3neg: 2.098,
      SD2neg: 2.28,
      SD2: 3.275,
      SD3: 3.616,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.6,
      Median: 2.7326,
      SD3neg: 2.111,
      SD2neg: 2.294,
      SD2: 3.296,
      SD3: 3.639,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.7,
      Median: 2.7496,
      SD3neg: 2.124,
      SD2neg: 2.309,
      SD2: 3.316,
      SD3: 3.662,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.8,
      Median: 2.7666,
      SD3neg: 2.137,
      SD2neg: 2.323,
      SD2: 3.337,
      SD3: 3.684,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 46.9,
      Median: 2.7837,
      SD3neg: 2.15,
      SD2neg: 2.337,
      SD2: 3.358,
      SD3: 3.707,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.0,
      Median: 2.8007,
      SD3neg: 2.164,
      SD2neg: 2.351,
      SD2: 3.378,
      SD3: 3.73,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.1,
      Median: 2.8179,
      SD3neg: 2.177,
      SD2neg: 2.366,
      SD2: 3.399,
      SD3: 3.753,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.2,
      Median: 2.835,
      SD3neg: 2.19,
      SD2neg: 2.38,
      SD2: 3.42,
      SD3: 3.776,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.3,
      Median: 2.8522,
      SD3neg: 2.203,
      SD2neg: 2.394,
      SD2: 3.44,
      SD3: 3.799,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.4,
      Median: 2.8694,
      SD3neg: 2.216,
      SD2neg: 2.409,
      SD2: 3.461,
      SD3: 3.822,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.5,
      Median: 2.8867,
      SD3neg: 2.23,
      SD2neg: 2.423,
      SD2: 3.482,
      SD3: 3.845,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.6,
      Median: 2.9041,
      SD3neg: 2.243,
      SD2neg: 2.438,
      SD2: 3.503,
      SD3: 3.868,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.7,
      Median: 2.9215,
      SD3neg: 2.256,
      SD2neg: 2.452,
      SD2: 3.524,
      SD3: 3.891,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.8,
      Median: 2.939,
      SD3neg: 2.27,
      SD2neg: 2.467,
      SD2: 3.545,
      SD3: 3.915,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 47.9,
      Median: 2.9565,
      SD3neg: 2.283,
      SD2neg: 2.482,
      SD2: 3.567,
      SD3: 3.938,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.0,
      Median: 2.9741,
      SD3neg: 2.297,
      SD2neg: 2.497,
      SD2: 3.588,
      SD3: 3.962,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.1,
      Median: 2.9918,
      SD3neg: 2.311,
      SD2neg: 2.511,
      SD2: 3.609,
      SD3: 3.986,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.2,
      Median: 3.0096,
      SD3neg: 2.324,
      SD2neg: 2.526,
      SD2: 3.631,
      SD3: 4.009,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.3,
      Median: 3.0275,
      SD3neg: 2.338,
      SD2neg: 2.541,
      SD2: 3.652,
      SD3: 4.033,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.4,
      Median: 3.0455,
      SD3neg: 2.352,
      SD2neg: 2.556,
      SD2: 3.674,
      SD3: 4.057,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.5,
      Median: 3.0636,
      SD3neg: 2.366,
      SD2neg: 2.571,
      SD2: 3.696,
      SD3: 4.082,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.6,
      Median: 3.0818,
      SD3neg: 2.38,
      SD2neg: 2.587,
      SD2: 3.718,
      SD3: 4.106,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.7,
      Median: 3.1001,
      SD3neg: 2.394,
      SD2neg: 2.602,
      SD2: 3.74,
      SD3: 4.13,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.8,
      Median: 3.1186,
      SD3neg: 2.408,
      SD2neg: 2.618,
      SD2: 3.763,
      SD3: 4.155,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 48.9,
      Median: 3.1372,
      SD3neg: 2.423,
      SD2neg: 2.633,
      SD2: 3.785,
      SD3: 4.18,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.0,
      Median: 3.156,
      SD3neg: 2.437,
      SD2neg: 2.649,
      SD2: 3.808,
      SD3: 4.205,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.1,
      Median: 3.1749,
      SD3neg: 2.451,
      SD2neg: 2.665,
      SD2: 3.831,
      SD3: 4.231,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.2,
      Median: 3.1939,
      SD3neg: 2.466,
      SD2neg: 2.681,
      SD2: 3.854,
      SD3: 4.256,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.3,
      Median: 3.2131,
      SD3neg: 2.481,
      SD2neg: 2.697,
      SD2: 3.877,
      SD3: 4.282,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.4,
      Median: 3.2325,
      SD3neg: 2.496,
      SD2neg: 2.713,
      SD2: 3.901,
      SD3: 4.308,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.5,
      Median: 3.252,
      SD3neg: 2.511,
      SD2neg: 2.729,
      SD2: 3.924,
      SD3: 4.334,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.6,
      Median: 3.2717,
      SD3neg: 2.526,
      SD2neg: 2.746,
      SD2: 3.948,
      SD3: 4.36,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.7,
      Median: 3.2915,
      SD3neg: 2.541,
      SD2neg: 2.762,
      SD2: 3.972,
      SD3: 4.387,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.8,
      Median: 3.3114,
      SD3neg: 2.557,
      SD2neg: 2.779,
      SD2: 3.996,
      SD3: 4.413,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 49.9,
      Median: 3.3316,
      SD3neg: 2.572,
      SD2neg: 2.796,
      SD2: 4.02,
      SD3: 4.44,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.0,
      Median: 3.3518,
      SD3neg: 2.588,
      SD2neg: 2.813,
      SD2: 4.045,
      SD3: 4.467,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.1,
      Median: 3.3723,
      SD3neg: 2.603,
      SD2neg: 2.83,
      SD2: 4.07,
      SD3: 4.495,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.2,
      Median: 3.3929,
      SD3neg: 2.619,
      SD2neg: 2.847,
      SD2: 4.095,
      SD3: 4.522,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.3,
      Median: 3.4136,
      SD3neg: 2.635,
      SD2neg: 2.865,
      SD2: 4.12,
      SD3: 4.55,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.4,
      Median: 3.4346,
      SD3neg: 2.651,
      SD2neg: 2.882,
      SD2: 4.145,
      SD3: 4.578,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.5,
      Median: 3.4557,
      SD3neg: 2.668,
      SD2neg: 2.9,
      SD2: 4.171,
      SD3: 4.606,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.6,
      Median: 3.4769,
      SD3neg: 2.684,
      SD2neg: 2.917,
      SD2: 4.196,
      SD3: 4.635,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.7,
      Median: 3.4983,
      SD3neg: 2.7,
      SD2neg: 2.935,
      SD2: 4.222,
      SD3: 4.664,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.8,
      Median: 3.5199,
      SD3neg: 2.717,
      SD2neg: 2.953,
      SD2: 4.248,
      SD3: 4.692,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 50.9,
      Median: 3.5417,
      SD3neg: 2.734,
      SD2neg: 2.972,
      SD2: 4.275,
      SD3: 4.722,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.0,
      Median: 3.5636,
      SD3neg: 2.75,
      SD2neg: 2.99,
      SD2: 4.301,
      SD3: 4.751,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.1,
      Median: 3.5856,
      SD3neg: 2.767,
      SD2neg: 3.008,
      SD2: 4.328,
      SD3: 4.78,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.2,
      Median: 3.6078,
      SD3neg: 2.784,
      SD2neg: 3.027,
      SD2: 4.355,
      SD3: 4.81,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.3,
      Median: 3.6302,
      SD3neg: 2.802,
      SD2neg: 3.046,
      SD2: 4.382,
      SD3: 4.84,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.4,
      Median: 3.6527,
      SD3neg: 2.819,
      SD2neg: 3.065,
      SD2: 4.409,
      SD3: 4.87,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.5,
      Median: 3.6754,
      SD3neg: 2.836,
      SD2neg: 3.084,
      SD2: 4.437,
      SD3: 4.901,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.6,
      Median: 3.6982,
      SD3neg: 2.854,
      SD2neg: 3.103,
      SD2: 4.464,
      SD3: 4.931,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.7,
      Median: 3.7212,
      SD3neg: 2.872,
      SD2neg: 3.122,
      SD2: 4.492,
      SD3: 4.962,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.8,
      Median: 3.7444,
      SD3neg: 2.889,
      SD2neg: 3.141,
      SD2: 4.52,
      SD3: 4.993,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 51.9,
      Median: 3.7677,
      SD3neg: 2.907,
      SD2neg: 3.161,
      SD2: 4.548,
      SD3: 5.024,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.0,
      Median: 3.7911,
      SD3neg: 2.925,
      SD2neg: 3.18,
      SD2: 4.577,
      SD3: 5.056,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.1,
      Median: 3.8147,
      SD3neg: 2.944,
      SD2neg: 3.2,
      SD2: 4.605,
      SD3: 5.087,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.2,
      Median: 3.8385,
      SD3neg: 2.962,
      SD2neg: 3.22,
      SD2: 4.634,
      SD3: 5.119,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.3,
      Median: 3.8623,
      SD3neg: 2.98,
      SD2neg: 3.24,
      SD2: 4.663,
      SD3: 5.151,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.4,
      Median: 3.8863,
      SD3neg: 2.999,
      SD2neg: 3.26,
      SD2: 4.692,
      SD3: 5.183,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.5,
      Median: 3.9105,
      SD3neg: 3.017,
      SD2neg: 3.28,
      SD2: 4.721,
      SD3: 5.216,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.6,
      Median: 3.9348,
      SD3neg: 3.036,
      SD2neg: 3.301,
      SD2: 4.751,
      SD3: 5.248,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.7,
      Median: 3.9592,
      SD3neg: 3.055,
      SD2neg: 3.321,
      SD2: 4.78,
      SD3: 5.281,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.8,
      Median: 3.9837,
      SD3neg: 3.073,
      SD2neg: 3.342,
      SD2: 4.81,
      SD3: 5.314,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 52.9,
      Median: 4.0084,
      SD3neg: 3.092,
      SD2neg: 3.362,
      SD2: 4.84,
      SD3: 5.347,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.0,
      Median: 4.0332,
      SD3neg: 3.112,
      SD2neg: 3.383,
      SD2: 4.87,
      SD3: 5.38,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.1,
      Median: 4.0581,
      SD3neg: 3.131,
      SD2neg: 3.404,
      SD2: 4.9,
      SD3: 5.413,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.2,
      Median: 4.0832,
      SD3neg: 3.15,
      SD2neg: 3.425,
      SD2: 4.93,
      SD3: 5.447,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.3,
      Median: 4.1084,
      SD3neg: 3.169,
      SD2neg: 3.446,
      SD2: 4.961,
      SD3: 5.481,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.4,
      Median: 4.1337,
      SD3neg: 3.189,
      SD2neg: 3.467,
      SD2: 4.992,
      SD3: 5.515,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.5,
      Median: 4.1591,
      SD3neg: 3.208,
      SD2neg: 3.488,
      SD2: 5.022,
      SD3: 5.549,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.6,
      Median: 4.1846,
      SD3neg: 3.228,
      SD2neg: 3.51,
      SD2: 5.053,
      SD3: 5.583,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.7,
      Median: 4.2102,
      SD3neg: 3.248,
      SD2neg: 3.531,
      SD2: 5.084,
      SD3: 5.617,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.8,
      Median: 4.2359,
      SD3neg: 3.267,
      SD2neg: 3.553,
      SD2: 5.115,
      SD3: 5.652,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 53.9,
      Median: 4.2617,
      SD3neg: 3.287,
      SD2neg: 3.574,
      SD2: 5.147,
      SD3: 5.686,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.0,
      Median: 4.2875,
      SD3neg: 3.307,
      SD2neg: 3.596,
      SD2: 5.178,
      SD3: 5.721,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.1,
      Median: 4.3135,
      SD3neg: 3.327,
      SD2neg: 3.617,
      SD2: 5.209,
      SD3: 5.756,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.2,
      Median: 4.3395,
      SD3neg: 3.347,
      SD2neg: 3.639,
      SD2: 5.241,
      SD3: 5.791,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.3,
      Median: 4.3655,
      SD3neg: 3.367,
      SD2neg: 3.661,
      SD2: 5.272,
      SD3: 5.826,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.4,
      Median: 4.3917,
      SD3neg: 3.387,
      SD2neg: 3.683,
      SD2: 5.304,
      SD3: 5.861,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.5,
      Median: 4.4179,
      SD3neg: 3.407,
      SD2neg: 3.705,
      SD2: 5.336,
      SD3: 5.896,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.6,
      Median: 4.4442,
      SD3neg: 3.427,
      SD2neg: 3.727,
      SD2: 5.368,
      SD3: 5.931,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.7,
      Median: 4.4705,
      SD3neg: 3.447,
      SD2neg: 3.749,
      SD2: 5.4,
      SD3: 5.966,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.8,
      Median: 4.4969,
      SD3neg: 3.468,
      SD2neg: 3.771,
      SD2: 5.432,
      SD3: 6.002,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 54.9,
      Median: 4.5233,
      SD3neg: 3.488,
      SD2neg: 3.793,
      SD2: 5.464,
      SD3: 6.037,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.0,
      Median: 4.5498,
      SD3neg: 3.508,
      SD2neg: 3.815,
      SD2: 5.496,
      SD3: 6.073,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.1,
      Median: 4.5763,
      SD3neg: 3.529,
      SD2neg: 3.837,
      SD2: 5.528,
      SD3: 6.108,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.2,
      Median: 4.6029,
      SD3neg: 3.549,
      SD2neg: 3.859,
      SD2: 5.56,
      SD3: 6.144,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.3,
      Median: 4.6295,
      SD3neg: 3.57,
      SD2neg: 3.882,
      SD2: 5.592,
      SD3: 6.18,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.4,
      Median: 4.6561,
      SD3neg: 3.59,
      SD2neg: 3.904,
      SD2: 5.624,
      SD3: 6.215,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.5,
      Median: 4.6827,
      SD3neg: 3.611,
      SD2neg: 3.926,
      SD2: 5.657,
      SD3: 6.251,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.6,
      Median: 4.7094,
      SD3neg: 3.631,
      SD2neg: 3.949,
      SD2: 5.689,
      SD3: 6.287,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.7,
      Median: 4.7361,
      SD3neg: 3.652,
      SD2neg: 3.971,
      SD2: 5.721,
      SD3: 6.323,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.8,
      Median: 4.7628,
      SD3neg: 3.672,
      SD2neg: 3.993,
      SD2: 5.754,
      SD3: 6.358,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 55.9,
      Median: 4.7895,
      SD3neg: 3.693,
      SD2neg: 4.016,
      SD2: 5.786,
      SD3: 6.394,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.0,
      Median: 4.8162,
      SD3neg: 3.713,
      SD2neg: 4.038,
      SD2: 5.818,
      SD3: 6.43,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.1,
      Median: 4.843,
      SD3neg: 3.734,
      SD2neg: 4.06,
      SD2: 5.851,
      SD3: 6.466,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.2,
      Median: 4.8697,
      SD3neg: 3.754,
      SD2neg: 4.083,
      SD2: 5.883,
      SD3: 6.502,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.3,
      Median: 4.8964,
      SD3neg: 3.775,
      SD2neg: 4.105,
      SD2: 5.916,
      SD3: 6.537,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.4,
      Median: 4.9232,
      SD3neg: 3.795,
      SD2neg: 4.127,
      SD2: 5.948,
      SD3: 6.573,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.5,
      Median: 4.95,
      SD3neg: 3.816,
      SD2neg: 4.15,
      SD2: 5.98,
      SD3: 6.609,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.6,
      Median: 4.9767,
      SD3neg: 3.836,
      SD2neg: 4.172,
      SD2: 6.013,
      SD3: 6.645,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.7,
      Median: 5.0034,
      SD3neg: 3.857,
      SD2neg: 4.194,
      SD2: 6.045,
      SD3: 6.681,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.8,
      Median: 5.0302,
      SD3neg: 3.878,
      SD2neg: 4.217,
      SD2: 6.078,
      SD3: 6.717,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 56.9,
      Median: 5.0569,
      SD3neg: 3.898,
      SD2neg: 4.239,
      SD2: 6.11,
      SD3: 6.753,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.0,
      Median: 5.0837,
      SD3neg: 3.919,
      SD2neg: 4.262,
      SD2: 6.143,
      SD3: 6.789,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.1,
      Median: 5.1104,
      SD3neg: 3.939,
      SD2neg: 4.284,
      SD2: 6.175,
      SD3: 6.824,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.2,
      Median: 5.1371,
      SD3neg: 3.96,
      SD2neg: 4.306,
      SD2: 6.207,
      SD3: 6.86,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.3,
      Median: 5.1639,
      SD3neg: 3.98,
      SD2neg: 4.329,
      SD2: 6.24,
      SD3: 6.896,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.4,
      Median: 5.1906,
      SD3neg: 4.001,
      SD2neg: 4.351,
      SD2: 6.272,
      SD3: 6.932,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.5,
      Median: 5.2173,
      SD3neg: 4.021,
      SD2neg: 4.373,
      SD2: 6.304,
      SD3: 6.968,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.6,
      Median: 5.244,
      SD3neg: 4.042,
      SD2neg: 4.396,
      SD2: 6.337,
      SD3: 7.003,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.7,
      Median: 5.2707,
      SD3neg: 4.062,
      SD2neg: 4.418,
      SD2: 6.369,
      SD3: 7.039,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.8,
      Median: 5.2974,
      SD3neg: 4.083,
      SD2neg: 4.44,
      SD2: 6.401,
      SD3: 7.075,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 57.9,
      Median: 5.324,
      SD3neg: 4.103,
      SD2neg: 4.463,
      SD2: 6.434,
      SD3: 7.111,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.0,
      Median: 5.3507,
      SD3neg: 4.124,
      SD2neg: 4.485,
      SD2: 6.466,
      SD3: 7.146,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.1,
      Median: 5.3773,
      SD3neg: 4.144,
      SD2neg: 4.507,
      SD2: 6.498,
      SD3: 7.182,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.2,
      Median: 5.4039,
      SD3neg: 4.165,
      SD2neg: 4.529,
      SD2: 6.53,
      SD3: 7.218,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.3,
      Median: 5.4304,
      SD3neg: 4.185,
      SD2neg: 4.552,
      SD2: 6.562,
      SD3: 7.253,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.4,
      Median: 5.4569,
      SD3neg: 4.205,
      SD2neg: 4.574,
      SD2: 6.594,
      SD3: 7.289,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.5,
      Median: 5.4834,
      SD3neg: 4.226,
      SD2neg: 4.596,
      SD2: 6.626,
      SD3: 7.324,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.6,
      Median: 5.5098,
      SD3neg: 4.246,
      SD2neg: 4.618,
      SD2: 6.659,
      SD3: 7.36,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.7,
      Median: 5.5362,
      SD3neg: 4.266,
      SD2neg: 4.64,
      SD2: 6.69,
      SD3: 7.395,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.8,
      Median: 5.5625,
      SD3neg: 4.287,
      SD2neg: 4.662,
      SD2: 6.722,
      SD3: 7.43,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 58.9,
      Median: 5.5888,
      SD3neg: 4.307,
      SD2neg: 4.684,
      SD2: 6.754,
      SD3: 7.465,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.0,
      Median: 5.6151,
      SD3neg: 4.327,
      SD2neg: 4.706,
      SD2: 6.786,
      SD3: 7.5,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.1,
      Median: 5.6413,
      SD3neg: 4.347,
      SD2neg: 4.728,
      SD2: 6.818,
      SD3: 7.535,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.2,
      Median: 5.6674,
      SD3neg: 4.367,
      SD2neg: 4.75,
      SD2: 6.849,
      SD3: 7.571,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.3,
      Median: 5.6935,
      SD3neg: 4.387,
      SD2neg: 4.772,
      SD2: 6.881,
      SD3: 7.605,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.4,
      Median: 5.7195,
      SD3neg: 4.407,
      SD2neg: 4.794,
      SD2: 6.912,
      SD3: 7.64,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.5,
      Median: 5.7454,
      SD3neg: 4.427,
      SD2neg: 4.815,
      SD2: 6.944,
      SD3: 7.675,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.6,
      Median: 5.7713,
      SD3neg: 4.447,
      SD2neg: 4.837,
      SD2: 6.975,
      SD3: 7.71,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.7,
      Median: 5.7971,
      SD3neg: 4.467,
      SD2neg: 4.859,
      SD2: 7.006,
      SD3: 7.744,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.8,
      Median: 5.8229,
      SD3neg: 4.487,
      SD2neg: 4.88,
      SD2: 7.037,
      SD3: 7.779,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 59.9,
      Median: 5.8485,
      SD3neg: 4.507,
      SD2neg: 4.902,
      SD2: 7.068,
      SD3: 7.813,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.0,
      Median: 5.8742,
      SD3neg: 4.527,
      SD2neg: 4.923,
      SD2: 7.099,
      SD3: 7.847,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.1,
      Median: 5.8997,
      SD3neg: 4.546,
      SD2neg: 4.945,
      SD2: 7.13,
      SD3: 7.881,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.2,
      Median: 5.9252,
      SD3neg: 4.566,
      SD2neg: 4.966,
      SD2: 7.161,
      SD3: 7.915,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.3,
      Median: 5.9507,
      SD3neg: 4.585,
      SD2neg: 4.987,
      SD2: 7.192,
      SD3: 7.95,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.4,
      Median: 5.9761,
      SD3neg: 4.605,
      SD2neg: 5.009,
      SD2: 7.223,
      SD3: 7.983,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.5,
      Median: 6.0014,
      SD3neg: 4.624,
      SD2neg: 5.03,
      SD2: 7.253,
      SD3: 8.017,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.6,
      Median: 6.0266,
      SD3neg: 4.644,
      SD2neg: 5.051,
      SD2: 7.284,
      SD3: 8.051,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.7,
      Median: 6.0518,
      SD3neg: 4.663,
      SD2neg: 5.072,
      SD2: 7.314,
      SD3: 8.085,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.8,
      Median: 6.0769,
      SD3neg: 4.683,
      SD2neg: 5.093,
      SD2: 7.344,
      SD3: 8.118,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 60.9,
      Median: 6.102,
      SD3neg: 4.702,
      SD2neg: 5.114,
      SD2: 7.375,
      SD3: 8.152,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.0,
      Median: 6.127,
      SD3neg: 4.721,
      SD2neg: 5.135,
      SD2: 7.405,
      SD3: 8.185,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.1,
      Median: 6.1519,
      SD3neg: 4.74,
      SD2neg: 5.156,
      SD2: 7.435,
      SD3: 8.218,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.2,
      Median: 6.1768,
      SD3neg: 4.76,
      SD2neg: 5.177,
      SD2: 7.465,
      SD3: 8.251,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.3,
      Median: 6.2017,
      SD3neg: 4.779,
      SD2neg: 5.198,
      SD2: 7.495,
      SD3: 8.285,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.4,
      Median: 6.2264,
      SD3neg: 4.798,
      SD2neg: 5.218,
      SD2: 7.525,
      SD3: 8.318,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.5,
      Median: 6.2511,
      SD3neg: 4.817,
      SD2neg: 5.239,
      SD2: 7.555,
      SD3: 8.351,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.6,
      Median: 6.2758,
      SD3neg: 4.836,
      SD2neg: 5.26,
      SD2: 7.585,
      SD3: 8.384,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.7,
      Median: 6.3004,
      SD3neg: 4.855,
      SD2neg: 5.28,
      SD2: 7.614,
      SD3: 8.416,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.8,
      Median: 6.3249,
      SD3neg: 4.874,
      SD2neg: 5.301,
      SD2: 7.644,
      SD3: 8.449,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 61.9,
      Median: 6.3494,
      SD3neg: 4.893,
      SD2neg: 5.322,
      SD2: 7.673,
      SD3: 8.482,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.0,
      Median: 6.3738,
      SD3neg: 4.912,
      SD2neg: 5.342,
      SD2: 7.703,
      SD3: 8.514,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.1,
      Median: 6.3981,
      SD3neg: 4.93,
      SD2neg: 5.362,
      SD2: 7.732,
      SD3: 8.547,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.2,
      Median: 6.4224,
      SD3neg: 4.949,
      SD2neg: 5.383,
      SD2: 7.762,
      SD3: 8.579,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.3,
      Median: 6.4466,
      SD3neg: 4.968,
      SD2neg: 5.403,
      SD2: 7.791,
      SD3: 8.611,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.4,
      Median: 6.4708,
      SD3neg: 4.987,
      SD2neg: 5.423,
      SD2: 7.82,
      SD3: 8.643,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.5,
      Median: 6.4948,
      SD3neg: 5.005,
      SD2neg: 5.444,
      SD2: 7.849,
      SD3: 8.675,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.6,
      Median: 6.5189,
      SD3neg: 5.024,
      SD2neg: 5.464,
      SD2: 7.878,
      SD3: 8.707,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.7,
      Median: 6.5429,
      SD3neg: 5.042,
      SD2neg: 5.484,
      SD2: 7.907,
      SD3: 8.739,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.8,
      Median: 6.5668,
      SD3neg: 5.061,
      SD2neg: 5.504,
      SD2: 7.936,
      SD3: 8.771,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 62.9,
      Median: 6.5906,
      SD3neg: 5.079,
      SD2neg: 5.524,
      SD2: 7.964,
      SD3: 8.803,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.0,
      Median: 6.6144,
      SD3neg: 5.098,
      SD2neg: 5.544,
      SD2: 7.993,
      SD3: 8.834,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.1,
      Median: 6.6382,
      SD3neg: 5.116,
      SD2neg: 5.564,
      SD2: 8.022,
      SD3: 8.866,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.2,
      Median: 6.6619,
      SD3neg: 5.134,
      SD2neg: 5.584,
      SD2: 8.05,
      SD3: 8.898,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.3,
      Median: 6.6856,
      SD3neg: 5.153,
      SD2neg: 5.604,
      SD2: 8.079,
      SD3: 8.929,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.4,
      Median: 6.7092,
      SD3neg: 5.171,
      SD2neg: 5.624,
      SD2: 8.107,
      SD3: 8.96,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.5,
      Median: 6.7328,
      SD3neg: 5.189,
      SD2neg: 5.644,
      SD2: 8.136,
      SD3: 8.992,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.6,
      Median: 6.7563,
      SD3neg: 5.207,
      SD2neg: 5.663,
      SD2: 8.164,
      SD3: 9.023,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.7,
      Median: 6.7798,
      SD3neg: 5.225,
      SD2neg: 5.683,
      SD2: 8.192,
      SD3: 9.054,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.8,
      Median: 6.8033,
      SD3neg: 5.244,
      SD2neg: 5.703,
      SD2: 8.221,
      SD3: 9.085,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 63.9,
      Median: 6.8267,
      SD3neg: 5.262,
      SD2neg: 5.722,
      SD2: 8.249,
      SD3: 9.117,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.0,
      Median: 6.8501,
      SD3neg: 5.28,
      SD2neg: 5.742,
      SD2: 8.277,
      SD3: 9.148,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.1,
      Median: 6.8734,
      SD3neg: 5.298,
      SD2neg: 5.762,
      SD2: 8.305,
      SD3: 9.178,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.2,
      Median: 6.8967,
      SD3neg: 5.316,
      SD2neg: 5.781,
      SD2: 8.333,
      SD3: 9.21,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.3,
      Median: 6.9199,
      SD3neg: 5.334,
      SD2neg: 5.801,
      SD2: 8.361,
      SD3: 9.24,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.4,
      Median: 6.9431,
      SD3neg: 5.352,
      SD2neg: 5.82,
      SD2: 8.389,
      SD3: 9.271,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.5,
      Median: 6.9662,
      SD3neg: 5.37,
      SD2neg: 5.84,
      SD2: 8.417,
      SD3: 9.302,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.6,
      Median: 6.9893,
      SD3neg: 5.388,
      SD2neg: 5.859,
      SD2: 8.444,
      SD3: 9.332,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.7,
      Median: 7.0124,
      SD3neg: 5.406,
      SD2neg: 5.879,
      SD2: 8.472,
      SD3: 9.363,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.8,
      Median: 7.0354,
      SD3neg: 5.424,
      SD2neg: 5.898,
      SD2: 8.5,
      SD3: 9.393,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 64.9,
      Median: 7.0583,
      SD3neg: 5.441,
      SD2neg: 5.917,
      SD2: 8.527,
      SD3: 9.424,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.0,
      Median: 7.0812,
      SD3neg: 5.459,
      SD2neg: 5.937,
      SD2: 8.555,
      SD3: 9.454,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.1,
      Median: 7.1041,
      SD3neg: 5.477,
      SD2neg: 5.956,
      SD2: 8.582,
      SD3: 9.484,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.2,
      Median: 7.1269,
      SD3neg: 5.495,
      SD2neg: 5.975,
      SD2: 8.61,
      SD3: 9.514,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.3,
      Median: 7.1497,
      SD3neg: 5.512,
      SD2neg: 5.994,
      SD2: 8.637,
      SD3: 9.545,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.4,
      Median: 7.1724,
      SD3neg: 5.53,
      SD2neg: 6.014,
      SD2: 8.665,
      SD3: 9.575,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.5,
      Median: 7.195,
      SD3neg: 5.547,
      SD2neg: 6.033,
      SD2: 8.692,
      SD3: 9.605,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.6,
      Median: 7.2177,
      SD3neg: 5.565,
      SD2neg: 6.052,
      SD2: 8.719,
      SD3: 9.635,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.7,
      Median: 7.2402,
      SD3neg: 5.583,
      SD2neg: 6.071,
      SD2: 8.746,
      SD3: 9.664,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.8,
      Median: 7.2627,
      SD3neg: 5.6,
      SD2neg: 6.09,
      SD2: 8.773,
      SD3: 9.694,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 65.9,
      Median: 7.2852,
      SD3neg: 5.618,
      SD2neg: 6.109,
      SD2: 8.8,
      SD3: 9.724,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.0,
      Median: 7.3076,
      SD3neg: 5.635,
      SD2neg: 6.128,
      SD2: 8.827,
      SD3: 9.753,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.1,
      Median: 7.33,
      SD3neg: 5.652,
      SD2neg: 6.146,
      SD2: 8.854,
      SD3: 9.783,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.2,
      Median: 7.3523,
      SD3neg: 5.67,
      SD2neg: 6.165,
      SD2: 8.881,
      SD3: 9.813,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.3,
      Median: 7.3745,
      SD3neg: 5.687,
      SD2neg: 6.184,
      SD2: 8.907,
      SD3: 9.842,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.4,
      Median: 7.3967,
      SD3neg: 5.704,
      SD2neg: 6.203,
      SD2: 8.934,
      SD3: 9.871,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.5,
      Median: 7.4189,
      SD3neg: 5.721,
      SD2neg: 6.221,
      SD2: 8.96,
      SD3: 9.901,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.6,
      Median: 7.441,
      SD3neg: 5.739,
      SD2neg: 6.24,
      SD2: 8.987,
      SD3: 9.93,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.7,
      Median: 7.463,
      SD3neg: 5.756,
      SD2neg: 6.259,
      SD2: 9.013,
      SD3: 9.959,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.8,
      Median: 7.485,
      SD3neg: 5.773,
      SD2neg: 6.277,
      SD2: 9.04,
      SD3: 9.988,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 66.9,
      Median: 7.5069,
      SD3neg: 5.79,
      SD2neg: 6.296,
      SD2: 9.066,
      SD3: 10.017,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.0,
      Median: 7.5288,
      SD3neg: 5.807,
      SD2neg: 6.314,
      SD2: 9.092,
      SD3: 10.046,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.1,
      Median: 7.5507,
      SD3neg: 5.824,
      SD2neg: 6.333,
      SD2: 9.118,
      SD3: 10.074,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.2,
      Median: 7.5724,
      SD3neg: 5.841,
      SD2neg: 6.351,
      SD2: 9.144,
      SD3: 10.103,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.3,
      Median: 7.5942,
      SD3neg: 5.858,
      SD2neg: 6.369,
      SD2: 9.171,
      SD3: 10.132,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.4,
      Median: 7.6158,
      SD3neg: 5.875,
      SD2neg: 6.388,
      SD2: 9.196,
      SD3: 10.16,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.5,
      Median: 7.6375,
      SD3neg: 5.892,
      SD2neg: 6.406,
      SD2: 9.222,
      SD3: 10.189,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.6,
      Median: 7.659,
      SD3neg: 5.908,
      SD2neg: 6.424,
      SD2: 9.248,
      SD3: 10.217,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.7,
      Median: 7.6806,
      SD3neg: 5.925,
      SD2neg: 6.442,
      SD2: 9.274,
      SD3: 10.246,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.8,
      Median: 7.702,
      SD3neg: 5.942,
      SD2neg: 6.46,
      SD2: 9.3,
      SD3: 10.274,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 67.9,
      Median: 7.7234,
      SD3neg: 5.959,
      SD2neg: 6.479,
      SD2: 9.325,
      SD3: 10.302,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.0,
      Median: 7.7448,
      SD3neg: 5.975,
      SD2neg: 6.497,
      SD2: 9.351,
      SD3: 10.33,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.1,
      Median: 7.7661,
      SD3neg: 5.992,
      SD2neg: 6.515,
      SD2: 9.376,
      SD3: 10.358,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.2,
      Median: 7.7874,
      SD3neg: 6.009,
      SD2neg: 6.533,
      SD2: 9.402,
      SD3: 10.386,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.3,
      Median: 7.8086,
      SD3neg: 6.025,
      SD2neg: 6.55,
      SD2: 9.427,
      SD3: 10.414,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.4,
      Median: 7.8298,
      SD3neg: 6.042,
      SD2neg: 6.568,
      SD2: 9.453,
      SD3: 10.442,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.5,
      Median: 7.8509,
      SD3neg: 6.058,
      SD2neg: 6.586,
      SD2: 9.478,
      SD3: 10.47,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.6,
      Median: 7.872,
      SD3neg: 6.075,
      SD2neg: 6.604,
      SD2: 9.503,
      SD3: 10.498,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.7,
      Median: 7.893,
      SD3neg: 6.091,
      SD2neg: 6.622,
      SD2: 9.528,
      SD3: 10.525,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.8,
      Median: 7.914,
      SD3neg: 6.107,
      SD2neg: 6.64,
      SD2: 9.553,
      SD3: 10.553,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 68.9,
      Median: 7.935,
      SD3neg: 6.124,
      SD2neg: 6.657,
      SD2: 9.578,
      SD3: 10.58,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.0,
      Median: 7.9559,
      SD3neg: 6.14,
      SD2neg: 6.675,
      SD2: 9.603,
      SD3: 10.608,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.1,
      Median: 7.9768,
      SD3neg: 6.156,
      SD2neg: 6.693,
      SD2: 9.628,
      SD3: 10.635,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.2,
      Median: 7.9976,
      SD3neg: 6.173,
      SD2neg: 6.71,
      SD2: 9.653,
      SD3: 10.663,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.3,
      Median: 8.0184,
      SD3neg: 6.189,
      SD2neg: 6.728,
      SD2: 9.678,
      SD3: 10.69,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.4,
      Median: 8.0392,
      SD3neg: 6.205,
      SD2neg: 6.745,
      SD2: 9.703,
      SD3: 10.717,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.5,
      Median: 8.0599,
      SD3neg: 6.221,
      SD2neg: 6.763,
      SD2: 9.728,
      SD3: 10.745,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.6,
      Median: 8.0806,
      SD3neg: 6.238,
      SD2neg: 6.781,
      SD2: 9.753,
      SD3: 10.771,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.7,
      Median: 8.1012,
      SD3neg: 6.254,
      SD2neg: 6.798,
      SD2: 9.777,
      SD3: 10.798,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.8,
      Median: 8.1218,
      SD3neg: 6.27,
      SD2neg: 6.815,
      SD2: 9.802,
      SD3: 10.826,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 69.9,
      Median: 8.1424,
      SD3neg: 6.286,
      SD2neg: 6.833,
      SD2: 9.826,
      SD3: 10.853,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.0,
      Median: 8.163,
      SD3neg: 6.302,
      SD2neg: 6.85,
      SD2: 9.851,
      SD3: 10.88,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.1,
      Median: 8.1835,
      SD3neg: 6.318,
      SD2neg: 6.868,
      SD2: 9.876,
      SD3: 10.907,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.2,
      Median: 8.2039,
      SD3neg: 6.334,
      SD2neg: 6.885,
      SD2: 9.9,
      SD3: 10.933,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.3,
      Median: 8.2244,
      SD3neg: 6.35,
      SD2neg: 6.902,
      SD2: 9.924,
      SD3: 10.96,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.4,
      Median: 8.2448,
      SD3neg: 6.366,
      SD2neg: 6.919,
      SD2: 9.949,
      SD3: 10.987,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.5,
      Median: 8.2651,
      SD3neg: 6.382,
      SD2neg: 6.937,
      SD2: 9.973,
      SD3: 11.014,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.6,
      Median: 8.2855,
      SD3neg: 6.398,
      SD2neg: 6.954,
      SD2: 9.997,
      SD3: 11.04,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.7,
      Median: 8.3058,
      SD3neg: 6.414,
      SD2neg: 6.971,
      SD2: 10.021,
      SD3: 11.067,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.8,
      Median: 8.3261,
      SD3neg: 6.429,
      SD2neg: 6.988,
      SD2: 10.046,
      SD3: 11.093,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 70.9,
      Median: 8.3464,
      SD3neg: 6.445,
      SD2neg: 7.006,
      SD2: 10.07,
      SD3: 11.12,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.0,
      Median: 8.3666,
      SD3neg: 6.461,
      SD2neg: 7.023,
      SD2: 10.094,
      SD3: 11.147,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.1,
      Median: 8.3869,
      SD3neg: 6.477,
      SD2neg: 7.04,
      SD2: 10.118,
      SD3: 11.173,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.2,
      Median: 8.4071,
      SD3neg: 6.493,
      SD2neg: 7.057,
      SD2: 10.142,
      SD3: 11.199,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.3,
      Median: 8.4273,
      SD3neg: 6.509,
      SD2neg: 7.074,
      SD2: 10.167,
      SD3: 11.226,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.4,
      Median: 8.4474,
      SD3neg: 6.524,
      SD2neg: 7.091,
      SD2: 10.191,
      SD3: 11.252,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.5,
      Median: 8.4676,
      SD3neg: 6.54,
      SD2neg: 7.108,
      SD2: 10.215,
      SD3: 11.279,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.6,
      Median: 8.4877,
      SD3neg: 6.556,
      SD2neg: 7.125,
      SD2: 10.239,
      SD3: 11.305,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.7,
      Median: 8.5078,
      SD3neg: 6.572,
      SD2neg: 7.142,
      SD2: 10.263,
      SD3: 11.331,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.8,
      Median: 8.5278,
      SD3neg: 6.587,
      SD2neg: 7.159,
      SD2: 10.286,
      SD3: 11.358,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 71.9,
      Median: 8.5479,
      SD3neg: 6.603,
      SD2neg: 7.176,
      SD2: 10.311,
      SD3: 11.384,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.0,
      Median: 8.5679,
      SD3neg: 6.619,
      SD2neg: 7.193,
      SD2: 10.334,
      SD3: 11.41,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.1,
      Median: 8.5879,
      SD3neg: 6.635,
      SD2neg: 7.21,
      SD2: 10.358,
      SD3: 11.436,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.2,
      Median: 8.6078,
      SD3neg: 6.65,
      SD2neg: 7.227,
      SD2: 10.382,
      SD3: 11.462,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.3,
      Median: 8.6277,
      SD3neg: 6.666,
      SD2neg: 7.244,
      SD2: 10.406,
      SD3: 11.488,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.4,
      Median: 8.6476,
      SD3neg: 6.681,
      SD2neg: 7.261,
      SD2: 10.429,
      SD3: 11.514,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.5,
      Median: 8.6674,
      SD3neg: 6.697,
      SD2neg: 7.278,
      SD2: 10.453,
      SD3: 11.54,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.6,
      Median: 8.6872,
      SD3neg: 6.712,
      SD2neg: 7.294,
      SD2: 10.477,
      SD3: 11.566,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.7,
      Median: 8.707,
      SD3neg: 6.728,
      SD2neg: 7.311,
      SD2: 10.5,
      SD3: 11.592,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.8,
      Median: 8.7267,
      SD3neg: 6.743,
      SD2neg: 7.328,
      SD2: 10.524,
      SD3: 11.618,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 72.9,
      Median: 8.7464,
      SD3neg: 6.759,
      SD2neg: 7.345,
      SD2: 10.547,
      SD3: 11.643,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.0,
      Median: 8.7661,
      SD3neg: 6.774,
      SD2neg: 7.361,
      SD2: 10.571,
      SD3: 11.669,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.1,
      Median: 8.7857,
      SD3neg: 6.79,
      SD2neg: 7.378,
      SD2: 10.594,
      SD3: 11.695,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.2,
      Median: 8.8053,
      SD3neg: 6.805,
      SD2neg: 7.395,
      SD2: 10.617,
      SD3: 11.72,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.3,
      Median: 8.8248,
      SD3neg: 6.82,
      SD2neg: 7.411,
      SD2: 10.64,
      SD3: 11.746,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.4,
      Median: 8.8443,
      SD3neg: 6.836,
      SD2neg: 7.428,
      SD2: 10.664,
      SD3: 11.771,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.5,
      Median: 8.8638,
      SD3neg: 6.851,
      SD2neg: 7.444,
      SD2: 10.687,
      SD3: 11.797,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.6,
      Median: 8.8831,
      SD3neg: 6.866,
      SD2neg: 7.461,
      SD2: 10.71,
      SD3: 11.822,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.7,
      Median: 8.9025,
      SD3neg: 6.881,
      SD2neg: 7.477,
      SD2: 10.733,
      SD3: 11.847,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.8,
      Median: 8.9217,
      SD3neg: 6.896,
      SD2neg: 7.493,
      SD2: 10.756,
      SD3: 11.872,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 73.9,
      Median: 8.941,
      SD3neg: 6.911,
      SD2neg: 7.51,
      SD2: 10.779,
      SD3: 11.898,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.0,
      Median: 8.9601,
      SD3neg: 6.927,
      SD2neg: 7.526,
      SD2: 10.801,
      SD3: 11.922,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.1,
      Median: 8.9792,
      SD3neg: 6.942,
      SD2neg: 7.542,
      SD2: 10.824,
      SD3: 11.947,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.2,
      Median: 8.9983,
      SD3neg: 6.957,
      SD2neg: 7.559,
      SD2: 10.847,
      SD3: 11.972,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.3,
      Median: 9.0173,
      SD3neg: 6.972,
      SD2neg: 7.575,
      SD2: 10.869,
      SD3: 11.997,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.4,
      Median: 9.0363,
      SD3neg: 6.986,
      SD2neg: 7.591,
      SD2: 10.892,
      SD3: 12.022,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.5,
      Median: 9.0552,
      SD3neg: 7.001,
      SD2neg: 7.607,
      SD2: 10.915,
      SD3: 12.046,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.6,
      Median: 9.074,
      SD3neg: 7.016,
      SD2neg: 7.623,
      SD2: 10.937,
      SD3: 12.071,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.7,
      Median: 9.0928,
      SD3neg: 7.031,
      SD2neg: 7.639,
      SD2: 10.959,
      SD3: 12.095,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.8,
      Median: 9.1116,
      SD3neg: 7.046,
      SD2neg: 7.655,
      SD2: 10.982,
      SD3: 12.12,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 74.9,
      Median: 9.1303,
      SD3neg: 7.06,
      SD2neg: 7.671,
      SD2: 11.004,
      SD3: 12.144,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.0,
      Median: 9.149,
      SD3neg: 7.075,
      SD2neg: 7.687,
      SD2: 11.026,
      SD3: 12.168,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.1,
      Median: 9.1676,
      SD3neg: 7.09,
      SD2neg: 7.702,
      SD2: 11.048,
      SD3: 12.193,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.2,
      Median: 9.1862,
      SD3neg: 7.104,
      SD2neg: 7.718,
      SD2: 11.07,
      SD3: 12.217,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.3,
      Median: 9.2048,
      SD3neg: 7.119,
      SD2neg: 7.734,
      SD2: 11.092,
      SD3: 12.241,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.4,
      Median: 9.2233,
      SD3neg: 7.134,
      SD2neg: 7.75,
      SD2: 11.114,
      SD3: 12.265,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.5,
      Median: 9.2418,
      SD3neg: 7.148,
      SD2neg: 7.766,
      SD2: 11.136,
      SD3: 12.289,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.6,
      Median: 9.2602,
      SD3neg: 7.163,
      SD2neg: 7.781,
      SD2: 11.158,
      SD3: 12.313,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.7,
      Median: 9.2786,
      SD3neg: 7.177,
      SD2neg: 7.797,
      SD2: 11.18,
      SD3: 12.337,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.8,
      Median: 9.297,
      SD3neg: 7.192,
      SD2neg: 7.812,
      SD2: 11.202,
      SD3: 12.361,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 75.9,
      Median: 9.3154,
      SD3neg: 7.206,
      SD2neg: 7.828,
      SD2: 11.224,
      SD3: 12.385,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.0,
      Median: 9.3337,
      SD3neg: 7.221,
      SD2neg: 7.844,
      SD2: 11.246,
      SD3: 12.408,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.1,
      Median: 9.352,
      SD3neg: 7.235,
      SD2neg: 7.859,
      SD2: 11.267,
      SD3: 12.432,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.2,
      Median: 9.3703,
      SD3neg: 7.249,
      SD2neg: 7.875,
      SD2: 11.289,
      SD3: 12.456,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.3,
      Median: 9.3886,
      SD3neg: 7.264,
      SD2neg: 7.89,
      SD2: 11.311,
      SD3: 12.48,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.4,
      Median: 9.4069,
      SD3neg: 7.278,
      SD2neg: 7.906,
      SD2: 11.333,
      SD3: 12.504,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.5,
      Median: 9.4252,
      SD3neg: 7.293,
      SD2neg: 7.922,
      SD2: 11.354,
      SD3: 12.527,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.6,
      Median: 9.4435,
      SD3neg: 7.307,
      SD2neg: 7.937,
      SD2: 11.376,
      SD3: 12.551,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.7,
      Median: 9.4617,
      SD3neg: 7.321,
      SD2neg: 7.953,
      SD2: 11.398,
      SD3: 12.575,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.8,
      Median: 9.48,
      SD3neg: 7.336,
      SD2neg: 7.968,
      SD2: 11.419,
      SD3: 12.598,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 76.9,
      Median: 9.4983,
      SD3neg: 7.35,
      SD2neg: 7.984,
      SD2: 11.441,
      SD3: 12.622,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.0,
      Median: 9.5166,
      SD3neg: 7.365,
      SD2neg: 7.999,
      SD2: 11.463,
      SD3: 12.646,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.1,
      Median: 9.535,
      SD3neg: 7.379,
      SD2neg: 8.015,
      SD2: 11.484,
      SD3: 12.67,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.2,
      Median: 9.5533,
      SD3neg: 7.394,
      SD2neg: 8.031,
      SD2: 11.506,
      SD3: 12.694,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.3,
      Median: 9.5717,
      SD3neg: 7.408,
      SD2neg: 8.046,
      SD2: 11.528,
      SD3: 12.718,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.4,
      Median: 9.5901,
      SD3neg: 7.423,
      SD2neg: 8.062,
      SD2: 11.55,
      SD3: 12.741,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.5,
      Median: 9.6086,
      SD3neg: 7.437,
      SD2neg: 8.078,
      SD2: 11.572,
      SD3: 12.765,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.6,
      Median: 9.6271,
      SD3neg: 7.452,
      SD2neg: 8.094,
      SD2: 11.594,
      SD3: 12.79,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.7,
      Median: 9.6456,
      SD3neg: 7.466,
      SD2neg: 8.109,
      SD2: 11.616,
      SD3: 12.813,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.8,
      Median: 9.6642,
      SD3neg: 7.481,
      SD2neg: 8.125,
      SD2: 11.638,
      SD3: 12.838,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 77.9,
      Median: 9.6828,
      SD3neg: 7.496,
      SD2neg: 8.141,
      SD2: 11.66,
      SD3: 12.861,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.0,
      Median: 9.7015,
      SD3neg: 7.511,
      SD2neg: 8.157,
      SD2: 11.682,
      SD3: 12.886,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.1,
      Median: 9.7202,
      SD3neg: 7.525,
      SD2neg: 8.173,
      SD2: 11.704,
      SD3: 12.91,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.2,
      Median: 9.739,
      SD3neg: 7.54,
      SD2neg: 8.189,
      SD2: 11.727,
      SD3: 12.935,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.3,
      Median: 9.7578,
      SD3neg: 7.555,
      SD2neg: 8.205,
      SD2: 11.749,
      SD3: 12.959,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.4,
      Median: 9.7767,
      SD3neg: 7.57,
      SD2neg: 8.221,
      SD2: 11.771,
      SD3: 12.984,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.5,
      Median: 9.7957,
      SD3neg: 7.585,
      SD2neg: 8.237,
      SD2: 11.794,
      SD3: 13.008,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.6,
      Median: 9.8147,
      SD3neg: 7.6,
      SD2neg: 8.253,
      SD2: 11.816,
      SD3: 13.033,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.7,
      Median: 9.8338,
      SD3neg: 7.615,
      SD2neg: 8.27,
      SD2: 11.839,
      SD3: 13.058,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.8,
      Median: 9.853,
      SD3neg: 7.63,
      SD2neg: 8.286,
      SD2: 11.862,
      SD3: 13.083,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 78.9,
      Median: 9.8722,
      SD3neg: 7.645,
      SD2neg: 8.302,
      SD2: 11.884,
      SD3: 13.107,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.0,
      Median: 9.8915,
      SD3neg: 7.66,
      SD2neg: 8.319,
      SD2: 11.907,
      SD3: 13.132,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.1,
      Median: 9.9109,
      SD3neg: 7.676,
      SD2neg: 8.335,
      SD2: 11.93,
      SD3: 13.158,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.2,
      Median: 9.9303,
      SD3neg: 7.691,
      SD2neg: 8.352,
      SD2: 11.954,
      SD3: 13.183,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.3,
      Median: 9.9499,
      SD3neg: 7.706,
      SD2neg: 8.368,
      SD2: 11.977,
      SD3: 13.208,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.4,
      Median: 9.9695,
      SD3neg: 7.722,
      SD2neg: 8.385,
      SD2: 12.0,
      SD3: 13.234,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.5,
      Median: 9.9892,
      SD3neg: 7.737,
      SD2neg: 8.402,
      SD2: 12.023,
      SD3: 13.26,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.6,
      Median: 10.009,
      SD3neg: 7.753,
      SD2neg: 8.419,
      SD2: 12.047,
      SD3: 13.285,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.7,
      Median: 10.0289,
      SD3neg: 7.769,
      SD2neg: 8.436,
      SD2: 12.07,
      SD3: 13.311,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.8,
      Median: 10.0489,
      SD3neg: 7.784,
      SD2neg: 8.453,
      SD2: 12.094,
      SD3: 13.337,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 79.9,
      Median: 10.069,
      SD3neg: 7.8,
      SD2neg: 8.47,
      SD2: 12.118,
      SD3: 13.363,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.0,
      Median: 10.0891,
      SD3neg: 7.816,
      SD2neg: 8.487,
      SD2: 12.142,
      SD3: 13.389,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.1,
      Median: 10.1094,
      SD3neg: 7.832,
      SD2neg: 8.504,
      SD2: 12.166,
      SD3: 13.416,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.2,
      Median: 10.1298,
      SD3neg: 7.848,
      SD2neg: 8.522,
      SD2: 12.19,
      SD3: 13.442,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.3,
      Median: 10.1503,
      SD3neg: 7.864,
      SD2neg: 8.539,
      SD2: 12.215,
      SD3: 13.469,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.4,
      Median: 10.1709,
      SD3neg: 7.88,
      SD2neg: 8.556,
      SD2: 12.239,
      SD3: 13.496,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.5,
      Median: 10.1916,
      SD3neg: 7.897,
      SD2neg: 8.574,
      SD2: 12.264,
      SD3: 13.523,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.6,
      Median: 10.2123,
      SD3neg: 7.913,
      SD2neg: 8.592,
      SD2: 12.289,
      SD3: 13.55,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.7,
      Median: 10.2332,
      SD3neg: 7.929,
      SD2neg: 8.609,
      SD2: 12.313,
      SD3: 13.577,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.8,
      Median: 10.2542,
      SD3neg: 7.946,
      SD2neg: 8.627,
      SD2: 12.338,
      SD3: 13.604,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 80.9,
      Median: 10.2753,
      SD3neg: 7.963,
      SD2neg: 8.645,
      SD2: 12.363,
      SD3: 13.632,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.0,
      Median: 10.2965,
      SD3neg: 7.979,
      SD2neg: 8.663,
      SD2: 12.388,
      SD3: 13.659,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.1,
      Median: 10.3178,
      SD3neg: 7.996,
      SD2neg: 8.681,
      SD2: 12.414,
      SD3: 13.687,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.2,
      Median: 10.3393,
      SD3neg: 8.013,
      SD2neg: 8.7,
      SD2: 12.439,
      SD3: 13.715,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.3,
      Median: 10.3608,
      SD3neg: 8.03,
      SD2neg: 8.718,
      SD2: 12.465,
      SD3: 13.743,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.4,
      Median: 10.3824,
      SD3neg: 8.047,
      SD2neg: 8.736,
      SD2: 12.491,
      SD3: 13.771,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.5,
      Median: 10.4041,
      SD3neg: 8.064,
      SD2neg: 8.755,
      SD2: 12.517,
      SD3: 13.8,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.6,
      Median: 10.4258,
      SD3neg: 8.081,
      SD2neg: 8.773,
      SD2: 12.542,
      SD3: 13.828,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.7,
      Median: 10.4477,
      SD3neg: 8.098,
      SD2neg: 8.792,
      SD2: 12.569,
      SD3: 13.857,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.8,
      Median: 10.4697,
      SD3neg: 8.115,
      SD2neg: 8.81,
      SD2: 12.595,
      SD3: 13.885,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 81.9,
      Median: 10.4918,
      SD3neg: 8.133,
      SD2neg: 8.829,
      SD2: 12.621,
      SD3: 13.914,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.0,
      Median: 10.514,
      SD3neg: 8.15,
      SD2neg: 8.848,
      SD2: 12.647,
      SD3: 13.943,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.1,
      Median: 10.5363,
      SD3neg: 8.167,
      SD2neg: 8.867,
      SD2: 12.674,
      SD3: 13.972,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.2,
      Median: 10.5586,
      SD3neg: 8.185,
      SD2neg: 8.886,
      SD2: 12.701,
      SD3: 14.001,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.3,
      Median: 10.5811,
      SD3neg: 8.203,
      SD2neg: 8.905,
      SD2: 12.727,
      SD3: 14.031,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.4,
      Median: 10.6037,
      SD3neg: 8.22,
      SD2neg: 8.924,
      SD2: 12.755,
      SD3: 14.061,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.5,
      Median: 10.6263,
      SD3neg: 8.238,
      SD2neg: 8.943,
      SD2: 12.781,
      SD3: 14.09,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.6,
      Median: 10.6491,
      SD3neg: 8.256,
      SD2neg: 8.962,
      SD2: 12.809,
      SD3: 14.12,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.7,
      Median: 10.6719,
      SD3neg: 8.274,
      SD2neg: 8.982,
      SD2: 12.836,
      SD3: 14.15,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.8,
      Median: 10.6948,
      SD3neg: 8.292,
      SD2neg: 9.001,
      SD2: 12.863,
      SD3: 14.18,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 82.9,
      Median: 10.7178,
      SD3neg: 8.31,
      SD2neg: 9.021,
      SD2: 12.89,
      SD3: 14.21,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.0,
      Median: 10.741,
      SD3neg: 8.328,
      SD2neg: 9.04,
      SD2: 12.918,
      SD3: 14.24,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.1,
      Median: 10.7641,
      SD3neg: 8.346,
      SD2neg: 9.06,
      SD2: 12.946,
      SD3: 14.27,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.2,
      Median: 10.7874,
      SD3neg: 8.364,
      SD2neg: 9.08,
      SD2: 12.974,
      SD3: 14.301,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.3,
      Median: 10.8108,
      SD3neg: 8.383,
      SD2neg: 9.1,
      SD2: 13.001,
      SD3: 14.331,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.4,
      Median: 10.8343,
      SD3neg: 8.401,
      SD2neg: 9.119,
      SD2: 13.03,
      SD3: 14.363,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.5,
      Median: 10.8578,
      SD3neg: 8.419,
      SD2neg: 9.139,
      SD2: 13.058,
      SD3: 14.393,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.6,
      Median: 10.8814,
      SD3neg: 8.438,
      SD2neg: 9.159,
      SD2: 13.086,
      SD3: 14.424,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.7,
      Median: 10.9051,
      SD3neg: 8.456,
      SD2neg: 9.179,
      SD2: 13.114,
      SD3: 14.455,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.8,
      Median: 10.9289,
      SD3neg: 8.475,
      SD2neg: 9.199,
      SD2: 13.143,
      SD3: 14.486,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 83.9,
      Median: 10.9527,
      SD3neg: 8.493,
      SD2neg: 9.22,
      SD2: 13.171,
      SD3: 14.518,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.0,
      Median: 10.9767,
      SD3neg: 8.512,
      SD2neg: 9.24,
      SD2: 13.2,
      SD3: 14.549,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.1,
      Median: 11.0007,
      SD3neg: 8.531,
      SD2neg: 9.26,
      SD2: 13.228,
      SD3: 14.581,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.2,
      Median: 11.0248,
      SD3neg: 8.55,
      SD2neg: 9.281,
      SD2: 13.257,
      SD3: 14.613,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.3,
      Median: 11.0489,
      SD3neg: 8.569,
      SD2neg: 9.301,
      SD2: 13.286,
      SD3: 14.644,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.4,
      Median: 11.0731,
      SD3neg: 8.587,
      SD2neg: 9.321,
      SD2: 13.315,
      SD3: 14.676,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.5,
      Median: 11.0974,
      SD3neg: 8.606,
      SD2neg: 9.342,
      SD2: 13.344,
      SD3: 14.708,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.6,
      Median: 11.1218,
      SD3neg: 8.625,
      SD2neg: 9.363,
      SD2: 13.373,
      SD3: 14.74,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.7,
      Median: 11.1462,
      SD3neg: 8.644,
      SD2neg: 9.383,
      SD2: 13.402,
      SD3: 14.772,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.8,
      Median: 11.1707,
      SD3neg: 8.663,
      SD2neg: 9.404,
      SD2: 13.432,
      SD3: 14.805,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 84.9,
      Median: 11.1952,
      SD3neg: 8.682,
      SD2neg: 9.425,
      SD2: 13.461,
      SD3: 14.837,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.0,
      Median: 11.2198,
      SD3neg: 8.702,
      SD2neg: 9.445,
      SD2: 13.491,
      SD3: 14.869,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.1,
      Median: 11.2444,
      SD3neg: 8.721,
      SD2neg: 9.466,
      SD2: 13.52,
      SD3: 14.902,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.2,
      Median: 11.2691,
      SD3neg: 8.74,
      SD2neg: 9.487,
      SD2: 13.55,
      SD3: 14.934,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.3,
      Median: 11.2939,
      SD3neg: 8.76,
      SD2neg: 9.508,
      SD2: 13.58,
      SD3: 14.967,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.4,
      Median: 11.3187,
      SD3neg: 8.779,
      SD2neg: 9.529,
      SD2: 13.609,
      SD3: 15.0,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.5,
      Median: 11.3435,
      SD3neg: 8.798,
      SD2neg: 9.55,
      SD2: 13.639,
      SD3: 15.033,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.6,
      Median: 11.3684,
      SD3neg: 8.818,
      SD2neg: 9.571,
      SD2: 13.669,
      SD3: 15.065,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.7,
      Median: 11.3934,
      SD3neg: 8.837,
      SD2neg: 9.592,
      SD2: 13.699,
      SD3: 15.098,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.8,
      Median: 11.4183,
      SD3neg: 8.856,
      SD2neg: 9.613,
      SD2: 13.729,
      SD3: 15.131,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 85.9,
      Median: 11.4434,
      SD3neg: 8.876,
      SD2neg: 9.634,
      SD2: 13.759,
      SD3: 15.164,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.0,
      Median: 11.4684,
      SD3neg: 8.895,
      SD2neg: 9.655,
      SD2: 13.789,
      SD3: 15.197,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.1,
      Median: 11.4935,
      SD3neg: 8.915,
      SD2neg: 9.676,
      SD2: 13.819,
      SD3: 15.23,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.2,
      Median: 11.5186,
      SD3neg: 8.934,
      SD2neg: 9.697,
      SD2: 13.849,
      SD3: 15.264,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.3,
      Median: 11.5437,
      SD3neg: 8.954,
      SD2neg: 9.719,
      SD2: 13.879,
      SD3: 15.297,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.4,
      Median: 11.5689,
      SD3neg: 8.973,
      SD2neg: 9.74,
      SD2: 13.91,
      SD3: 15.33,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.5,
      Median: 11.594,
      SD3neg: 8.993,
      SD2neg: 9.761,
      SD2: 13.94,
      SD3: 15.363,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.6,
      Median: 11.6192,
      SD3neg: 9.012,
      SD2neg: 9.782,
      SD2: 13.97,
      SD3: 15.397,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.7,
      Median: 11.6444,
      SD3neg: 9.032,
      SD2neg: 9.803,
      SD2: 14.0,
      SD3: 15.43,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.8,
      Median: 11.6696,
      SD3neg: 9.051,
      SD2neg: 9.825,
      SD2: 14.031,
      SD3: 15.464,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 86.9,
      Median: 11.6948,
      SD3neg: 9.071,
      SD2neg: 9.846,
      SD2: 14.061,
      SD3: 15.497,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.0,
      Median: 11.7201,
      SD3neg: 9.091,
      SD2neg: 9.867,
      SD2: 14.091,
      SD3: 15.531,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.1,
      Median: 11.7453,
      SD3neg: 9.11,
      SD2neg: 9.888,
      SD2: 14.122,
      SD3: 15.564,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.2,
      Median: 11.7705,
      SD3neg: 9.13,
      SD2neg: 9.91,
      SD2: 14.152,
      SD3: 15.597,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.3,
      Median: 11.7957,
      SD3neg: 9.149,
      SD2neg: 9.931,
      SD2: 14.182,
      SD3: 15.631,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.4,
      Median: 11.8209,
      SD3neg: 9.169,
      SD2neg: 9.952,
      SD2: 14.213,
      SD3: 15.664,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.5,
      Median: 11.8461,
      SD3neg: 9.188,
      SD2neg: 9.973,
      SD2: 14.243,
      SD3: 15.698,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.6,
      Median: 11.8713,
      SD3neg: 9.208,
      SD2neg: 9.994,
      SD2: 14.273,
      SD3: 15.731,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.7,
      Median: 11.8965,
      SD3neg: 9.227,
      SD2neg: 10.015,
      SD2: 14.304,
      SD3: 15.765,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.8,
      Median: 11.9217,
      SD3neg: 9.247,
      SD2neg: 10.037,
      SD2: 14.334,
      SD3: 15.798,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 87.9,
      Median: 11.9468,
      SD3neg: 9.266,
      SD2neg: 10.058,
      SD2: 14.364,
      SD3: 15.832,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.0,
      Median: 11.972,
      SD3neg: 9.286,
      SD2neg: 10.079,
      SD2: 14.395,
      SD3: 15.865,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.1,
      Median: 11.9971,
      SD3neg: 9.305,
      SD2neg: 10.1,
      SD2: 14.425,
      SD3: 15.899,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.2,
      Median: 12.0223,
      SD3neg: 9.324,
      SD2neg: 10.121,
      SD2: 14.455,
      SD3: 15.932,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.3,
      Median: 12.0474,
      SD3neg: 9.344,
      SD2neg: 10.142,
      SD2: 14.485,
      SD3: 15.965,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.4,
      Median: 12.0725,
      SD3neg: 9.363,
      SD2neg: 10.163,
      SD2: 14.516,
      SD3: 15.999,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.5,
      Median: 12.0976,
      SD3neg: 9.383,
      SD2neg: 10.184,
      SD2: 14.546,
      SD3: 16.032,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.6,
      Median: 12.1227,
      SD3neg: 9.402,
      SD2neg: 10.205,
      SD2: 14.576,
      SD3: 16.066,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.7,
      Median: 12.1478,
      SD3neg: 9.421,
      SD2neg: 10.226,
      SD2: 14.607,
      SD3: 16.099,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.8,
      Median: 12.1728,
      SD3neg: 9.441,
      SD2neg: 10.247,
      SD2: 14.637,
      SD3: 16.133,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 88.9,
      Median: 12.1978,
      SD3neg: 9.46,
      SD2neg: 10.268,
      SD2: 14.667,
      SD3: 16.166,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.0,
      Median: 12.2229,
      SD3neg: 9.479,
      SD2neg: 10.289,
      SD2: 14.697,
      SD3: 16.2,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.1,
      Median: 12.2479,
      SD3neg: 9.498,
      SD2neg: 10.31,
      SD2: 14.728,
      SD3: 16.233,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.2,
      Median: 12.2729,
      SD3neg: 9.518,
      SD2neg: 10.331,
      SD2: 14.758,
      SD3: 16.266,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.3,
      Median: 12.2978,
      SD3neg: 9.537,
      SD2neg: 10.352,
      SD2: 14.788,
      SD3: 16.3,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.4,
      Median: 12.3228,
      SD3neg: 9.556,
      SD2neg: 10.373,
      SD2: 14.818,
      SD3: 16.333,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.5,
      Median: 12.3477,
      SD3neg: 9.575,
      SD2neg: 10.394,
      SD2: 14.848,
      SD3: 16.367,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.6,
      Median: 12.3727,
      SD3neg: 9.595,
      SD2neg: 10.415,
      SD2: 14.879,
      SD3: 16.4,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.7,
      Median: 12.3976,
      SD3neg: 9.614,
      SD2neg: 10.436,
      SD2: 14.909,
      SD3: 16.433,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.8,
      Median: 12.4225,
      SD3neg: 9.633,
      SD2neg: 10.457,
      SD2: 14.939,
      SD3: 16.466,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 89.9,
      Median: 12.4474,
      SD3neg: 9.652,
      SD2neg: 10.477,
      SD2: 14.969,
      SD3: 16.5,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.0,
      Median: 12.4723,
      SD3neg: 9.671,
      SD2neg: 10.498,
      SD2: 14.999,
      SD3: 16.533,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.1,
      Median: 12.4971,
      SD3neg: 9.69,
      SD2neg: 10.519,
      SD2: 15.029,
      SD3: 16.566,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.2,
      Median: 12.522,
      SD3neg: 9.709,
      SD2neg: 10.54,
      SD2: 15.059,
      SD3: 16.6,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.3,
      Median: 12.5468,
      SD3neg: 9.728,
      SD2neg: 10.561,
      SD2: 15.09,
      SD3: 16.633,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.4,
      Median: 12.5717,
      SD3neg: 9.747,
      SD2neg: 10.581,
      SD2: 15.12,
      SD3: 16.667,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.5,
      Median: 12.5965,
      SD3neg: 9.767,
      SD2neg: 10.602,
      SD2: 15.15,
      SD3: 16.7,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.6,
      Median: 12.6213,
      SD3neg: 9.786,
      SD2neg: 10.623,
      SD2: 15.18,
      SD3: 16.733,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.7,
      Median: 12.6461,
      SD3neg: 9.805,
      SD2neg: 10.644,
      SD2: 15.21,
      SD3: 16.767,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.8,
      Median: 12.6709,
      SD3neg: 9.824,
      SD2neg: 10.664,
      SD2: 15.24,
      SD3: 16.8,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 90.9,
      Median: 12.6957,
      SD3neg: 9.843,
      SD2neg: 10.685,
      SD2: 15.27,
      SD3: 16.833,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.0,
      Median: 12.7205,
      SD3neg: 9.862,
      SD2neg: 10.706,
      SD2: 15.3,
      SD3: 16.866,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.1,
      Median: 12.7453,
      SD3neg: 9.881,
      SD2neg: 10.726,
      SD2: 15.33,
      SD3: 16.9,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.2,
      Median: 12.77,
      SD3neg: 9.9,
      SD2neg: 10.747,
      SD2: 15.36,
      SD3: 16.933,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.3,
      Median: 12.7948,
      SD3neg: 9.918,
      SD2neg: 10.768,
      SD2: 15.39,
      SD3: 16.967,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.4,
      Median: 12.8196,
      SD3neg: 9.937,
      SD2neg: 10.788,
      SD2: 15.421,
      SD3: 17.0,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.5,
      Median: 12.8443,
      SD3neg: 9.956,
      SD2neg: 10.809,
      SD2: 15.451,
      SD3: 17.033,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.6,
      Median: 12.8691,
      SD3neg: 9.975,
      SD2neg: 10.83,
      SD2: 15.481,
      SD3: 17.067,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.7,
      Median: 12.8939,
      SD3neg: 9.994,
      SD2neg: 10.85,
      SD2: 15.511,
      SD3: 17.1,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.8,
      Median: 12.9186,
      SD3neg: 10.013,
      SD2neg: 10.871,
      SD2: 15.541,
      SD3: 17.134,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 91.9,
      Median: 12.9434,
      SD3neg: 10.032,
      SD2neg: 10.892,
      SD2: 15.571,
      SD3: 17.167,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.0,
      Median: 12.9681,
      SD3neg: 10.051,
      SD2neg: 10.912,
      SD2: 15.601,
      SD3: 17.2,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.1,
      Median: 12.9929,
      SD3neg: 10.07,
      SD2neg: 10.933,
      SD2: 15.631,
      SD3: 17.234,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.2,
      Median: 13.0177,
      SD3neg: 10.089,
      SD2neg: 10.953,
      SD2: 15.662,
      SD3: 17.267,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.3,
      Median: 13.0424,
      SD3neg: 10.108,
      SD2neg: 10.974,
      SD2: 15.692,
      SD3: 17.301,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.4,
      Median: 13.0672,
      SD3neg: 10.127,
      SD2neg: 10.995,
      SD2: 15.722,
      SD3: 17.334,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.5,
      Median: 13.092,
      SD3neg: 10.146,
      SD2neg: 11.015,
      SD2: 15.752,
      SD3: 17.368,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.6,
      Median: 13.1167,
      SD3neg: 10.164,
      SD2neg: 11.036,
      SD2: 15.782,
      SD3: 17.402,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.7,
      Median: 13.1415,
      SD3neg: 10.183,
      SD2neg: 11.056,
      SD2: 15.813,
      SD3: 17.435,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.8,
      Median: 13.1663,
      SD3neg: 10.202,
      SD2neg: 11.077,
      SD2: 15.843,
      SD3: 17.469,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 92.9,
      Median: 13.1911,
      SD3neg: 10.221,
      SD2neg: 11.098,
      SD2: 15.873,
      SD3: 17.502,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.0,
      Median: 13.2158,
      SD3neg: 10.24,
      SD2neg: 11.118,
      SD2: 15.903,
      SD3: 17.535,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.1,
      Median: 13.2406,
      SD3neg: 10.258,
      SD2neg: 11.139,
      SD2: 15.934,
      SD3: 17.569,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.2,
      Median: 13.2654,
      SD3neg: 10.277,
      SD2neg: 11.159,
      SD2: 15.964,
      SD3: 17.603,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.3,
      Median: 13.2902,
      SD3neg: 10.296,
      SD2neg: 11.18,
      SD2: 15.994,
      SD3: 17.636,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.4,
      Median: 13.3151,
      SD3neg: 10.315,
      SD2neg: 11.201,
      SD2: 16.025,
      SD3: 17.671,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.5,
      Median: 13.3399,
      SD3neg: 10.334,
      SD2neg: 11.221,
      SD2: 16.055,
      SD3: 17.704,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.6,
      Median: 13.3647,
      SD3neg: 10.353,
      SD2neg: 11.242,
      SD2: 16.085,
      SD3: 17.738,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.7,
      Median: 13.3896,
      SD3neg: 10.372,
      SD2neg: 11.262,
      SD2: 16.116,
      SD3: 17.772,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.8,
      Median: 13.4145,
      SD3neg: 10.391,
      SD2neg: 11.283,
      SD2: 16.146,
      SD3: 17.806,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 93.9,
      Median: 13.4394,
      SD3neg: 10.409,
      SD2neg: 11.304,
      SD2: 16.177,
      SD3: 17.84,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.0,
      Median: 13.4643,
      SD3neg: 10.428,
      SD2neg: 11.324,
      SD2: 16.207,
      SD3: 17.873,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.1,
      Median: 13.4892,
      SD3neg: 10.447,
      SD2neg: 11.345,
      SD2: 16.237,
      SD3: 17.907,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.2,
      Median: 13.5142,
      SD3neg: 10.466,
      SD2neg: 11.366,
      SD2: 16.268,
      SD3: 17.942,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.3,
      Median: 13.5391,
      SD3neg: 10.485,
      SD2neg: 11.386,
      SD2: 16.298,
      SD3: 17.975,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.4,
      Median: 13.5641,
      SD3neg: 10.504,
      SD2neg: 11.407,
      SD2: 16.329,
      SD3: 18.01,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.5,
      Median: 13.5892,
      SD3neg: 10.523,
      SD2neg: 11.428,
      SD2: 16.36,
      SD3: 18.044,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.6,
      Median: 13.6142,
      SD3neg: 10.542,
      SD2neg: 11.448,
      SD2: 16.39,
      SD3: 18.078,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.7,
      Median: 13.6393,
      SD3neg: 10.561,
      SD2neg: 11.469,
      SD2: 16.421,
      SD3: 18.112,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.8,
      Median: 13.6644,
      SD3neg: 10.58,
      SD2neg: 11.49,
      SD2: 16.452,
      SD3: 18.146,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 94.9,
      Median: 13.6895,
      SD3neg: 10.599,
      SD2neg: 11.511,
      SD2: 16.483,
      SD3: 18.181,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.0,
      Median: 13.7146,
      SD3neg: 10.618,
      SD2neg: 11.532,
      SD2: 16.513,
      SD3: 18.215,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.1,
      Median: 13.7398,
      SD3neg: 10.637,
      SD2neg: 11.552,
      SD2: 16.545,
      SD3: 18.25,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.2,
      Median: 13.765,
      SD3neg: 10.656,
      SD2neg: 11.573,
      SD2: 16.576,
      SD3: 18.284,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.3,
      Median: 13.7902,
      SD3neg: 10.675,
      SD2neg: 11.594,
      SD2: 16.606,
      SD3: 18.318,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.4,
      Median: 13.8155,
      SD3neg: 10.694,
      SD2neg: 11.615,
      SD2: 16.637,
      SD3: 18.353,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.5,
      Median: 13.8408,
      SD3neg: 10.713,
      SD2neg: 11.636,
      SD2: 16.669,
      SD3: 18.388,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.6,
      Median: 13.8661,
      SD3neg: 10.732,
      SD2neg: 11.657,
      SD2: 16.7,
      SD3: 18.423,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.7,
      Median: 13.8914,
      SD3neg: 10.751,
      SD2neg: 11.678,
      SD2: 16.731,
      SD3: 18.457,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.8,
      Median: 13.9168,
      SD3neg: 10.77,
      SD2neg: 11.699,
      SD2: 16.762,
      SD3: 18.492,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 95.9,
      Median: 13.9422,
      SD3neg: 10.789,
      SD2neg: 11.719,
      SD2: 16.793,
      SD3: 18.527,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.0,
      Median: 13.9676,
      SD3neg: 10.809,
      SD2neg: 11.74,
      SD2: 16.825,
      SD3: 18.562,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.1,
      Median: 13.9931,
      SD3neg: 10.828,
      SD2neg: 11.761,
      SD2: 16.856,
      SD3: 18.597,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.2,
      Median: 14.0186,
      SD3neg: 10.847,
      SD2neg: 11.783,
      SD2: 16.887,
      SD3: 18.632,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.3,
      Median: 14.0441,
      SD3neg: 10.866,
      SD2neg: 11.804,
      SD2: 16.919,
      SD3: 18.667,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.4,
      Median: 14.0697,
      SD3neg: 10.885,
      SD2neg: 11.825,
      SD2: 16.95,
      SD3: 18.702,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.5,
      Median: 14.0953,
      SD3neg: 10.905,
      SD2neg: 11.846,
      SD2: 16.982,
      SD3: 18.737,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.6,
      Median: 14.1209,
      SD3neg: 10.924,
      SD2neg: 11.867,
      SD2: 17.013,
      SD3: 18.773,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.7,
      Median: 14.1466,
      SD3neg: 10.943,
      SD2neg: 11.888,
      SD2: 17.045,
      SD3: 18.808,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.8,
      Median: 14.1724,
      SD3neg: 10.963,
      SD2neg: 11.909,
      SD2: 17.077,
      SD3: 18.844,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 96.9,
      Median: 14.1981,
      SD3neg: 10.982,
      SD2neg: 11.93,
      SD2: 17.109,
      SD3: 18.879,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.0,
      Median: 14.2239,
      SD3neg: 11.001,
      SD2neg: 11.952,
      SD2: 17.14,
      SD3: 18.915,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.1,
      Median: 14.2498,
      SD3neg: 11.021,
      SD2neg: 11.973,
      SD2: 17.172,
      SD3: 18.95,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.2,
      Median: 14.2757,
      SD3neg: 11.04,
      SD2neg: 11.994,
      SD2: 17.204,
      SD3: 18.986,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.3,
      Median: 14.3016,
      SD3neg: 11.059,
      SD2neg: 12.015,
      SD2: 17.236,
      SD3: 19.022,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.4,
      Median: 14.3276,
      SD3neg: 11.079,
      SD2neg: 12.037,
      SD2: 17.268,
      SD3: 19.058,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.5,
      Median: 14.3537,
      SD3neg: 11.099,
      SD2neg: 12.058,
      SD2: 17.3,
      SD3: 19.094,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.6,
      Median: 14.3798,
      SD3neg: 11.118,
      SD2neg: 12.08,
      SD2: 17.333,
      SD3: 19.13,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.7,
      Median: 14.4059,
      SD3neg: 11.137,
      SD2neg: 12.101,
      SD2: 17.365,
      SD3: 19.166,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.8,
      Median: 14.4321,
      SD3neg: 11.157,
      SD2neg: 12.123,
      SD2: 17.398,
      SD3: 19.203,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 97.9,
      Median: 14.4584,
      SD3neg: 11.177,
      SD2neg: 12.144,
      SD2: 17.43,
      SD3: 19.239,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.0,
      Median: 14.4848,
      SD3neg: 11.197,
      SD2neg: 12.166,
      SD2: 17.463,
      SD3: 19.275,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.1,
      Median: 14.5112,
      SD3neg: 11.216,
      SD2neg: 12.188,
      SD2: 17.495,
      SD3: 19.312,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.2,
      Median: 14.5376,
      SD3neg: 11.236,
      SD2neg: 12.209,
      SD2: 17.528,
      SD3: 19.349,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.3,
      Median: 14.5642,
      SD3neg: 11.256,
      SD2neg: 12.231,
      SD2: 17.561,
      SD3: 19.385,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.4,
      Median: 14.5908,
      SD3neg: 11.276,
      SD2neg: 12.253,
      SD2: 17.594,
      SD3: 19.422,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.5,
      Median: 14.6174,
      SD3neg: 11.295,
      SD2neg: 12.275,
      SD2: 17.627,
      SD3: 19.459,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.6,
      Median: 14.6442,
      SD3neg: 11.315,
      SD2neg: 12.297,
      SD2: 17.66,
      SD3: 19.496,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.7,
      Median: 14.671,
      SD3neg: 11.336,
      SD2neg: 12.319,
      SD2: 17.693,
      SD3: 19.533,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.8,
      Median: 14.6979,
      SD3neg: 11.355,
      SD2neg: 12.341,
      SD2: 17.727,
      SD3: 19.571,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 98.9,
      Median: 14.7248,
      SD3neg: 11.376,
      SD2neg: 12.363,
      SD2: 17.76,
      SD3: 19.608,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.0,
      Median: 14.7519,
      SD3neg: 11.396,
      SD2neg: 12.385,
      SD2: 17.793,
      SD3: 19.646,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.1,
      Median: 14.779,
      SD3neg: 11.416,
      SD2neg: 12.407,
      SD2: 17.827,
      SD3: 19.684,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.2,
      Median: 14.8062,
      SD3neg: 11.436,
      SD2neg: 12.429,
      SD2: 17.861,
      SD3: 19.721,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.3,
      Median: 14.8334,
      SD3neg: 11.456,
      SD2neg: 12.452,
      SD2: 17.895,
      SD3: 19.76,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.4,
      Median: 14.8608,
      SD3neg: 11.477,
      SD2neg: 12.474,
      SD2: 17.929,
      SD3: 19.797,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.5,
      Median: 14.8882,
      SD3neg: 11.497,
      SD2neg: 12.496,
      SD2: 17.963,
      SD3: 19.836,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.6,
      Median: 14.9157,
      SD3neg: 11.518,
      SD2neg: 12.519,
      SD2: 17.997,
      SD3: 19.874,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.7,
      Median: 14.9434,
      SD3neg: 11.538,
      SD2neg: 12.542,
      SD2: 18.031,
      SD3: 19.913,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.8,
      Median: 14.9711,
      SD3neg: 11.559,
      SD2neg: 12.564,
      SD2: 18.066,
      SD3: 19.951,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 99.9,
      Median: 14.9989,
      SD3neg: 11.579,
      SD2neg: 12.587,
      SD2: 18.1,
      SD3: 19.99,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.0,
      Median: 15.0267,
      SD3neg: 11.6,
      SD2neg: 12.61,
      SD2: 18.135,
      SD3: 20.028,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.1,
      Median: 15.0547,
      SD3neg: 11.621,
      SD2neg: 12.633,
      SD2: 18.17,
      SD3: 20.068,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.2,
      Median: 15.0828,
      SD3neg: 11.642,
      SD2neg: 12.655,
      SD2: 18.205,
      SD3: 20.107,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.3,
      Median: 15.1109,
      SD3neg: 11.663,
      SD2neg: 12.679,
      SD2: 18.239,
      SD3: 20.146,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.4,
      Median: 15.1392,
      SD3neg: 11.684,
      SD2neg: 12.702,
      SD2: 18.275,
      SD3: 20.186,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.5,
      Median: 15.1676,
      SD3neg: 11.705,
      SD2neg: 12.725,
      SD2: 18.31,
      SD3: 20.226,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.6,
      Median: 15.196,
      SD3neg: 11.726,
      SD2neg: 12.748,
      SD2: 18.345,
      SD3: 20.265,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.7,
      Median: 15.2246,
      SD3neg: 11.747,
      SD2neg: 12.771,
      SD2: 18.381,
      SD3: 20.305,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.8,
      Median: 15.2532,
      SD3neg: 11.768,
      SD2neg: 12.795,
      SD2: 18.417,
      SD3: 20.345,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 100.9,
      Median: 15.2819,
      SD3neg: 11.79,
      SD2neg: 12.818,
      SD2: 18.452,
      SD3: 20.385,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.0,
      Median: 15.3108,
      SD3neg: 11.811,
      SD2neg: 12.842,
      SD2: 18.488,
      SD3: 20.426,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.1,
      Median: 15.3397,
      SD3neg: 11.832,
      SD2neg: 12.865,
      SD2: 18.524,
      SD3: 20.466,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.2,
      Median: 15.3687,
      SD3neg: 11.854,
      SD2neg: 12.889,
      SD2: 18.561,
      SD3: 20.507,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.3,
      Median: 15.3979,
      SD3neg: 11.875,
      SD2neg: 12.913,
      SD2: 18.597,
      SD3: 20.548,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.4,
      Median: 15.4271,
      SD3neg: 11.897,
      SD2neg: 12.937,
      SD2: 18.633,
      SD3: 20.588,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.5,
      Median: 15.4564,
      SD3neg: 11.919,
      SD2neg: 12.96,
      SD2: 18.67,
      SD3: 20.63,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.6,
      Median: 15.4858,
      SD3neg: 11.94,
      SD2neg: 12.984,
      SD2: 18.706,
      SD3: 20.671,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.7,
      Median: 15.5154,
      SD3neg: 11.962,
      SD2neg: 13.008,
      SD2: 18.743,
      SD3: 20.712,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.8,
      Median: 15.545,
      SD3neg: 11.984,
      SD2neg: 13.033,
      SD2: 18.78,
      SD3: 20.754,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 101.9,
      Median: 15.5747,
      SD3neg: 12.006,
      SD2neg: 13.057,
      SD2: 18.817,
      SD3: 20.796,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.0,
      Median: 15.6046,
      SD3neg: 12.028,
      SD2neg: 13.081,
      SD2: 18.855,
      SD3: 20.838,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.1,
      Median: 15.6345,
      SD3neg: 12.05,
      SD2neg: 13.105,
      SD2: 18.892,
      SD3: 20.88,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.2,
      Median: 15.6646,
      SD3neg: 12.072,
      SD2neg: 13.13,
      SD2: 18.93,
      SD3: 20.922,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.3,
      Median: 15.6947,
      SD3neg: 12.095,
      SD2neg: 13.155,
      SD2: 18.967,
      SD3: 20.964,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.4,
      Median: 15.725,
      SD3neg: 12.117,
      SD2neg: 13.179,
      SD2: 19.005,
      SD3: 21.006,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.5,
      Median: 15.7553,
      SD3neg: 12.14,
      SD2neg: 13.204,
      SD2: 19.043,
      SD3: 21.049,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.6,
      Median: 15.7858,
      SD3neg: 12.162,
      SD2neg: 13.229,
      SD2: 19.081,
      SD3: 21.092,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.7,
      Median: 15.8164,
      SD3neg: 12.184,
      SD2neg: 13.253,
      SD2: 19.119,
      SD3: 21.136,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.8,
      Median: 15.847,
      SD3neg: 12.207,
      SD2neg: 13.278,
      SD2: 19.157,
      SD3: 21.179,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 102.9,
      Median: 15.8778,
      SD3neg: 12.23,
      SD2neg: 13.303,
      SD2: 19.196,
      SD3: 21.222,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.0,
      Median: 15.9087,
      SD3neg: 12.253,
      SD2neg: 13.329,
      SD2: 19.235,
      SD3: 21.265,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.1,
      Median: 15.9396,
      SD3neg: 12.275,
      SD2neg: 13.354,
      SD2: 19.273,
      SD3: 21.309,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.2,
      Median: 15.9707,
      SD3neg: 12.298,
      SD2neg: 13.379,
      SD2: 19.312,
      SD3: 21.352,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.3,
      Median: 16.0019,
      SD3neg: 12.321,
      SD2neg: 13.404,
      SD2: 19.351,
      SD3: 21.396,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.4,
      Median: 16.0332,
      SD3neg: 12.344,
      SD2neg: 13.43,
      SD2: 19.39,
      SD3: 21.44,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.5,
      Median: 16.0645,
      SD3neg: 12.367,
      SD2neg: 13.455,
      SD2: 19.429,
      SD3: 21.484,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.6,
      Median: 16.096,
      SD3neg: 12.391,
      SD2neg: 13.481,
      SD2: 19.469,
      SD3: 21.529,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.7,
      Median: 16.1276,
      SD3neg: 12.414,
      SD2neg: 13.506,
      SD2: 19.508,
      SD3: 21.574,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.8,
      Median: 16.1593,
      SD3neg: 12.437,
      SD2neg: 13.532,
      SD2: 19.548,
      SD3: 21.618,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 103.9,
      Median: 16.191,
      SD3neg: 12.46,
      SD2neg: 13.558,
      SD2: 19.588,
      SD3: 21.663,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.0,
      Median: 16.2229,
      SD3neg: 12.484,
      SD2neg: 13.584,
      SD2: 19.628,
      SD3: 21.708,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.1,
      Median: 16.2549,
      SD3neg: 12.507,
      SD2neg: 13.61,
      SD2: 19.668,
      SD3: 21.754,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.2,
      Median: 16.287,
      SD3neg: 12.531,
      SD2neg: 13.636,
      SD2: 19.708,
      SD3: 21.799,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.3,
      Median: 16.3191,
      SD3neg: 12.555,
      SD2neg: 13.662,
      SD2: 19.748,
      SD3: 21.844,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.4,
      Median: 16.3514,
      SD3neg: 12.578,
      SD2neg: 13.688,
      SD2: 19.789,
      SD3: 21.889,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.5,
      Median: 16.3837,
      SD3neg: 12.602,
      SD2neg: 13.714,
      SD2: 19.829,
      SD3: 21.935,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.6,
      Median: 16.4162,
      SD3neg: 12.626,
      SD2neg: 13.741,
      SD2: 19.87,
      SD3: 21.981,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.7,
      Median: 16.4488,
      SD3neg: 12.65,
      SD2neg: 13.767,
      SD2: 19.911,
      SD3: 22.027,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.8,
      Median: 16.4814,
      SD3neg: 12.674,
      SD2neg: 13.793,
      SD2: 19.952,
      SD3: 22.074,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 104.9,
      Median: 16.5142,
      SD3neg: 12.698,
      SD2neg: 13.82,
      SD2: 19.993,
      SD3: 22.12,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.0,
      Median: 16.547,
      SD3neg: 12.722,
      SD2neg: 13.847,
      SD2: 20.034,
      SD3: 22.166,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.1,
      Median: 16.58,
      SD3neg: 12.746,
      SD2neg: 13.873,
      SD2: 20.076,
      SD3: 22.213,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.2,
      Median: 16.6131,
      SD3neg: 12.77,
      SD2neg: 13.9,
      SD2: 20.117,
      SD3: 22.26,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.3,
      Median: 16.6462,
      SD3neg: 12.795,
      SD2neg: 13.927,
      SD2: 20.158,
      SD3: 22.306,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.4,
      Median: 16.6795,
      SD3neg: 12.819,
      SD2neg: 13.954,
      SD2: 20.2,
      SD3: 22.354,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.5,
      Median: 16.7129,
      SD3neg: 12.844,
      SD2neg: 13.981,
      SD2: 20.242,
      SD3: 22.401,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.6,
      Median: 16.7464,
      SD3neg: 12.868,
      SD2neg: 14.008,
      SD2: 20.284,
      SD3: 22.449,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.7,
      Median: 16.78,
      SD3neg: 12.893,
      SD2neg: 14.035,
      SD2: 20.326,
      SD3: 22.496,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.8,
      Median: 16.8137,
      SD3neg: 12.917,
      SD2neg: 14.063,
      SD2: 20.369,
      SD3: 22.544,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 105.9,
      Median: 16.8475,
      SD3neg: 12.942,
      SD2neg: 14.09,
      SD2: 20.411,
      SD3: 22.592,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.0,
      Median: 16.8814,
      SD3neg: 12.967,
      SD2neg: 14.117,
      SD2: 20.454,
      SD3: 22.641,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.1,
      Median: 16.9154,
      SD3neg: 12.992,
      SD2neg: 14.145,
      SD2: 20.497,
      SD3: 22.688,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.2,
      Median: 16.9496,
      SD3neg: 13.017,
      SD2neg: 14.173,
      SD2: 20.54,
      SD3: 22.737,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.3,
      Median: 16.9838,
      SD3neg: 13.042,
      SD2neg: 14.2,
      SD2: 20.583,
      SD3: 22.786,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.4,
      Median: 17.0182,
      SD3neg: 13.067,
      SD2neg: 14.228,
      SD2: 20.626,
      SD3: 22.835,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.5,
      Median: 17.0527,
      SD3neg: 13.092,
      SD2neg: 14.256,
      SD2: 20.669,
      SD3: 22.883,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.6,
      Median: 17.0873,
      SD3neg: 13.117,
      SD2neg: 14.284,
      SD2: 20.713,
      SD3: 22.933,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.7,
      Median: 17.122,
      SD3neg: 13.143,
      SD2neg: 14.312,
      SD2: 20.756,
      SD3: 22.982,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.8,
      Median: 17.1569,
      SD3neg: 13.168,
      SD2neg: 14.34,
      SD2: 20.8,
      SD3: 23.032,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 106.9,
      Median: 17.1918,
      SD3neg: 13.194,
      SD2neg: 14.368,
      SD2: 20.845,
      SD3: 23.082,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.0,
      Median: 17.2269,
      SD3neg: 13.22,
      SD2neg: 14.397,
      SD2: 20.888,
      SD3: 23.131,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.1,
      Median: 17.262,
      SD3neg: 13.245,
      SD2neg: 14.425,
      SD2: 20.933,
      SD3: 23.181,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.2,
      Median: 17.2973,
      SD3neg: 13.271,
      SD2neg: 14.454,
      SD2: 20.977,
      SD3: 23.231,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.3,
      Median: 17.3327,
      SD3neg: 13.297,
      SD2neg: 14.482,
      SD2: 21.022,
      SD3: 23.282,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.4,
      Median: 17.3683,
      SD3neg: 13.323,
      SD2neg: 14.511,
      SD2: 21.067,
      SD3: 23.333,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.5,
      Median: 17.4039,
      SD3neg: 13.349,
      SD2neg: 14.54,
      SD2: 21.111,
      SD3: 23.383,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.6,
      Median: 17.4397,
      SD3neg: 13.375,
      SD2neg: 14.569,
      SD2: 21.157,
      SD3: 23.434,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.7,
      Median: 17.4755,
      SD3neg: 13.401,
      SD2neg: 14.598,
      SD2: 21.202,
      SD3: 23.485,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.8,
      Median: 17.5115,
      SD3neg: 13.427,
      SD2neg: 14.627,
      SD2: 21.247,
      SD3: 23.536,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 107.9,
      Median: 17.5476,
      SD3neg: 13.454,
      SD2neg: 14.656,
      SD2: 21.292,
      SD3: 23.588,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.0,
      Median: 17.5839,
      SD3neg: 13.48,
      SD2neg: 14.685,
      SD2: 21.338,
      SD3: 23.64,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.1,
      Median: 17.6202,
      SD3neg: 13.507,
      SD2neg: 14.715,
      SD2: 21.384,
      SD3: 23.691,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.2,
      Median: 17.6567,
      SD3neg: 13.533,
      SD2neg: 14.744,
      SD2: 21.43,
      SD3: 23.743,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.3,
      Median: 17.6932,
      SD3neg: 13.56,
      SD2neg: 14.773,
      SD2: 21.476,
      SD3: 23.796,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.4,
      Median: 17.7299,
      SD3neg: 13.586,
      SD2neg: 14.803,
      SD2: 21.523,
      SD3: 23.848,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.5,
      Median: 17.7668,
      SD3neg: 13.614,
      SD2neg: 14.833,
      SD2: 21.569,
      SD3: 23.9,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.6,
      Median: 17.8037,
      SD3neg: 13.64,
      SD2neg: 14.863,
      SD2: 21.615,
      SD3: 23.953,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.7,
      Median: 17.8407,
      SD3neg: 13.667,
      SD2neg: 14.892,
      SD2: 21.662,
      SD3: 24.006,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.8,
      Median: 17.8779,
      SD3neg: 13.694,
      SD2neg: 14.922,
      SD2: 21.709,
      SD3: 24.059,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 108.9,
      Median: 17.9152,
      SD3neg: 13.722,
      SD2neg: 14.953,
      SD2: 21.756,
      SD3: 24.112,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.0,
      Median: 17.9526,
      SD3neg: 13.749,
      SD2neg: 14.983,
      SD2: 21.803,
      SD3: 24.166,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.1,
      Median: 17.9901,
      SD3neg: 13.776,
      SD2neg: 15.013,
      SD2: 21.851,
      SD3: 24.219,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.2,
      Median: 18.0277,
      SD3neg: 13.803,
      SD2neg: 15.043,
      SD2: 21.898,
      SD3: 24.273,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.3,
      Median: 18.0654,
      SD3neg: 13.831,
      SD2neg: 15.073,
      SD2: 21.946,
      SD3: 24.327,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.4,
      Median: 18.1033,
      SD3neg: 13.859,
      SD2neg: 15.104,
      SD2: 21.993,
      SD3: 24.381,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.5,
      Median: 18.1412,
      SD3neg: 13.886,
      SD2neg: 15.135,
      SD2: 22.041,
      SD3: 24.435,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.6,
      Median: 18.1792,
      SD3neg: 13.914,
      SD2neg: 15.165,
      SD2: 22.089,
      SD3: 24.489,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.7,
      Median: 18.2174,
      SD3neg: 13.942,
      SD2neg: 15.196,
      SD2: 22.138,
      SD3: 24.544,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.8,
      Median: 18.2556,
      SD3neg: 13.969,
      SD2neg: 15.227,
      SD2: 22.186,
      SD3: 24.599,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 109.9,
      Median: 18.294,
      SD3neg: 13.998,
      SD2neg: 15.258,
      SD2: 22.234,
      SD3: 24.653,
    },
    {
      Section: 'weight-for-length-girls',
      Height: 110.0,
      Median: 18.3324,
      SD3neg: 14.025,
      SD2neg: 15.289,
      SD2: 22.283,
      SD3: 24.708,
    },
  ],
};
