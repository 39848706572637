import { VisitDto } from '@ecdlink/core';
import { StepItem } from '@ecdlink/ui';

export const getVisitStatus = (
  visit: VisitDto,
  isOffline?: boolean
): StepItem['type'] => {
  const twoDaysAgo = new Date();
  twoDaysAgo.setHours(twoDaysAgo.getHours() - 48);

  if (
    visit.attended ||
    (!!visit.startedDate &&
      new Date(visit.startedDate).getTime() < twoDaysAgo.getTime())
  ) {
    return 'completed';
  }

  if (
    (visit.startedDate &&
      new Date(visit.startedDate).getTime() > twoDaysAgo.getTime()) ||
    isOffline
  ) {
    return 'inProgress';
  }

  return 'todo';
};

export const canStartVisit = (visit: VisitDto, visitType?: string): boolean => {
  if (visit.attended) {
    return false;
  }

  if (isVisitInProgress(visit)) {
    return true;
  }

  var todayStart = new Date();
  todayStart.setHours(0, 0, 0);

  var todayEnd = new Date();
  todayEnd.setHours(23, 59, 59);

  // Check date range
  if (
    !!visit.plannedVisitDate &&
    new Date(visit.plannedVisitDate).getTime() >= todayEnd.getTime()
  ) {
    return false;
  }

  if (visitType === 'mother') {
    return true;
  } else {
    if (
      !!visit.dueDate &&
      new Date(visit.dueDate).getTime() < todayStart.getTime()
    ) {
      return false;
    }
    if (isVisitMissed(visit)) {
      return false;
    }
  }

  return true;
};

export const getVisitSubTitle = (
  visit: VisitDto,
  visitType?: string
): string => {
  const date = new Date(visit.orderDate);
  date.setHours(0, 0, 0, 0);

  const twoDaysAgo = new Date();
  twoDaysAgo.setHours(twoDaysAgo.getHours() - 48);

  if (isVisitInProgress(visit)) {
    return 'Visit incomplete';
  }

  // Visit incomplete and expired
  if (visitType === 'mother') {
    if (!visit.attended && !!visit.startedDate) {
      return `Visit incomplete`;
    }
  } else {
    if (
      !visit.attended &&
      !!visit.startedDate &&
      new Date(visit.dueDate).getTime() < new Date().getTime()
    ) {
      return `Missed visit deadline`;
    }
  }

  if (
    visit.visitType?.normalizedName === 'Additional visits' &&
    visit.comment
  ) {
    return visit.comment;
  }

  return `By ${date.getDate()} ${date.toLocaleString('default', {
    month: 'long',
  })} ${date.getFullYear()}`;
};

export const isVisitInProgress = (visit: VisitDto): boolean => {
  const twoDaysAgo = new Date();
  twoDaysAgo.setHours(twoDaysAgo.getHours() - 48);

  if (
    !visit.attended &&
    !!visit.startedDate &&
    new Date(visit.startedDate).getTime() > twoDaysAgo.getTime()
  ) {
    return true;
  }

  return false;
};

export const isVisitMissed = (visit: VisitDto): boolean => {
  const twoDaysAgo = new Date();
  twoDaysAgo.setHours(twoDaysAgo.getHours() - 48);

  if (
    !visit.attended &&
    new Date(visit.dueDate).getTime() > new Date().getTime()
  ) {
    return false;
  }

  if (
    !visit.attended &&
    !!visit.startedDate &&
    new Date(visit.startedDate).getTime() < twoDaysAgo.getTime()
  ) {
    return true;
  }

  return false;
};
