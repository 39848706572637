import { LanguageDto } from '@ecdlink/core';

export const DEFAULT_LOCALE = { locale: 'en-za' };

/**
 * Returns the locale for a given language id
 * Will default to 'en-za' if the language id is not found
 *
 * @param {string} languageId
 * @return {*}  {string}
 */
export const GetLocaleByLanguageId = (
  languages: LanguageDto[],
  languageId: string
): string => {
  const language = languages.find((x) => x.id === languageId);
  return language ? language.locale : DEFAULT_LOCALE.locale;
};
