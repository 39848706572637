import { Middleware } from '@reduxjs/toolkit';
import { GetLocaleByLanguageId } from '@/utils/common/language.utils';
import { userActions } from '@/store/user';
import { staticDataSelectors } from '@/store/static-data';
import { healthCareWorkerActions } from '../healthCareWorker';

/**
 * Makes sure to update the user locale preference when the user is updated.
 * The user locale preference is based on the user's languageId and used when viewing
 * translated CMS content to make sure the prefered locale is used first
 *
 * @param {*} store
 */
const updateUserLocaleMiddleware: Middleware =
  (store) => (next) => (action) => {
    // Language is updated for both user and health care worker
    if (
      action.type === userActions.updateUser.type ||
      action.type === healthCareWorkerActions.updateHealthCareWorker.type
    ) {
      const state = store.getState();
      const languages = staticDataSelectors.getLanguages(state);
      const user = action.payload;

      if (languages && user.languageId) {
        const locale = GetLocaleByLanguageId(languages, user.languageId);
        store.dispatch(userActions.updateUserLocalePreference(locale));
      }
    }

    return next(action);
  };

export default updateUserLocaleMiddleware;
