import { useSelector } from 'react-redux';
import { DevelopmentalScreeningVisitSection } from '@/pages/infant/infant-profile/progress-tab/activity-list/forms/pillar-2-steps/developmental-screening-weeks';
import { HIVSection } from '@/pages/infant/infant-profile/progress-tab/activity-list/forms/pillar-5-steps/hiv-care-and-medication';
import { activitiesTypes } from '@/pages/mom/pregnant-profile/progress-tab/activity-list/activities-list';
import { clinicVisitsSectionName } from '@/pages/mom/pregnant-profile/progress-tab/activity-list/forms/healthcare-steps/clinic-visits';
import { muacFormSection } from '@/pages/mom/pregnant-profile/progress-tab/activity-list/forms/nutrition-steps/mother-growth-muac';
import { motherThunkActions } from '@/store/mother';
import {
  getMotherFirstVisitSelector,
  getMotherLastVisitSelector,
} from '@/store/mother/mother.selectors';
import { referralThunkActions } from '@/store/referral';
import { visitThunkActions } from '@/store/visit';
import { useLayoutEffect } from 'react';
import { useAppDispatch } from '@/store';
import { VisitDto } from '@ecdlink/core';

export const useMotherInfo = ({
  motherId,
  visitId,
  currentVisit,
}: {
  motherId: string;
  visitId?: string;
  currentVisit?: VisitDto | undefined;
}) => {
  const appDispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (visitId) {
      appDispatch(motherThunkActions?.getMotherVisits({ motherId }));
      appDispatch(
        referralThunkActions.getReferralsForVisitId({ visitId })
      ).unwrap();
    }
  }, [appDispatch, motherId, visitId]);

  const firstVisit = useSelector(getMotherFirstVisitSelector);
  const previousCurrentVisit = useSelector(getMotherLastVisitSelector);

  useLayoutEffect(() => {
    if (visitId) {
      if (
        !!previousCurrentVisit &&
        previousCurrentVisit?.id !== currentVisit?.id &&
        !!currentVisit
      ) {
        appDispatch(
          visitThunkActions.getPreviousVisitInformationForMother({
            visitId: previousCurrentVisit?.id,
          })
        );
      } else {
        if (!previousCurrentVisit && !!currentVisit) {
          appDispatch(
            visitThunkActions.getPreviousVisitInformationForMother({
              visitId: currentVisit?.id,
            })
          );
        }
      }
    }
  }, [
    appDispatch,
    visitId,
    currentVisit?.id,
    currentVisit,
    previousCurrentVisit,
  ]);

  useLayoutEffect(() => {
    if (visitId) {
      appDispatch(
        visitThunkActions.getVisitAnswersForMother({
          visitId: visitId,
          visitName: activitiesTypes.nutrition,
          visitSection: DevelopmentalScreeningVisitSection,
        })
      );
    }
  }, [visitId, appDispatch]);

  useLayoutEffect(() => {
    if (previousCurrentVisit?.id && visitId) {
      appDispatch(
        visitThunkActions.getVisitAnswersForMother({
          visitId: previousCurrentVisit.id,
          visitName: activitiesTypes.nutrition,
          visitSection: muacFormSection,
        })
      );
      appDispatch(
        visitThunkActions.getVisitAnswersForMother({
          visitId: previousCurrentVisit.id,
          visitName: activitiesTypes.healthCare,
          visitSection: clinicVisitsSectionName,
        })
      );
      appDispatch(
        visitThunkActions.getVisitAnswersForMother({
          visitId: previousCurrentVisit.id,
          visitName: activitiesTypes.pregnancyCare,
          visitSection: HIVSection,
        })
      );
    }
  }, [appDispatch, previousCurrentVisit?.id]);

  useLayoutEffect(() => {
    if (firstVisit?.id && visitId) {
      appDispatch(
        visitThunkActions.getVisitAnswersForMother({
          visitId: firstVisit.id,
          visitName: activitiesTypes.pregnancyCare,
          visitSection: HIVSection,
        })
      );
    }
  }, [appDispatch, firstVisit?.id]);

  useLayoutEffect(() => {
    if (visitId) {
      appDispatch(
        visitThunkActions.getPreviousVisitInformationForMother({
          visitId,
        })
      );
      appDispatch(
        visitThunkActions.GetMotherSummaryByPriority({
          visitId,
        })
      );
    }
  }, [appDispatch, visitId]);
};
